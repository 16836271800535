import {baseUrl} from "@/common/env";

function getUrl(url) {
    if (!url)
        return null;
    let reg = /http(s)?:\/\/.*/g
    if (reg.test(url)) {
        return url;
    }
    return baseUrl + url;
}

export default getUrl;