<template>
  <div class="wealth shadow">
    <div style="height:10px"></div>
    <span></span>
    <div class="wealth-item" v-for="(item, index) in dataList" :key="index">
      <template v-if="item.user != null">
        <div>
          <img :src="item.url" v-if="item.type === 'bg'">
          <div v-if="item.type === 'num'"><i class="num">{{ item.val }}.</i></div>
        </div>
        <div>
          <!--        <img src="https://solove.love/media/uploads/article/images/2019/05/solove/lo_1557896732_1.gif"/>-->
          <Avatar :size="40" :lighten="10000" :username="item.user.username"
                  :src="/http(s)?/g.test(item.user.avatar) ? item.user.avatar : $baseUrl + item.user.avatar"></Avatar>
        </div>
        <div>
          <SLText>{{ item.user.nickname || item.user.username }}</SLText>
        </div>
        <div>
          <SLText>积分 {{ item.user.credit }}</SLText>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SLText from "@/components/SLText";
import userApi from "@/common/api/userApi";
import Avatar from "vue-avatar";

export default {
  name: "WealthAside",
  components: {SLText, Avatar},
  data() {
    return {
      dataList: [
        {"type": "bg", "url": require("../../assets/di.png")},
        {"type": "bg", "url": require("../../assets/di2.png")},
        {"type": "bg", "url": require("../../assets/di1.png")},
        {"type": "num", "val": 4},
        {"type": "num", "val": 5},
      ]
    }
  },
  created() {
    userApi.getRanking(res => {
      for (let i = 0; i < this.dataList.length; i++) {
        try {
          this.$set(this.dataList[i], "user", res[i]);
        } catch (e) {
          this.$set(this.dataList[i], "user", null);
        }
      }
    })
  },
}
</script>

<style scoped lang="less">
.wealth {
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  padding-bottom: 20px;
  width: 360px;

  span {
    display: block;
    background: url("../../assets/list.png") no-repeat center center;
    background-size: contain;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 20px;

    i {
      color: #55ca56c7;
      font-weight: 100;
      font-size: 20px;
    }
  }

  .wealth-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6.5px 10px;

    div:nth-child(1) {
      flex: 1;

      img {
        height: 20px;
      }

      .num {
        font-style: italic;
        font-weight: 500;
        font-size: 18px;
      }
    }

    div:nth-child(2) {
      flex: 1;
      text-align: left;

      img {
        height: 40px;
        width: 40px;
        border-radius: 50%;
      }
    }

    div:nth-child(3) {
      flex: 2;
      text-align: left;
      overflow: hidden;
    }

    div:nth-child(4) {
      flex: 2;
      padding-right: 15px;

      p {
        text-align: right;
      }

      //margin-left: 40px;
    }
  }
}
</style>
