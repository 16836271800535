<template>
<!--  <div class="resource">-->
<!--    <div class="filename">-->
<!--      <svg-icon w="20" :name="name"></svg-icon>-->
<!--      <span> {{ item.name }}</span>-->
<!--    </div>-->
<!--    <div class="type">-->
<!--      <span v-if="item.type === 'Free'">{{ item.type }}</span>-->
<!--      <div v-if="item.type === 'Credit'">{{ item.url ? "( 已购买 )" : "积分: " + item.money }}</div>-->
<!--      <div v-if="item.type === 'Money'">{{ item.url ? "( 已购买 )" : "RMB: " + item.money.toFixed(2) }}</div>-->
<!--    </div>-->
<!--    <div class="code">-->
<!--      <span>提取码：</span>-->
<!--      <span>{{ item.code }}</span>-->
<!--    </div>-->
<!--    <div class="download">-->
<!--      <SLButton class="down-btn" width="83.2" :loading="loading"><i class="sl icon-download" @click="toDownload(item.url)"> 去下载</i>-->
<!--      </SLButton>-->
<!--    </div>-->
<!--  </div>-->
  <div class="download">
    <div class="item-dl" v-for="(item,index) in downloads" :key="index">
      <svg-icon :name="getName(item.name)" w="40"></svg-icon>
      <div class="dl-info">
        <SLText>{{ item.name }}</SLText>
        <div style="display: flex;flex-direction: row">
          <span>{{ getType(item.type, item.money, item.url) }}</span>
          <span>提取码：{{ item.code || '无' }}</span>
        </div>
        <div class="mark-menu">
          <el-tooltip :content="getContent(item.type, item.url, item.money)" placement="top">
            <i class="el-icon-s-opportunity"
               style="font-size: 25px; color: orange;background: #fff;border-radius: 50%;padding: 3px"></i>
          </el-tooltip>
          <el-tooltip content="点击跳转下载页面" placement="top" style="margin-left: 40px;animation-delay: .1s"
                      :data-clipboard-text="item.code" :id="`code${index}`"
                      @click.native="toDownload(item.url, index, item)">
            <i class="sl icon-download"
               style="font-size: 28px; color: var(--main-green);background: #fff;border-radius: 50%"></i>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import orderApi from "@/common/api/orderApi";
import SLText from "@/components/SLText";
import {mapState} from "vuex";
import Clipboard from "clipboard";

let Base64 = require('js-base64').Base64

export default {
  name: "ResourceFileItem",
  components: {SLButton, SLText},
  data() {
    return {
    }
  },
  props: {
    downloads: {
      type: Array,
      default: {},
    }
  },
  methods: {
    getName(name) {
      let suffix = [".zip", '.rar', ".pdf", ".7z", ".md", ".dmg"];
      for (const items of suffix) {
        if (name.toLowerCase().indexOf(items) > 0) {
          return items.replaceAll(".", "");
        }
      }
      return "resource";
    },
    getType(type, money, url){
      if (type === 'Free') return "Free";
      if (url) return "已购";
      if (type === 'Credit') return "积分: " + money;
      if (type === 'Money') return "RMB: ￥" + money;
    },
    toDownload(url, index, item) {
      if(!this.isLogin){
        return this.loginBox.show();
      }
      if (url) {
        let uri = Base64.encodeURI(url);
        let id = `code${index}`;
        let clipboard = new Clipboard('#' + id)
        clipboard.on('success', e => {
          this.$message({message: "提取码已复制至剪切板~", duration: 4000, type: "success"})
          // 释放内存
          clipboard.destroy()
          return open("/to/" + uri);
        })
        clipboard.on('error', e => {
          // 不支持复制
          console.warn('该浏览器不支持自动复制')
          // 释放内存
          clipboard.destroy();
          return open("/to/" + uri);
        })
        // return this.$router.push({name: 'TO', params: {url: uri}})
      }
      this.loading = true;
      // 未支付
      if (item.type === 'Credit') {
        // 积分支付
        let data = {
          payType: "Credit",
          orderType: "Resource",
          resourceId: item.id
        }
        orderApi.creditPayResource(data, url => {
          this.loading = false;
          if (url) {
            let id = `code${index}`;
            let clipboard = new Clipboard('#' + id)
            clipboard.on('success', e => {
              this.$message({message: "积分扣除成功，提取码已复制至剪切板,即将跳转下载页面~", duration: 4000, type: "success"})
              // 释放内存
              clipboard.destroy();
              url = Base64.encodeURI(url);
              setTimeout(() => open("/to/" + url), 1000);
            })
            clipboard.on('error', e => {
              // 不支持复制
              console.warn('该浏览器不支持自动复制')
              // 释放内存
              clipboard.destroy()
              url = Base64.encodeURI(url);
              setTimeout(() => open("/to/" + url), 1000);
            })
          }
        })
      }
      if (item.type === 'Money') {
        // 人民币
      }
    },
    getContent(type, url, money){
      if (type === 'Free') return "免费绿色资源，放心下载";
      if (url) return '此附件您已购买，重复下载不收取积分(RMB),请直接下载即可。';
      else return `当前附件所需${type === 'Credit'? '积分': 'RMB: ￥'}${money}，点击下载会自动从您的积分中扣除~`
    }
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      loginBox: state => state.user.loginBox
    })
  }
}
</script>

<style scoped lang="less">
/deep/ .el-tooltip {
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }

  cursor: pointer;
  animation: icon-an .5s linear normal;
}
@keyframes icon-an {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px)
  }
}

.download {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 20px;

  .item-dl {
    position: relative;
    display: inline-flex;
    padding: 10px 15px;
    border: 1px #cccccc3d solid;
    border-radius: 8px;
    flex-direction: row;
    background: #fff;
    margin: 0 20px 20px 0;

    .mark-menu {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(1px);
      background-color: #0000005c;
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }

    .dl-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 200px;
      justify-content: center;

      &:hover .mark-menu {
        display: flex;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: #2c3e50e0;
      }

      span, p {
        font-size: 13px;
        padding-left: 10px;
        margin-bottom: 2px;
      }

      div:nth-child(2) {
        span:nth-child(1) {
          display: block;
          padding: 1px 2px;
          margin-left: 10px;
          font-size: 12px;
          color: #fff;
          border-radius: 4px;
          background-color: var(--main-green);
        }
      }
    }
  }
}
</style>
