<template>
  <div class="base">
    <div style="padding:20px">
      <el-tabs v-model="activeName">
        <el-tab-pane label="公共文章" name="first">
          <el-row :gutter="15">
            <Loading :loading="gLoading" :data="gResult.list" height="calc(100vh - 400px)">
              <el-col :md="6" v-for="(item, index) in gResult.list" :key="index"
                      style="margin-bottom: 15px;position: relative">
                <el-card :body-style="{ padding: '0px', position : 'relative' }">
                  <div class="article-status" v-if="item.status !== 'OK'">
                    <div class="content" style="backdrop-filter: grayscale(100%);background-color: #00000005" v-if="item.status === 'Audit'">
                      <i class="el-icon-time" style="font-size: 30px;color: var(--main-blue)"></i>
                      <span style="font-size: 12px;line-height: 40px;color:#fff">文章审核中...</span>
                    </div>

                    <div class="content" style="backdrop-filter: grayscale(100%);background-color: #00000005;cursor: pointer" v-else @click="$router.push('/edit/' + item.id)">
                      <i class="el-icon-warning" style="font-size: 30px;color: coral"></i>
                      <span style="font-size: 12px;line-height: 40px;color:#fff">审核未通过，请修改后再次提交！</span>
                    </div>
                  </div>
                  <el-image class="image" :src="getUrl(item.image)" fit="cover"/>
                  <div class="info">
                    <SLText size="14">{{ item.title }}</SLText>
                    <div class="bottom">
                      <span><i class="el-icon-time"> {{ item.date }}</i></span>
                      <span>
                      <el-tooltip placement="top" effect="light">
                        <i class="sl icon-menu"></i>
                        <div slot="content" class="menu">
                          <span @click="$router.push(item.staticUrl)"><i class="el-icon-view"></i> 查看</span>
                          <span @click="editArticle(item.id)"><i class="sl icon-edit"></i> 修改</span>
                          <span @click="deleteArticle(item.id, index)"><i class="el-icon-delete"></i> 删除</span>
                        </div>
                      </el-tooltip>
                    </span>
                    </div>
                  </div>
                </el-card>
                <SLTag type="dark" color="var(--main-green)" class="tags"><i class="sl icon-public"></i></SLTag>
              </el-col>
            </Loading>

            <el-col style="margin-top: 20px">
              <el-pagination v-if="Object.keys(gResult).length" :total="gResult.total" :current-page="gPage"
                             :page-size="size" hide-on-single-page layout="prev, pager, next"
                             @current-change="onPageG"></el-pagination>
            </el-col>
          </el-row>
        </el-tab-pane>


        <el-tab-pane label="私有文章(仅自己可见)" name="second">
          <el-row :gutter="15">
            <Loading :loading="sLoading" :data="sResult.list" height="calc(100vh - 400px)">
              <el-col :md="6" v-for="(item, index) in sResult.list" :key="index" style="margin-bottom: 15px;position: relative">
                <el-card :body-style="{ padding: '0px'}" >
                  <el-image class="image"
                            :src="getUrl(item.image)"
                            fit="cover"/>
                  <div class="info">
                    <SLText size="14">{{item.title}}</SLText>
                    <div class="bottom">
                      <span><i class="el-icon-time"> {{ item.date }}</i></span>
                      <span>
                      <el-tooltip placement="top" effect="light">
                        <i class="sl icon-menu"></i>
                        <div slot="content" class="menu">
                           <span @click="$router.push(item.staticUrl)"><i class="el-icon-view"></i> 查看</span>
                          <span @click="editArticle(item.id)"><i class="sl icon-edit"></i> 修改</span>
                          <span @click="deleteArticle(item.id, index, true)"><i class="el-icon-delete"></i> 删除</span>
                        </div>
                      </el-tooltip>
                    </span>
                    </div>
                  </div>
                </el-card>
                <SLTag type="dark" color="var(--main-red)" class="tags"><i class="sl icon-lock"></i></SLTag>
              </el-col>
            </Loading>
            <el-col style="margin-top: 20px">
              <el-pagination v-if="Object.keys(sResult).length" :total="sResult.total" :current-page="sPage" :page-size="size" hide-on-single-page
                             layout="prev, pager, next" @current-change="onPageS"></el-pagination>
            </el-col>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </div>

  </div>
</template>

<script>
import SLTag from "@/components/SLTag";
import SLText from "@/components/SLText";
import userCenterApi from "@/common/api/userCenterApi";
import Loading from "@/components/Loading";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "MyArticle",
  components: {Loading, SLText, SLTag},
  data() {
    return {
      activeName: 'first',
      gLoading: true,
      sLoading: true,
      gResult: {},
      sResult: {},
      gPage: 1,
      sPage: 1,
      size: 12,
      getUrl: null
    }
  },
  mounted() {
    this.getUrl = getUrl
    userCenterApi.getMyArticle(this.gPage, this.size, true, res => {
      this.gResult = res;
      this.gLoading = false;
    })
    userCenterApi.getMyArticle(this.sPage, this.size, false, res => {
      this.sResult = res;
      this.sLoading = false;
    })
  },
  methods: {
    editArticle(id) {
      this.$router.push({name: 'Edit', params: {"id": id}})
    },
    deleteArticle(id, index, ispub) {
      // 删除文章
      userCenterApi.deleteRemoveArticle(id, res => {
        if (res) {
          this.$message.success("删除成功");
          if (!ispub) this.gResult.list.splice(index, 1);
          this.sResult.list.splice(index, 1);
        }
      })
    },
    onPageG(page) {
      this.gPage = page;
      this.gLoading = true;
      userCenterApi.getMyArticle(this.gPage, this.size, true, res => {
        this.gResult = res;
        this.gLoading = false;
      })
    },
    onPageS(page) {
      this.sPage = page;
      this.sLoading = true;
      userCenterApi.getMyArticle(this.sPage, this.size, false, res => {
        this.sResult = res;
        this.sLoading = false;
      })
    }
  }
}
</script>

<style scoped lang="less">
.image {
  height: 180px;
}

.article-status{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;

  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: column;
  }
}

.tags {
  position: absolute;
  top: 10px;
  left: 20px;
}

.info {
  padding: 0 5px;

  .bottom {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    span:nth-child(2) {
      cursor: pointer;
    }
  }
}

.menu {
  span {
    margin: 5px 10px;
    color: var(--main-black);
    cursor: pointer;

    &:hover {
      color: var(--main-green);
    }
  }

  span:nth-child(3) {
    &:hover {
      color: var(--main-red);
    }
  }
}
</style>
