<template>
  <el-row style="margin-top: 30px">
    <el-col :md="{span:16, offset: 4}" class="shadow user-center">
      <el-row :gutter="10">
        <el-col :md="5">
          <el-menu :default-active="currentIndex.toString()" style="padding: 10px" active-text-color="var(--main-green)"
                   @select="onSelect">
            <el-submenu index="10" v-if="userInfo.level === 'Admin'">
              <template slot="title">
                <i :class="manage.icon" style="font-size: 16px"></i>
                <span> {{ manage.name }}</span>
              </template>
              <el-menu-item :index="'10-'+ index.toString()" v-for="(item, index) in manage.children" :key="index">
                <i :class="item.icon"></i>
                <span style="font-size: 12px"> {{ item.name }}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item :index="index.toString()" v-for="(item, index) in menus" :key="index">
              <div class="menu-item">
                <i :class="item.icon" class="icon"></i>
                <span>{{ item.name }}</span>
              </div>
            </el-menu-item>
          </el-menu>
        </el-col>
        <el-col :md="19" style="padding: 10px 0">
          <transition name="manages" mode="out-in">
            <router-view/>
          </transition>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
/**
 * 用户中心
 */
import {mapState} from 'vuex';

export default {
  name: "UserCenterPage",
  data() {
    return {
      menus: [
        {"name": '基础设置', "icon": 'sl icon-manages', "router": "Base"},
        {"name": '我的积分', "icon": 'sl icon-credit', "router": "Credit"},
        {"name": '我的消息', "icon": 'sl icon-bell', "router": "Message"},
        {"name": '我的收藏', "icon": 'sl icon-collection', "router": "Collection"},
        {"name": '我的文章', "icon": 'sl icon-article', "router": "MyArticle"},
        {"name": '我的关注', "icon": 'sl icon-focus', "router": "/u/1/focus"},
        {"name": '我的粉丝', "icon": 'sl icon-fans', "router": "/u/1/fans"},
      ],
      currentIndex: 0,
      manage: {
        "name": '站点管理',
        "icon": 'sl icon-manage',
        "children": [
          // {name: '站点信息', router: 'WebSettings', icon: "sl icon-Web"},
          {name: '菜单管理', router: 'Menu', icon: "sl icon-menu"},
          {name: '用户管理', router: 'UserManage', icon: "sl icon-manages"},
          {name: '文章管理', router: 'ArticleManage', icon: "sl icon-article"},
        ]
      },
    }
  },
  mounted() {
    this.getCurrentIndex();
  },
  methods: {
    onSelect(index) {
      if (index === 5 || index === 6) {
        this.currentIndex = index;
        this.$router.push(this.menus[index].router)
        return;
      }
      if (index.split('-').length === 2){
        this.currentIndex = index;
        let ind = index.split("-")[1]
        this.$router.push({name: this.manage.children[ind].router})
        return;
      }
      if (index !== this.currentIndex) {
        this.currentIndex = index;
        this.$router.push({name: this.menus[index].router})
      }
    },
    getCurrentIndex() {
      let url = this.$route.path;
      let name = url.split("/user/")
      if (name.length === 2) {
        name = name[1]
      }
      switch (name) {
        case "base":
          this.currentIndex = 0;
          break;
        case "credit":
          this.currentIndex = 1;
          break;
        case "msg":
          this.currentIndex = 2;
          break;
        case "collection":
          this.currentIndex = 3;
          break;
        case "article":
          this.currentIndex = 4;
          break;
        case "website":
          this.currentIndex = '10-0';
          break;
        case "menu":
          this.currentIndex = '10-1';
          break;
        case "user-manage":
          this.currentIndex = '10-2';
          break;
        case "article-manage":
          this.currentIndex = '10-3';
          break;
        default:
          this.currentIndex = 0;
          break;
      }
    }
  },
  computed: {
    ...mapState({
      userInfo : state => {
        if(state.user.userInfo){
          return state.user.userInfo;
        }
        return {level: ""}
      }
    })
  },
  beforeRouteUpdate(to, from, next){
    if (to.path === from.path){
      return;
    }
    next();
  }
}
</script>

<style scoped lang="less">
.user-center {
  margin-bottom: 20px;
  min-height: 80vh;

  .menu-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 16px;
      margin-right: 5px;
    }
  }

}

.manages-enter-active {
  animation: bounce-left .3s ease-in;

  @keyframes bounce-left {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.manages-leave-active {
  transform-origin: 21% 0;
  animation: lik .8s ease-in-out;

  @keyframes lik {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
