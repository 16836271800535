<template>
  <OnSize @onChange="sizeChange">
    <el-row type="flex" :gutter="10" class="top-swiper-hot">
      <!-- 首页轮播 -->
      <el-col :md="{span: 16, offset: 4}" :xs="{span: 24, offset: 0}">
        <el-row :gutter="10">
          <el-col :md="16" :xs="24" style="align-items:flex-start;">
            <el-carousel loop :interval="4000" :height="swiperHeight + 'px'" direction="vertical"
                         indicator-position="none">
              <el-carousel-item v-for="(item,index) in swiper" :key="index">
                <!-- 跳转商品详情 -->
                <div style="position: relative;height:100%;">
                  <el-image :src="getUrls(item.image) + '?s=1125x450'" fit="cover"
                            style="height: 100%;border-radius: 4px"/>
                  <el-tag type="success" class="swiper-tag" @click="onSwiperTag(item.tag)">{{ item.tag }}</el-tag>
                  <div class="swiper-info">
                    <router-link :to="item.staticUrl">
                      <SLText line="2" class="title">{{ item.title }}</SLText>
                    </router-link>
                    <div class="swiper-info-author">
                      <Avatar :lighten="10000" v-if="item.author.username"
                              :src="getUrls(item.author.avatar)"
                              :username="item.author.username"
                              :size="20"/>
                      <span>{{ item.author.name }}</span>
                      <span><i class="el-icon-time"> {{ item.date }}</i></span>
                      <span><i class="el-icon-view"> 浏览 {{ item.view }}</i></span>
                      <span><i class="el-icon-s-comment"> 评论 {{ item.comment }}</i></span>
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
          <el-col :md="8" :xs="24" style="position: relative">
            <!-- 热门文章 -->
            <div class="home-article-hot" v-for="(item,index) in hot" :key="index"
                 :style="{'margin-bottom': index !== 3 ? 50 / 3+ 'px': '0px'}">
              <router-link :to="item.staticUrl">
                <el-row style="height:100%;">
                  <el-col :span="8" style="height:100%;">
                    <el-image :src="getUrls(item.image) + '?s=185x100'" fit="cover"
                              style="height: 100%; border-radius: 4px"/>
                  </el-col>
                  <el-col :span="16" class="home-article-info">
                    <SLTag type="plain" color="var(--main-blue)">{{ item.tag }}</SLTag>
                    <p>{{ item.title }}</p>
                    <div class="home-article-user">
                      <span>{{ item.author.name }}</span>
                      <span><el-icon name="time" style="color:var(--main-green)"/> {{ item.date }}</span>
                      <span>浏览 {{ item.view }}</span>
                    </div>
                  </el-col>
                </el-row>
              </router-link>
            </div>
            <!-- 热门文章标签 -->
            <div class="home-article-hot-tag"></div>

          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </OnSize>
</template>

<script>
import SLTag from "@/components/SLTag"
import OnSize from "@/components/OnSize";
import SLText from "@/components/SLText";
import homeApi from "@/common/api/homeApi";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar"

export default {
  name: "TopSwiperHot",
  data() {
    return {
      swiperHeight: "450",
      swiper: [
        {
          author: {
            avatar: null,
            id: null,
            level: "Ordinary",
            name: ""
          },
          comment: 0,
          date: "",
          description: "",
          staticUrl: "/",
          tag: "",
          title: "",
          view: 0
        }
      ],
      hot: [],
    }
  },
  mounted() {
    homeApi.getSwiper(res => {
      this.swiper = res;
    })
    homeApi.getTopHotArticle(res => {
      this.hot = res.splice(0, 4);
    })
  },
  methods: {
    sizeChange(e) {
      if (e === "max") {
        this.swiperHeight = "450"
      } else if (e === 'min') {
        this.swiperHeight = '250'
      }
    },
    onSwiperTag(tag) {
      this.$router.push({name: 'Tag', params: {name: tag}})
    },
    getUrls(url) {
      if (url)
        return getUrl(url);
    }
  },
  components: {
    SLText,
    OnSize,
    SLTag,
    Avatar
  }
}
</script>

<style scoped lang="less">
.swiper-info {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(100% - 20px);
  padding: 10px;
  text-align: start;
  color: #fff;
  background: #0000001c;

  .title {
    color: #fff !important;
    font-size: 18px !important;

    &:hover {
      color: var(--main-green) !important;
      transition: .2s linear;
    }
  }

  .swiper-info-author {
    display: flex;
    margin-top: 3px;
    font-size: 14px;

    span {
      margin: 0 5px;
    }
  }
}

.home-article-hot {
  height: 100px;

  .home-article-info {
    text-align: start;
    padding-left: 5px;

    p {
      font-size: 13px;
      height: 35px;
      margin: 2px 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    .home-article-user {
      span {
        display: inline-block;
        font-size: 12px;
        margin: 10px 15px 0 0;
        color: #767575
      }
    }
  }
}

.home-article-hot-tag {
  &:after {
    content: '';
    background-image: url("../../../assets/tag-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    width: 120px;
    height: 40px;
    top: -15px;
    right: 0;
    transform: rotate(177deg);
    z-index: 1;
    animation: tag-bg-ani 4.5s infinite;
  }

  &:before {
    content: "Hot";
    position: absolute;
    top: -1px;
    right: 45px;
    color: #fff;
    font-weight: bold;
    font-style: oblique;
    z-index: 99;
    animation: tag-font 4.5s infinite;
  }

  @keyframes tag-font {
    0% {
      transform: scale(0.5) rotate(0deg)
    }
    50% {
      transform: scale(1) rotate(13deg)
    }
    100% {
      transform: scale(0.5) rotate(0deg)
    }
  }

  @keyframes tag-bg-ani {
    0% {
      transform: rotate(177deg)
    }
    50% {
      transform: rotate(190deg)
    }
    100% {
      transform: rotate(177deg)
    }
  }
}

.swiper-tag {
  position: absolute;
  left: 20px;
  top: 20px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .home-article-hot {
    margin-top: 10px;
  }

  .home-article-hot-tag {
    &:after {
      top: 0;
    }

    &:before {
      top: 14px;
    }
  }
}

@media screen and(min-width: 992px) {
  .top-swiper-hot {
    margin: 50px 0;
  }
}
</style>
