<template>
  <el-row style="margin-bottom: 60px">
    <el-col :md="{span: 16, offset: 4}">
      <div class="sl-home-title">
        <h4>精选</h4>
        <p @click="$router.push({name: 'Hot', query: {type: 'chosen'}})">查看更多</p>
      </div>
    </el-col>
    <el-col :md="{span: 16, offset: 4}">
      <el-row v-if="result.length">
        <el-col :md="9" @click.native="toDetails(result[0].staticUrl)" style="cursor: pointer" class="featured-1 gradient-mask">
          <el-image :src="theUrl(result[0].image) + '?s=635x330'"
                    style="height: 100%;border-radius: 2px" fit="cover"/>
          <div class="featured-article-info">
            <SLText color="#fff" line="3" style="font-size:16px;font-weight: bold">
              {{ result[0].title }}
            </SLText>
            <SLText color="#fff" line="2" size="12">{{ result[0].description }}
            </SLText>
            <el-row style="text-align: start; margin-bottom: 15px">
              <el-col :span="12" class="featured-1-user">
                <Avatar :size="20" :lighten="10000" style="display: inline-block;vertical-align:middle"
                        :username="result[0].author.username"
                        :src="theUrl(result[0].author.avatar)"></Avatar>
                <span style="margin-left: 4px">{{ result[0].author.name }}</span>
                <span style="margin-left: 10px">{{ result[0].date }}</span>
              </el-col>
              <el-col :span="12" class="featured-1-icon">
                <span><el-icon name="view"/> {{result[0].view}}</span>
                <span style="margin-left: 10px"><el-icon name="s-comment"/> {{ result[0].comment }}</span>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :md="6" @click.native="toDetails(result[1].staticUrl)" style="cursor: pointer">
          <div class="featured-2">
            <div class="featured-2-shoda">
              <div class="featured-2-image">
                <el-image :src="theUrl(result[1].image) + '?s=403x204'" style="height: 100%" fit="cover"/>
              </div>
              <div style="padding: 0 5px 5px 5px">
                <SLText line="2">{{ result[1].title }}</SLText>
                <SLText line="2" color="#777" size="12">{{ result[1].description }}
                </SLText>
                <div class="featured-2-info">
                  <span>{{ result[1].date }}</span>
                  <span>
                  <i class="el-icon-view"> {{result[1].view}}</i>
                  <i class="el-icon-s-comment" style="margin-left:10px"> {{ result[1].comment }}</i>
                </span>
                </div>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :md="9">
          <el-row class="featured-3" v-for="(item,index) in list" :key="index"
                  :style="{'margin-bottom': index !== 3 ? '10px' : '0'}" style="cursor: pointer" @click.native="toDetails(item.staticUrl)">
            <el-col :span="5" style="height:75px">
              <el-image :src="theUrl(item.image) + '?s=132x75'"
                        style="height: 100%" fit="cover"/>
            </el-col>
            <el-col :span="19" class="featured-3-info">
              <h5>{{ item.title }}</h5>
              <div class="featured-3-count">
                <span>{{item.date}}</span>
                <div>
                  <i class="el-icon-view">{{ item.view }}</i>
                  <i class="el-icon-s-comment" style="margin-left: 10px">{{ item.comment }}</i>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import SLText from "@/components/SLText";
import homeApi from "@/common/api/homeApi";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar"

export default {
  name: "FeaturedArea",
  data(){
    return{
      result: [],
    }
  },
  mounted() {
    homeApi.getChosenArticle(res =>{
      this.result = res;
    })
  },
  methods:{
    theUrl(url){
      return getUrl(url)
    },
    toDetails(url){
      this.$router.push(url)
    }
  },
  computed:{
    list(){
      return this.result.slice(2, 6);
    }
  },
  components: {
    SLText,
    Avatar
  }
}
</script>

<style scoped lang="less">
.featured-1 {
  position: relative;
  height: 330px;

  .featured-article-info {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }

  .featured-1-user {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #fff;
  }

  .featured-1-icon {
    display: flex;
    justify-content: flex-end;
    color: #fff;
    font-size: 12px;
  }
}

.featured-2 {
  padding: 0 10px;

  .featured-2-shoda {
    height: 330px;
    box-shadow: 0 0 35px #cccccc4d;;

    .featured-2-image {
      height: 204px;
    }

    .featured-2-info {
      font-size: 12px;
      color: #777;
      display: flex;
      justify-content: space-between;
    }
  }
}

.featured-3 {
  background-color: #fff;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow .8s ease-in-out;

  &:hover {
    box-shadow: 0 0 10px #cccccc94;
    transition: box-shadow .2s ease-in;
  }

  h5 {
    text-align: left;
    padding-left: 5px;
    font-weight: 400;
    margin: 5px 0 10px 0;
  }

  .featured-3-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 75px;

    .featured-3-count {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      color: #777;
      padding-left: 5px;
    }
  }
}


@media screen and (max-width: 768px) {
  .featured-1 {
    padding: 0 5px;
    border-radius: 4px;

    &:after {
      left: 5px;
      width: calc(100% - 10px);
      border-radius: 4px;
    }
  }

  .featured-2 {
    padding: 0 5px;
    margin: 10px 0;
  }

  .featured-3 {
    padding: 0 5px;
  }
}

@media screen and(min-width: 992px) {
}
</style>
