<template>
  <div :id="id">
  </div>
</template>

<script>
import ClassicEditor from "./ckeditor.js";
import {mapState} from "vuex";

export default {
  name: "CKEditor",
  data() {
    return {
      config: {
        ckfinder: {
          uploadUrl: '/',
          options: {
            language: 'zh-cn',
            chooseFiles: true,
            connectorLanguage: 'java',
            connectorPath: this.$baseUrl + '/ck.manager',
            authentication: true,
            autoCloseHTML5Upload: 3.5,
          }
        },
        placeholder: "这是占位符",
        language: "zh-cn",
        // toolbar: ['imageUpload', 'ckfinder', '|', 'heading', '|', 'bold',
        //   'italic', '|', 'link', 'bulletedList', 'numberedList', 'blockQuote',
        //   '|', 'undo', 'redo']
        // items: [
        //   'heading', '|',
        //   'bold', 'italic', 'strikethrough', 'subscript', 'superscript', 'link', '|',
        //   'bulletedList', 'numberedList', 'todoList', '-',
        //   'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
        //   'code', 'codeBlock', '|',
        //   'outdent', 'indent', '|',
        //   'imageUpload', 'blockQuote', '|',
        //   'undo', 'redo'
        // ],
        shouldNotGroupWhenFull: true
      },
      editor: null,
      initData: false,
    }
  },
  props: {
    id: {
      type: String,
      default: 'editor'
    },
    placeholder: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: ''
    },
    ckfinderUrl: {
      type: String,
      default: ''
    },
    imageUploadUrl: {
      type: String,
      default: ''
    },
    // editor 配置
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
    this.appendCKFinderCore();
    this.config.ckfinder.options.connectorInfo = `token=SL ${this.token}`;
    if (this.placeholder) {
      this.config.placeholder = this.placeholder;
    }
    if (this.ckfinderUrl) {
      this.config.ckfinder.options.connectorPath = this.ckfinderUrl;
    }
    if (this.imageUploadUrl) {
      this.config.ckfinder.uploadUrl = this.imageUploadUrl;
    }
    Object.assign(this.config, this.options);
    this.initialize();
  },
  destroyed() {
    this.editor.destroy();
  },
  methods: {
    appendCKFinderCore() {
      let head = document.head || document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.setAttribute("src", "https://ckeditor.com/apps/ckfinder/3.5.0/ckfinder.js");
      head.appendChild(script);
    },
    initialize() {
      let self = this;
      ClassicEditor.create(document.querySelector('#' + this.id), this.config)
          .then(editor => {
            window.editor = editor;
            self.editor = editor;
            // window.editor.setData("<span style='color:red'>这是set数据</span>")
            if (self.value) {
              editor.setData(self.value)
              self.initData = true;
            }
            editor.model.document.on('change:data', function () {
              self.$emit('change', editor.getData())
              self.$emit("input", editor.getData());
            });
          })
          .catch(error => {
            console.error('初始化编辑器时出现问题。', error);
          });
    }
  },
  computed: {
    ...mapState({
      token: state => state.user.token,
    })
  },
  watch: {
    value() {
      if(!this.initData) {
        this.editor.setData(this.value);
        this.initData = true;
      }
      // this.editor.model.change(writer => { writer.setSelection( this.editor.model.document.getRoot(), 'end' ); });
    }
  }
}
</script>

<style scoped lang="less">
</style>
