const state = {
    token: "" || localStorage.getItem("token"),
    isLogin: localStorage.getItem("token") != null && localStorage.getItem("token") !== "null",
    userInfo: null,
    loginBox: null,
}

const mutations = {
    setToken(state, token) {
        state.token = token;
        localStorage.setItem("token", token);
        state.isLogin = token != null;
    },
    setUserInfo(state, info){
        state.userInfo = info;
    },
    setLoginBox(state, ref){
        state.loginBox = ref;
    }
}

const actions = {}

const getters = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
