<template>
  <div class="sl-tag" :class="getStyle()" :style="color ? style : ''">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "SLTag",
  data() {
    return {
      style: {
        "color": "#ccc",
        "border-color": "#ccc",
        "background-color": "#fff",
      },
    }
  },
  props: {
    type: {
      type: String,
      default: "light"  // dark 填充 / light 白色 / plain 点线
    },
    color: {
      type: String,
      default: null
    },

  },

  methods: {
    getStyle() {
      switch (this.type) {
        case "light":
          if (this.color) {
            this.style.color = this.color;
            this.style["border-color"] = this.color;
          }
          return "sl-list-light";
        case "dark":
          if(this.color){
            this.style.color = "#fff";
            this.style["border-color"] = this.color;
            this.style["background-color"] = this.color;
          }
          return "sl-list-dark";
        case "plain":
          if(this.color){
            this.style.color = this.color;
            this.style["border-color"] = this.color;
          }
          return "sl-list-plain";
        default:
          return "";
      }
    }
  }
}
</script>

<style scoped>
.sl-tag {
  display: inline-block;
  padding: 2px 6px;
  border: 1px #ccc solid;
  font-size: 12px;
  color: #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.sl-tag-dark {
  border-color: var(--main-green);
  color: #fff;
  background-color: var(--main-green);
}

.sl-tag-light {
  border-color: var(--main-green);
  color: var(--main-green);
}

.sl-tag-plain {
  border-color: var(--main-green);
  color: var(--main-green);
  border-style: dotted;
}
</style>
