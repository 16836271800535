<template>
  <div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "OnSize",
  data()  {
    return {
      maxWidth: 992, // 大于992
      minWidth: 768, // 小于768
    }
  },
  mounted() {
    let clientWidth = document.documentElement.clientWidth
    this.onSizeChange(clientWidth)
    let self = this;
    window.onresize = function temp() {
      let clientWidth = document.documentElement.clientWidth
      self.onSizeChange(clientWidth);
    };
  },
  methods: {
    onSizeChange(width){
      if(width >= this.maxWidth){
        this.$emit("onChange", "max")
      }
      if(width < this.minWidth){
        this.$emit("onChange", "min")
      }
    }
  }
}
</script>

<style scoped>

</style>
