<template>
  <div>
    <!-- 顶部轮播热门区域 -->
    <TopSwiperHot/>
    <!-- 热门区域 -->
    <TopHotArea/>
    <!-- 精选 -->
    <FeaturedArea/>
    <!-- 视频专区 -->
    <VideoArea/>
    <!-- 热门标签 -->
    <HotTagArea/>

  </div>
</template>

<script>
import SLTag from "@/components/SLTag";
import SLText from "@/components/SLText";
import VideoArea from "@/views/home/components/VideoArea";
import HotTagArea from "@/views/home/components/HotTagArea";
import TopSwiperHot from "@/views/home/components/TopSwiperHot";
import TopHotArea from "@/views/home/components/TopHotArea";
import FeaturedArea from "@/views/home/components/FeaturedArea";

export default {
  name: 'Home',
  data() {
    return {

    }
  },
  methods: {

  },
  components: {
    FeaturedArea,
    TopHotArea,
    TopSwiperHot,
    HotTagArea,
    VideoArea,
    SLText,
    SLTag,
  }
}
</script>

<style scoped lang="less">
/**
 * 大于等于 992
 */
@media screen and (min-width: 992px) {
  .home-article-hot {
    margin-left: 10px;
  }
  .featured-3{
    text-align: left;

  }
}

/**
 * 小于等于 768
 */
@media screen and (max-width: 768px) {

  .new-article-img{
    height: 250px;
  }

  .home-article-hot-tag {
    &:after {
      top: 0;
    }

    &:before {
      top: 14px;
    }
  }
  .featured-1{
    padding: 0 5px;
    height: 280px;
    &:before{
      width: calc(100% - 10px);
      margin-left: 5px;
    }
  }
  .featured-2{
    padding: 0 5px;
    margin: 10px 0;
  }
  .featured-2-shoda{
    margin: 0 !important;
  }
  .featured-3{
    padding: 0 5px;
  }
}
</style>

<style lang="less">
.sl-home-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 5px;

  h4 {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    padding-left: 15px;
    &:before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--main-green);
      left: 1px;
      top: 9.5px;
    }
  }

  p {
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background: var(--main-black);
    }
  }
}
</style>
