<template>
  <el-row>
    <el-col style="width: 99%">
      <h2>用户管理</h2>
      <div class="base-wrapper">
        <el-input prefix-icon="el-icon-search" style="width: 260px;margin-bottom: 28px"
                  placeholder="输入用户名或邮箱" @keyup.enter.native="onSearch" v-model="searchData"></el-input>
        <el-table stripe border :data="result.list" v-loading="loading" style="min-height: 40vh">
          <el-table-column prop="id" label="ID" width="100px"/>
          <el-table-column prop="nickname" label="用户">
            <template slot-scope="scope">
              <div style="display: flex;flex-direction: row; align-items: center">
                <Avatar :lighten="10000" :username="scope.row.username" :src="getUrl(scope.row.avatar)"
                        :size="30"></Avatar>
                <span style="padding-left: 5px">{{ scope.row.nickname || scope.row.username }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="level" label="等级">
            <template slot-scope="scope">
              {{ getUserLevel(scope.row.level) }}
            </template>
          </el-table-column>
          <el-table-column prop="credit" label="积分"/>
          <el-table-column prop="lastDate" label="上次登录"/>
          <el-table-column label="操作" width="210px">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  :type="scope.row.isEnabled ? 'warning' : 'danger'"
                  @click="handleDisable(scope.$index, scope.row)">{{ scope.row.isEnabled ? "封禁" : "已封" }}
              </el-button>
              <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.$index, scope.row)">删除
              </el-button>
              <el-button
                  size="mini"
                  type="success"
                  @click="temp = scope.row; dialog = true;">奖励
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination style="margin: 50px 0"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page.sync="page"
                       :page-sizes="[10, 20, 30, 40, 60, 80, 100, 200, 500, 1000]"
                       :page-size="size"
                       layout="sizes, prev, pager, next"
                       hide-on-single-page
                       :total="result.total">
        </el-pagination>
      </div>
    </el-col>
    <el-dialog :visible.sync="dialog" title="增加积分" width="400px" center>
      <el-input placeholder="输入积分" type="number" v-model="credit"></el-input>
      <template slot="footer">
        <el-button size="mini" type="info" @click="dialog = false">取消</el-button>
        <el-button size="mini" type="success" @click="onRewardCredit">确定</el-button>
      </template>
    </el-dialog>
  </el-row>
</template>

<script>
import Avatar from "vue-avatar";
import commonApi from "@/common/api/commonApi";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "UserManage",
  data() {
    return {
      page: 1,
      size: 10,
      result: {
        list: []
      },
      getUrl: getUrl,
      loading: true,
      searchData: "",
      dialog: false,
      temp: null,
      credit: 0
    }
  },
  created() {
    commonApi.getUsers(this.page, this.size, res => {
      this.result = res;
      this.loading = false;
    })
  },
  methods: {
    handleDisable(index, item) {
      commonApi.disableUserAccount(item.id, !item.isEnabled, res => {
        if (res) {
          this.$message.success(!item.isEnabled ? "该账户解封成功~" : "账户禁用成功");
          this.$set(this.result.list[index], "isEnabled", !item.isEnabled);
        }
      })
    },
    handleDelete(index, item) {
      commonApi.removeUser(item.id, res => {
        if (res) {
          this.$message.success("删除成功~");
          this.result.list.splice(index, 1);
        }
      })
    },
    handleSizeChange(size) {
      this.loading = true;
      this.size = size;
      commonApi.getUsers(this.page, this.size, res => {
        this.result = res;
        this.loading = false;
      })
    },
    handleCurrentChange(page) {
      this.loading = true;
      this.page = page;
      if (this.searchData) {
        commonApi.searchUsers(this.page, this.size, this.searchData, res => {
          this.loading = false;
          this.result = res;
        })
      } else {
        commonApi.getUsers(this.page, this.size, res => {
          this.result = res;
          this.loading = false;
        })
      }
    },
    getUserLevel(level) {
      if (level === 'Admin') return "管理员";
      if (level === 'Ordinary') return "普通会员";
      if (level === 'Senior') return "高级会员";
      return "未知"
    },
    onSearch() {
      this.loading = true;
      if (this.searchData) {
        this.page = 1;
        commonApi.searchUsers(this.page, this.size, this.searchData, res => {
          this.loading = false;
          this.result = res;
        })
      } else {
        commonApi.getUsers(this.page, this.size, res => {
          this.result = res;
          this.loading = false;
        })
      }
    },

    onRewardCredit() {
      if (this.credit > 0)
        commonApi.rewardUserCredit(this.temp.id, this.credit, res => {
          if (res){
            this.$message.success("积分增加成功~");
            if (this.searchData){
              commonApi.searchUsers(this.page, this.size, this.searchData, res => {
                this.loading = false;
                this.result = res;
              })
            } else{
              commonApi.getUsers(this.page, this.size, res => {
                this.result = res;
                this.dialog = false;
              })
            }
          }
        })
    }
  },
  components: {Avatar}
}
</script>

<style scoped>

</style>
