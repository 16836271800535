<template>
  <div class="content-more" :class="status ? '' : 'hidden-more'" ref="c1">
    <div v-for="(item, index) in listData" :key="index" style="margin-bottom: 40px">
      <div class="user-info">
        <Avatar class="avatar" :size="40" :lighten="10000" :username="item.user.nickname"
                :src="getAvatarUrl(item.user.avatar)"></Avatar>
        <span>{{ item.user.nickname }} <i class="sl icon-aite"></i> {{ item.parent.nickname }}</span>
      </div>
      <div class="content">
        <span>{{ item.content }}</span>
        <div class="comment-date">
          <div class="interactive" v-if="item.id">
            <span @click="onPraise(item)"><i class="sl icon-zan"></i> 赞({{ item.praise }})</span>
            <span><i class="sl icon-pinglun" @click="onReply(index)"> 回复</i></span>
          </div>
          <span>{{ item.date }}</span>
        </div>
      </div>
    </div>
    <ReplyBox ref="replyBox" :parent="item" :parent-id="parentId" :article-id="articleId" @reply="onReplyOk"/>
  </div>
</template>

<script>
import Avatar from "vue-avatar"
import ReplyBox from "./ReplyBox"
import {mapState} from "vuex";
import commentApi from "@/common/api/commentApi";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "CommentMore",
  data() {
    return {
      height: 0,
      ch: 'auto',
      item: {
        user: {}
      }
    }
  },
  props: {
    status: {
      type: Boolean,
      default: false
    },
    listData: {
      type: Array,
      default: () => {
      }
    },
    articleId: {
      type: Number,
      default: null
    },
    parentId: {
      type: Number,
      default: null,
    }
  },
  mounted() {
    this.getHeight();
  },
  methods: {
    getHeight() {
      this.height = this.$refs.c1.clientHeight + 'px';
      if (!this.status) {
        this.ch = 0;
      } else {
        return this.ch = this.height;
      }
    },
    onReply(index) {
      if (!this.isLogin)
        return this.loginBox.show();
      this.item = this.listData[index];
      this.$refs.replyBox.show();
    },
    onReplyOk(data) {
      console.log(data)
      this.listData.unshift(data);
    },
    onPraise(i) {
      commentApi.praiseComment(i.id, () => {
        this.$set(i, "praise", i.praise + 1);
      })
    },
    getAvatarUrl(url) {
      return getUrl(url);
    }
  },
  watch: {
    status() {
      if (!this.status) {
        this.ch = 0;
      } else {
        return this.ch = this.height;
      }
    }
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      loginBox: state => state.user.loginBox
    })
  },
  components: {Avatar, ReplyBox}
}
</script>

<style scoped lang="less">
.article-comment {
  margin-top: 30px;
  margin-bottom: 40px;

  .user-info {
    display: flex;
    align-items: center;
    color: #777;
    font-size: 14px;

    i {
      font-size: 16px;
      color: orange;
      font-weight: 900;
    }

    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .content {
    margin-left: 50px;
    font-size: 13.5px;
    padding-bottom: 20px;
    border-bottom: 1px #cccccc4b solid;

    .comment-date {
      display: flex;
      margin-top: 20px;
      align-items: center;
      color: #999;
      justify-content: space-between;

      .interactive {
        display: flex;
        flex-direction: row;
        font-size: 13.5px;
        margin-right: 10px;

        span {
          position: relative;
          cursor: pointer;
          font-size: 13.5px;
          margin-right: 10px;

          &:hover {
            color: var(--main-green)
          }
        }
      }
    }

    .content-more {
      max-height: 9999999999px;
      margin-top: 20px;
      padding-left: 10px;
      border-left: 5px solid #50d6522b;
      visibility: visible;
      transition: visibility, max-height .2s ease-in;
    }

    .hidden-more {
      //height: 0 !important;
      //visibility: hidden;
      //transition: .2s ease;
      visibility: hidden;
      max-height: 0;
      transition: all .2s ease-out;
    }
  }
}

</style>
