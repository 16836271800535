<template>
  <button class="sl-button" ref="btn" type="button" :disabled="dis" @click="onClick" :style="{'min-width': width + 'px'}">
<!--    <svg-icon class="icon" name="loading" v-if="loading" w="14" h="14"/>-->
    <i class="sl icon-loading icon" v-if="loading"></i>
    <slot v-if="!loading"></slot>
  </button>
</template>

<script>
export default {
  name: "SLButton",
  data(){
    return{
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    width: {
      type: [Number, String],
      default: 64
    }
  },
  mounted() {
    // this.width = this.$refs.btn.getBoundingClientRect().width + "px";
  },
  methods: {
    onClick(){
      this.$emit("click")
    },
  },
  computed: {
    dis() {
      if (this.disabled){
        return this.disabled
      } else{
        return this.loading
      }
    },
  }
}
</script>

<style scoped lang="less">
.sl-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 20px;
  border: 1px #cccccc4b solid;
  color: #2c3e50a6;
  background: #fff;
  border-radius: 3px;
  cursor: pointer;
  min-height: 37px;
  transition: .3s ease;
  justify-content: center;

  &:hover{
    background: var(--main-green);
    color: #fff;
    transition: .3s ease;
  }
  &:active{
    color: #fff;
    background: var(--main-green);
    opacity: 0.5;
    transition: .3s ease;
  }

  &:disabled{
    opacity: 0.5;
    color: #fff;
    cursor: not-allowed;
    background: var(--main-green);
  }

  .icon{
    font-size: 14px;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: iconan .5s linear infinite;

    @keyframes iconan {
      0%{transform: rotate(0deg)}
      100%{transform: rotate(360deg)}
      //100%{transform: rotate(0deg)}
    }
  }
}

</style>
