<template>
  <Loading :data="result" :offset="{left: -5 + 'px'}" height="70vh">
    <div slot-scope="data" class="base">
      <h2>基本信息</h2>
      <div class="base-wrapper">
        <!-- 自定义头像 -->
        <span>头像</span>
        <div class="container">
          <SLUpload :urls="item.url ? [$baseUrl + item.url] : []" @change="onChangeAvatar"
                    v-for="(item, index) in data.data.avatar" :key="index"
                    v-if="item.type === 'Custom'">
            <div slot="no" class="custom-avatar">
              <el-radio v-model="item.active" :label="true" style="margin-left: 5px"
                        @change="onRadioChange($event, index, result.avatar)">自定义
              </el-radio>
            </div>
          </SLUpload>
          <div v-for="(item, index) in data.data.avatar" :key="index" v-if="item.type !== 'Custom'">
            <div class="avatar">
              <el-avatar :src="item.url" :size="50"/>
              <el-radio v-model="item.active" :label="true" @change="onRadioChange($event, index, result.avatar)">
                {{ item.type }}
              </el-radio>
            </div>
          </div>
        </div>
      </div>

      <div class="base-wrapper">
        <span>昵称</span>
        <div class="container">
          <div class="nickname" v-if="item.type === 'Custom'" v-for="(item, index) in data.data.nickname" :key="index">
            <input type="text" class="input" v-model="item.name" placeholder="自定义昵称"
                   v-for="(item, index) in data.data.nickname" v-if="item.type === 'Custom'" @blur="onInputBlur()"/>
            <el-radio v-model="item.active" :label="true" @change="onRadioChange($event, index, data.data.nickname)">自定义
            </el-radio>
          </div>
          <div class="nickname" v-if="item.type !== 'Custom'" v-for="(item, index) in data.data.nickname" :key="index">
            <span style="font-weight: bold">{{ item.name }}</span>
            <el-radio v-model="item.active" :label="true" @change="onRadioChange($event, index, data.data.nickname)">
              {{ item.type }}
            </el-radio>
          </div>
        </div>
      </div>

      <div class="base-wrapper">
        <span>个人描述</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.description" @blur="onInputBlur()"/>
        </div>
      </div>

      <div class="base-wrapper">
        <span>个人网站</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.webUrl" @blur="onInputBlur()"/>
        </div>
      </div>

      <div class="base-wrapper">
        <span>个人封面</span>
        <div class="container" @click="onChangeBg">
          <input type="file" hidden ref="upload"/>
          <el-image :src="userBgImage" fit="cover" class="frontCover"/>
        </div>
      </div>

      <h2>扩展信息</h2>

      <div class="base-wrapper">
        <span>微信二维码</span>
        <div class="container">
          <SLUpload @change="onChangeFile($event, 'weixinQr')" @removeServer="removeServerImage($event, 'weixinQr')"
                    :urls="data.data.weixinQr ? [$baseUrl + data.data.weixinQr] : []" type="square"
                    size="100"></SLUpload>
        </div>
      </div>

      <div class="base-wrapper">
        <span>QQ</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.qq" @blur="onInputBlur()"/>
        </div>
      </div>

      <div class="base-wrapper">
        <span>新浪微博</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.sina" @blur="onInputBlur()"/>
        </div>
      </div>

      <div class="base-wrapper">
        <span>Facebook</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.facebook" @blur="onInputBlur()"/>
        </div>
      </div>

      <div class="base-wrapper">
        <span>Twitter</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.twitter" @blur="onInputBlur()"/>
        </div>
      </div>

      <div class="base-wrapper">
        <span>Google+</span>
        <div class="container">
          <input type="text" class="input" v-model="data.data.google" @blur="onInputBlur()"/>
        </div>
      </div>

      <h2>收款信息</h2>
      <div class="base-wrapper">
        <span>微信收款码</span>
        <div class="container">
          <SLUpload type="square" size="100" @change="onChangeFile($event, 'weixinGathering')"
                    @removeServer="removeServerImage($event, 'weixinGathering')"
                    :urls="data.data.weixinGathering ? [$baseUrl + data.data.weixinGathering] : []"></SLUpload>
        </div>
      </div>
      <div class="base-wrapper">
        <span>支付宝收款码</span>
        <div class="container">
          <SLUpload type="square" size="100" @change="onChangeFile($event, 'alipayGathering')"
                    @removeServer="removeServerImage($event, 'alipayGathering')"
                    :urls="data.data.alipayGathering ? [$baseUrl + data.data.alipayGathering] : []"></SLUpload>
        </div>
      </div>

      <h2>账户绑定</h2>
      <div class="base-wrapper" v-for="(item, index) in data.data.accounts" :key="index">
        <span>{{ item.type === 'Sina' ? '新浪微博' : item.type }}</span>
        <div class="container">
          <SLButton :disabled="item.status" @click="bindAccount(item.type)">{{
              item.status ? '已绑定' : '点击绑定'
            }}
          </SLButton>
        </div>
      </div>

    </div>
  </Loading>
</template>

<script>
import SLUpload from "@/components/SLUpload";
import SLButton from "@/components/SLButton";
import userCenterApi from "@/common/api/userCenterApi";
import userApi from "@/common/api/userApi";
import Loading from "@/components/Loading";
import {mapState} from "vuex";

export default {
  name: "BaseSettings",
  data() {
    return {
      radio: "1",
      bg: require("@/assets/user-default-bg.jpeg"),
      bgFile: null,
      nicknameRadio: "1",
      result: null,
      formData: {}
    }
  },
  mounted() {
    userCenterApi.getUserInfo(res => this.result = res);
    setTimeout(() => {
      if (this.$route.query.bind) {
        this.$message.success("账号绑定成功~")
      }
    }, 1000);
  },
  components: {Loading, SLUpload, SLButton},
  methods: {
    updateUserInformation(formData, callback) {
      userCenterApi.updateUserInfo(formData, () => {
        userCenterApi.getUserInfo((res) => this.result = res);
        if (callback) callback();
      });
    },
    bindAccount(type) {
      switch (type) {
        case "QQ":
          window.location.href = `https://graph.qq.com/oauth2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fapi.solove.love%2Foauth%2Fqq%3Fbind%3Dtrue%26uid%3D${this.uid}&state=c2tpbGxz&client_id=101926579&scope=get_user_info`;
          break;
        case "Github":
          window.location.href = `https://github.com/login/oauth/authorize?client_id=7d98b270fd107f2d8ada&scope=user:email,read:user&state=${this.uid}_bind&sallow_signup=true`;
          break;
        case "Gitee":
          window.location.href = `https://gitee.com/oauth/authorize?client_id=0642827c2cc3a902cc065756b30e02c150fa1d8ae8f51675def9c6664c0ed007&redirect_uri=${this.$baseUrl}/oauth/gitee?uid=${this.uid}&state=true&response_type=code&scope=user_info%20emails`;
          break;
      }
    },
    onChangeAvatar(files) {
      console.log(files)
      if (files.length) {
        const formData = new FormData();
        formData.append("avatar", files[0]);
        formData.append("avatarType", "Custom")
        this.updateUserInformation(formData);
        userApi.getUserInfo();
      }
    },
    onChangeFile(files, type) {
      if (files.length) {
        const formData = new FormData();
        formData.append(type, files[0]);
        this.updateUserInformation(formData);
      }
    },
    onChangeBg() {
      this.$refs.upload.click();
      this.$refs.upload.onchange = (e) => {
        if (e.target.files.length) {
          // this.bg = window.URL.createObjectURL(e.target.files[0]);
          // this.bgFile = e.target.files[0];
          this.bgFile = window.URL.createObjectURL(e.target.files[0]);
          const formData = new FormData();
          formData.append("bgImage", e.target.files[0])
          this.updateUserInformation(formData)
        }
      }
    },
    onRadioChange(e, index, res) {
      for (let i = 0; i < res.length; i++) {
        if (i !== index) {
          res[i].active = false;
        }
      }
      if (res[index].url === undefined) {
        // nickname
        const formData = new FormData();
        formData.append("nicknameType", res[index].type);
        this.updateUserInformation(formData, () => userApi.getUserInfo());
      } else {
        // avatar
        const formData = new FormData();
        formData.append("avatarType", res[index].type);
        this.updateUserInformation(formData, () => userApi.getUserInfo());
      }

    },

    onInputBlur(e) {
      let nicknames = null;
      this.result.nickname.forEach((nickname) => {
        if (nickname.type === 'Custom') {
          nicknames = nickname.name;
        }
      });
      let description, webUrl, qq, sina, facebook, twitter, google;
      description = this.result.description;
      webUrl = this.result.webUrl;
      qq = this.result.qq;
      sina = this.result.sina;
      facebook = this.result.facebook;
      twitter = this.result.twitter;
      google = this.result.google;

      const formData = new FormData();
      if (nicknames)
        formData.append("nickname", nicknames);
      if (description)
        formData.append("description", description);
      if (webUrl)
        formData.append("webUrl", webUrl);
      if (qq)
        formData.append("qq", qq);
      if (sina)
        formData.append("sina", sina);
      if (facebook)
        formData.append("facebook", facebook);
      if (twitter)
        formData.append("twitter", twitter);
      if (google)
        formData.append("google", google);

      this.updateUserInformation(formData)
    },

    removeServerImage(e, type) {
      userCenterApi.deleteImage(type);
    }
  },
  computed: {
    userBgImage() {
      if (this.bgFile) {
        return this.bgFile;
      }
      if (this.result.bgImage) {
        return this.$baseUrl + this.result.bgImage;
      }
      return this.bg;
    },
    ...mapState({
      uid: state => state.user.userInfo.id,
    })
  }
}
</script>

<style scoped lang="less">

.avatar, .custom-avatar, .nickname {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  label {
    margin-left: 5px;

    /deep/ span {
      margin: 0 !important;
    }
  }

  /deep/ .el-avatar {
    background: #fff;
  }
}

.nickname {
  span {
    margin-right: 5px !important;
  }
}
</style>
