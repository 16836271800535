import {handlerRes, hasRes, request} from "@/common/request";
import {Message} from "element-ui";
import fa from "element-ui/src/locale/lang/fa";
import ar from "element-ui/src/locale/lang/ar";

const commonApi = {

    /**
     * 获取站点访问量
     * @param callback
     */
    getViews(callback) {
        request({
            url: "/sys/views",
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                console.warn(res.message);
            }
        })
    },

    /**
     * 获取用户签到状态
     * @param method get 请求签到状态 post 签到
     * @param callback
     */
    getSignInState(method, callback) {
        request({
            url: "/user/sign-in",
            method: method,
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 获取积分获取方法
     * @param callback
     */
    getSignInMethods(callback) {
        request({
            url: "/manager.credit",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取积分记录
     * @param page
     * @param size
     * @param callback
     */
    getCreditRecord(page, size, callback) {
        request({
            url: "/manager.credit/record" + `?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 推广链接注册
     * @param form 表单数据
     * @param callback
     */
    promoteRegister(form, callback) {
        request({
            url: "/user/promote.register",
            method: 'post',
            data: form,
            success: res => {
                if (hasRes(res)) {
                    return callback(true)
                }
                Message.warning(res.message);
                return callback(false);
            }
        })
    },


    /**
     * 获取站点所有用户
     * @param page
     * @param size
     * @param callback
     */
    getUsers(page, size, callback) {
        request({
            url: "/manager.user" + `?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 搜索用户
     * @param page
     * @param size
     * @param kw 关键字
     * @param callback
     */
    searchUsers(page, size, kw, callback) {
        request({
            url: `/manager.user/search?page=${page}&size=${size}&kw=${kw}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 封禁账户
     * @param uid 用户id
     * @param status true 解封  false 封禁
     * @param callback
     */
    disableUserAccount(uid, status, callback) {
        request({
            url: '/manager.user/disable',
            method: "post",
            data: {uid, status},
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 删除用户
     * @param uid 用户id
     * @param callback
     */
    removeUser(uid, callback) {
        request({
            url: '/manager.user/del',
            method: "delete",
            data: {uid},
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 奖励用户积分
     * @param uid 用户id
     * @param credit 积分
     * @param callback
     */
    rewardUserCredit(uid, credit, callback) {
        request({
            url: '/manager.user/reward',
            method: "post",
            data: {uid, credit},
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取所有文章
     * @param page
     * @param size
     * @param callback
     */
    getAllArticle(page, size, callback) {
        request({
            url: '/manager.article/all?' + `page=${page}&size=${size}`,
            method: 'get',
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取待审核文章
     * @param page
     * @param size
     * @param callback
     */
    getPendingTrial(page, size, callback) {
        request({
            url: "/manager.article/pending.trial" + `?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 审核文章
     * @param articleId 文章id
     * @param status true通过  false  审核未通过
     * @param callback
     */
    examineArticle(articleId, status, callback) {
        request({
            url: '/manager.article/examine',
            method: "post",
            data: {articleId, status: status? 'OK' : 'NotThrough'},
            success: res => {
                if (hasRes(res)) {
                    return callback();
                }
                Message.warning(res.message);
            }
        })
    },


    /**
     * 删除文章
     * @param articleId
     * @param callback
     */
    removeArticle(articleId, callback) {
        request({
            url: "/manager.article/del",
            method: "delete",
            data: {articleId},
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取文章详情
     * @param articleId
     * @param callback
     */
    getArticleDetails(articleId, callback) {
        request({
            url: `/manager.article/details?articleId=${articleId}`,
            method: 'get',
            success: res => handlerRes(res, callback)
        })
    }
}

export default commonApi;
