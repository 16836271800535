<template>
  <div class="base">
    <h2>我的积分</h2>

    <div class="base-wrapper">
      <span>签到</span>
      <div class="container">
        <SLButton @click="onSignIn" :class="state? 'orange' : ''">{{ state ? '已签到' : '点击签到' }}</SLButton>
      </div>
    </div>

    <div class="base-wrapper">
      <span>当前积分</span>
      <div class="container" v-if="Object.keys(this.userinfo || {}).length">
        {{ this.userinfo.credit }}
      </div>
    </div>

    <div class="base-wrapper">
      <span>推广链接</span>
      <div class="container" v-if="Object.keys(this.userinfo || {}).length">
        https://solove.love/promote/{{ this.userinfo.extension.promoteUrl }}
      </div>
    </div>


    <!--    <h2>积分充值</h2>-->
    <!--    <div class="base-wrapper"><span style="text-align:left">积分兑换比例1:10, 即 1元 = 10 积分</span></div>-->
    <!--    <div class="base-wrapper">-->
    <!--      <span style="text-align:left">-->
    <!--        <input type="number" class="credit-input" />-->
    <!--      </span>-->
    <!--      <div class="container">-->
    <!--        <Bubble style="padding: 10px">-->
    <!--          <div class="images-qr">-->
    <!--            <span style="color: red">￥10</span>-->
    <!--            <el-image src="https://img.tukuppt.com//ad_preview/00/16/42/5c99b9b248670.jpg!/fw/980" style="height: 100px; width:100px" fit="cover"></el-image>-->
    <!--          </div>-->
    <!--        </Bubble>-->
    <!--      </div>-->
    <!--    </div>-->

    <h2>获取方法</h2>
    <div class="base-wrapper" v-if="tableData.length">
      <el-table :data="tableData" stripe border>
        <el-table-column prop="type" label="方法"/>
        <el-table-column prop="limitTime" label="时间"/>
        <el-table-column prop="limitNum" label="次数"/>
        <el-table-column prop="credit" label="积分"/>
      </el-table>
    </div>
    <div style="text-align: left;padding-left: 20px;font-weight: bold;position: relative" v-if="weixin">
      >注意：
      <p>微信公众号签到，需要进行账户绑定,</p> 您当前<span :style="{color: weixin.status ? ' var(--main-green)' : ' var(--main-red)'}">{{
        weixin.status ? '已绑定' : '未绑定'
      }}</span>，
      <p v-if="!weixin.status">请关注公众号，并回复您的专属关注口令：{{ weixin.code }}</p>
      <el-image fit="cover" :src="require('@/assets/public-qrcode.jpg')" v-if="!weixin.status"
                style="position: absolute; right: 10%; top: 0; height: 100px; width: 100px"></el-image>
    </div>

    <h2>积分记录</h2>
    <Loading :loading="loading" :data="record.list" height="430px">
      <div class="base-wrapper" style="flex-direction: column;">
        <div class="record" v-for="(item, index) in record.list" :key="index">
          <span v-html="item.record"></span>
          <span><em>{{ item.date }}</em></span>
          <span
              :class="item.credit > 0 ? 'add' : 'reduce' ">{{
              item.credit > 0 ? '+' + item.credit : item.credit
            }}</span>
        </div>
      </div>
    </Loading>
    <el-pagination :total="record.total" :current-page="page" :page-size="size" hide-on-single-page
                   layout="prev, pager, next" @current-change="onPage"></el-pagination>
  </div>
</template>

<script>
import SLButton from '@/components/SLButton';
import Bubble from "@/components/Bubble";
import commonApi from "@/common/api/commonApi";
import {mapState} from "vuex";
import Loading from "@/components/Loading";
import userApi from "@/common/api/userApi";

export default {
  name: "Credit",
  data() {
    return {
      tableData: [],
      state: false,
      page: 1,
      size: 10,
      loading: true,
      record: {},
      weixin: null
    }
  },
  mounted() {
    userApi.getUserFocusPublicCode(res => this.weixin = res);
    commonApi.getSignInState("get", res => {
      this.state = res;
    });
    commonApi.getSignInMethods(res => {
      res.forEach(item => {
        if (item.limitNum === 0) {
          item["limitNum"] = '无限制'
        }
        if (item.credit < 0) {
          item["credit"] = '返还' + (100 - (item.credit + 100)).toString() + '%';
        } else {
          item["credit"] = '+' + item.credit.toString()
        }
      })
      this.tableData = res
    });
    commonApi.getCreditRecord(this.page, this.size, res => {
      this.loading = false;
      this.record = res;
    })
  },
  methods: {
    onSignIn() {
      commonApi.getSignInState("post", res => {
        this.state = true;
        this.$message.success("签到成功，积分 +10")
      });
    },
    onPage(page) {
      this.page = page;
      this.loading = true;
      commonApi.getCreditRecord(this.page, this.size, res => {
        this.loading = false;
        this.record = res;
      })
    }
  },
  computed: {
    ...mapState({
      userinfo: state => state.user.userInfo,
    }),
  },
  components: {Loading, Bubble, SLButton}
}
</script>

<style scoped lang="less">
.base {

  .credit-input {
    outline: none;
    padding: 10px;
    border: 1px solid #cccccc4b;
    border-radius: 4px;
  }

  .images-qr {
    display: flex;
    flex-direction: row;
    align-items: center;

  }

  .record {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
    margin: 5px 0;
    background-color: #f0ffea94;

    .add, .reduce {
      font-weight: 700;
    }

    .add {
      color: var(--main-green);
    }

    .reduce {
      color: var(--main-red);
    }
  }
}

.orange {
  background: #f5825a;
  color: #fff;

  &:hover {
    background: #f5825a;
    opacity: 0.8;
  }
}
</style>
