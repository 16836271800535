<template>
  <el-row :gutter="10">
    <Loading :data="result.list" :loading="loading" height="calc(100vh - 611.22px)">
      <el-col :md="6" v-for="(item, index) in result.list" :key="index">
        <div class="author-article" @click="toPage(item.staticUrl)">
          <el-image class="image" :src="getArticleImageUrl(item.image)"
                    fit="cover"/>
          <div class="article-info">
            <SLText size="16">{{ item.title}}</SLText>
            <SLText size="12" line="2" color="#777" style="height: 34px">{{ item.description }}</SLText>
            <div class="user">
            <span>
              <Avatar style="vertical-align: middle; display: inline-block" :lighten="10000" :username="item.author.name"
                         :src="getArticleImageUrl(item.author.avatar)"
                         :size="20"/>
              <div style="display: inline-block; font-size: 12px; vertical-align: middle; margin-left: 2px;width: 60px;overflow: hidden;text-overflow: ellipsis">{{ item.author.name }}</div>
              <span style="margin-left: 10px">{{ item.date }}</span>
            </span>
              <span>
              <span style="margin-right: 5px"><i class="sl icon-pinglun"></i> 评论{{item.comment}}</span>
              <span><i class="el-icon-view"></i> 浏览{{ item.view }}</span>
            </span>
            </div>
          </div>
        </div>
      </el-col>
      <el-col>
        <el-pagination layout="prev, pager, next" :current-page="page" :total="result.total" hide-on-single-page :page-size="12" @current-change="onPage"></el-pagination>
      </el-col>
    </Loading>
  </el-row>
</template>

<script>
import SLText from "@/components/SLText";
import Loading from "@/components/Loading";
import userPersonalApi from "@/common/api/userPersonalApi";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar";

export default {
  name: "Article",
  data(){
    return {
      loading: true,
      result: {},
      page: 1
    }
  },
  mounted() {
    userPersonalApi.getPersonalArticle(this.$route.params.id, this.page, res => {
      this.result = res;
      this.loading = false;
    })
  },
  methods:{
    getArticleImageUrl(url){
      return getUrl(url)
    },
    toPage(url){
      this.$router.push(url);
    },
    onPage(e){
      this.page = e;
      this.loading = true;
      userPersonalApi.getPersonalArticle(this.$route.params.id, this.page, res => {
        this.result = res;
        this.loading = false;
      });
    }
  },
  components: {Loading, SLText, Avatar}
}
</script>

<style scoped lang="less">
.author-article {
  position: relative;
  margin-bottom: 20px;
  box-shadow: 0 0 20px #cccccc4b;
  transition: all .2s ease;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 20px #cccccc;
    transform: scale(1.02);
    transition: all .2s ease;
  }

  .image {
    width: 100%;
    height: 230px;
  }

  .article-info {
    padding: 0 5px;

    .user {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      padding-bottom: 15px;

      span {
        font-size: 12px;
        vertical-align: middle;
      }
    }
  }
}
</style>
