<template>
  <div class="webmaster-information shadow">
    <h3>访问量</h3>
    <h4>{{ views }}</h4>
    <template v-if="!isLogin">
      <p>加入我们，成为我们的一员~~</p>
      <el-button type="success" @click="loginBox.show()">立即加入</el-button>
    </template>
    <template v-else>
      <p style="padding: 0 10px">亲爱的会员，{{ infoText }}</p>
      <div class="btn">
        <el-tooltip effect="light" placement="bottom">
          <template slot="content">
            {{ state ? '(已签到)' : '点击签到' }}, 当前积分: {{ credit }}
          </template>
          <SLButton class="sign-in" width="117.53" :loading="loading" :class="state ? 'ok': ''" @click="onSignIn">
            ╮(￣▽￣)╭
          </SLButton>
        </el-tooltip>
        <el-tooltip effect="light" placement="bottom">
          <template slot="content">
            <div class="qr">
              <img :src="require('@/assets/weixin_pay.png')" alt="微信">
              <img :src="require('@/assets/alipay_pay.png')" alt="支付宝">
            </div>
          </template>
          <SLButton class="a-cup-of-tea">请站长喝茶</SLButton>
        </el-tooltip>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import commonApi from "@/common/api/commonApi";
import SLButton from "@/components/SLButton";

export default {
  name: "WebmasterAside",
  components: {SLButton},
  data() {
    return {
      count: 88888888,
      state: false,
      loading: false,
      credit: 0
    }
  },
  mounted() {
    commonApi.getViews(res => this.count = res);
    if (this.isLogin) {
      commonApi.getSignInState("get", res => {
        this.state = res;
        this.credit = this.userInfo.credit;
      });
    }
  },
  methods: {
    onSignIn() {
      if (this.state) return this.$message.warning("今天已经签到，明天再来吧~");
      ;
      this.loading = true;
      commonApi.getSignInState("post", res => {
        if (res) {
          this.$message.success("签到成功，积分+10");
          this.credit += 10;
          this.state = res;
          this.loading = false;
        }
      })
    }
  },
  watch: {
    isLogin() {
      if (this.isLogin) {
        // 登录  重新获取签到状态
        commonApi.getSignInState("get", res => this.state = res);
      }
    },
    "$store.state.user.userInfo.credit"() {
      if (this.isLogin) {
        this.credit = this.userInfo.credit;
      }
    }
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      loginBox: state => state.user.loginBox,
      userInfo: state => state.user.userInfo
    }),
    views() {
      let start = 3;
      let number = parseInt((this.count.toString().length / start).toString());
      if (number <= 1) {
        return this.count;
      }
      let str = [];
      for (const s of this.count.toString()) {
        str.push(s);
      }
      for (let i = 1; i <= number; i++) {
        str.splice(this.count.toString().length - start, 0, ",");
        start += 3;
        if (start === this.count.toString().length) break;
      }
      let num = "";
      str.forEach(i => num += i);
      return num;
    },

    infoText() {
      let date = new Date();
      let hours = date.getHours();
      if (hours >= 0 && hours < 6) return "夜深了，要注意休息哦❤️~";
      if (hours >= 6 && hours < 11) return "早上好！加油ヾ(◍°∇°◍)ﾉﾞ"
      if (hours >= 11 && hours < 13) return "中午好！在忙也要记得吃午饭哦~"
      if (hours >= 13 && hours < 18) return "下午好，忙碌的一天~";
      if (hours >= 18 && hours < 22) return "晚上好，站长与你同在~";
      if (hours >= 22 && hours <= 23) return "晚安，好梦~💋";
      return hours;
    }
  }
}
</script>

<style scoped lang="less">
.webmaster-information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  border-radius: 8px;
  height: 270px;
  margin-bottom: 20px;
  width: 360px;

  h3 {
    margin: 8px 0;
    font-size: 23px;
  }

  h4 {
    margin: 8px 0;
    font-size: 15px;
  }

  p {
    margin-bottom: 20px;
  }

  .btn {
    display: flex;
    flex-direction: row;

    .sign-in {
      margin-right: 10px;
      border-color: #ec6a31;
      color: #ec6a31;

      &:disabled {
        background: #ec6a31;
        color: white;
      }

      &:hover {
        background: #ec6a31;
        color: white;
      }
    }

    .ok {
      border-color: #ec6a31;
      background: #ec6a31;
      color: white;

      &:hover {
        opacity: 0.8;
      }
    }

    .a-cup-of-tea {
      color: var(--main-green);
      border-color: var(--main-green);

      &:hover {
        color: white;
        background-color: var(--main-green)
      }
    }
  }
}

.qr {
  img {
    width: 100px;
  }
}
</style>
