<template>
  <div class="user-data shadow">
    <div class="info">
      <Avatar :size="50" :lighten="10000" :username="userData.username || '' "
              :src="avatar"></Avatar>
      <div class="level">
        <!--        <i style="color:red">高级会员</i>-->
        <span>最近活跃: {{ userData.lastDate || "潜水" }}</span>
        <span>加入时间: {{ userData.joinDate }} (第 {{ userData.id }} 位成员)</span>
      </div>
    </div>
    <h3>基本信息</h3>
    <div class="basicInformation">
      <div>
        <!-- 更为图标 -->
        <!--        <span class="user-level">高级会员</span>-->
      </div>
      <span>Ta的积分： {{ userData.credit }}</span>
      <span>个人主页： <a :href="personalUrl" alt="个人主页"><i>{{ personalUrl }}</i></a></span>
      <span>个人网站： <a :href="webUrl" alt="个人主页"><i>{{ userData.extension.webUrl }}</i></a></span>
      <span>个人描述： {{ userData.extension.description || '这家伙很懒，什么也没留下~' }}</span>
    </div>

    <h3>推广信息</h3>
    <div style="margin-bottom: 30px">
      <span>推广链接：<input id="copy" readonly class="copy-content" :value="'https://solove.love/promote/' +userData.extension.promoteUrl"/> <i
          class="sl icon-copy copy" @click="onCopy"></i></span>
    </div>

    <template v-if="userData.extension.alipayGathering || userData.extension.weixinGathering">
      <h3>收款信息</h3>
      <div class="receivePayment">
        <img src="../../../assets/shang.gif" class="shang">
        <Bubble>
          <div class="qrcode">
            <img :src="$baseUrl + userData.extension.weixinGathering"/>
            <img :src="$baseUrl + userData.extension.alipayGathering"/>
          </div>
        </Bubble>
      </div>
    </template>

    <template v-if="isExtension">
      <h3>扩展信息</h3>
      <div class="extendedInformation">
        <!-- qq 对话链接 -->
        <SLButton v-if="userData.extension.qq" @click="onExtension('qq')"><i class="sl icon-qq"> QQ</i></SLButton>
        <SLButton v-if="userData.extension.sina"><i class="sl icon-sina"> 微博</i></SLButton>
        <SLButton v-if="userData.extension.facebook"><i class="sl icon-facebook"> Facebook</i></SLButton>
        <SLButton v-if="userData.extension.twitter"><i class="sl icon-twitter"> Twitter</i></SLButton>
        <SLButton v-if="userData.extension.google"><i class="sl icon-google"> Google+</i></SLButton>
        <el-tooltip effect="light" v-if="userData.extension.weixinQr">
          <template slot="content">
            <img :src="userData.extension.weixinQr" width="150" style="object-fit: cover">
          </template>
          <SLButton><i class="sl icon-weixin"> 微信</i></SLButton>
        </el-tooltip>
      </div>
    </template>

  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import Bubble from "@/components/Bubble";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar"
import device from "current-device";

let Base64 = require('js-base64').Base64

export default {
  name: "Data",
  components: {Bubble, SLButton, Avatar},
  data() {
    return {
      personalUrl: window.location.href
    }
  },
  props: {
    userData: {
      type: Object,
      default: {}
    }
  },
  methods: {
    onCopy() {
      let input = document.getElementById("copy");
      input.focus();
      input.select();
      let bo = document.execCommand("copy", false)
      input.blur();
      if (bo) {
        this.$message.success("复制成功~")
      }
    },
    onExtension(t) {
      if (t === "qq") {
        if (device.windows() || device.desktop()) {
          if (t === "qq") return open(`tencent://message/?uin=${this.userData.extension.qq}&Site=Sambow&Menu=yes`);
        }
        return open(`mqqwpa://im/chat?chat_type=wpa&uin=${this.userData.extension.qq}&version=1&src_type=web&web_src=solove.love`);
      }
      if (t === 'sina') return open("https://weibo.com/u/" + this.userData.extension.sina);
      if (t === 'facebook') return open(this.userData.extension.facebook);
      if (t === 'twitter') return open(this.userData.extension.twitter);
      if (t === 'google') return open(this.userData.extension.googel);
    }
  },
  computed: {
    avatar() {
      if (this.userData.avatar)
        return getUrl(this.userData.avatar);
    },
    isExtension() {
      let keys = ["facebook", "google", "qq", "sina", "twitter", "weixinQr"]
      for (const key of Object.keys(this.userData.extension)) {
        if (keys.includes(key)) {
          if (this.userData.extension[key])
            return true;
        }
      }
      return false;
    },
    webUrl() {
      if (this.userData.extension.webUrl) {
        let reg = /http(s)?:\/\/.*/g
        if (reg.test(this.userData.extension.webUrl))
          return "/to/" + Base64.encodeURI(this.userData.extension.webUrl);
      }
      return null;
    }
  },
}
</script>

<style scoped lang="less">
.copy {
  cursor: pointer;
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}

.copy-content {
  border: none;
  background: #fff;
  color: var(--main-black);
  font-style: italic;
  outline: none;
}

.user-data {
  padding: 30px;
  border-radius: 8px;
  text-align: left;
  min-height: 50vh;
  box-shadow: 0 0 20px #cccccc30;

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .level {
      display: flex;
      flex-direction: column;
      margin-left: 30px;
      font-size: 14px;

      span:nth-child(1) {
        padding-bottom: 10px;
      }
    }
  }

  .basicInformation {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    line-height: 30px;

    .user-level {
      display: inline-block;
      padding: 2px 15px;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
      font-style: italic;
      border-radius: 50% 4px 50% 4px;
      transform: scale(0.8);
      background: linear-gradient(#ffa50066, #ff6600, #ffa50066);
    }
  }

  .extendedInformation {
    display: flex;
    flex-direction: row;

    button {
      margin-right: 20px;
      color: #fff;
      border-radius: 8px;

      i {
        font-size: 14px;
      }
    }

    button:nth-child(1) {
      background: #ff5d4ae8;
    }

    button:nth-child(2) {
      background: #ff0000cf;
    }

    button:nth-child(3) {
      background: #2f4b93
    }

    button:nth-child(4) {
      background: #5ed7fc
    }

    button:nth-child(5) {
      background: #f45e46
    }

    button:nth-child(6) {
      background: var(--main-green)
    }
  }

  .receivePayment {
    display: flex;
    flex-direction: row;
    align-items: center;

    .shang {
      width: 100px;
    }

    .qrcode {
      padding: 10px;

      img {
        width: 100px;
        max-height: 100px;
        object-fit: cover;
      }

      img:nth-child(1) {
        margin-right: 10px;
      }
    }
  }
}

h3 {
  position: relative;
  margin: 40px 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 100%;
    height: 1px;
    background-color: #cccccc4b;
  }
}

</style>
