<template>
  <div>
    <div style="height: calc(100vh - 235px);padding-top: 20px;">
      <el-col :md="{span: 16, offset: 4}">
        <el-row style="margin: 40px 0">
          <h1>{{ title }}</h1>
        </el-row>
        <el-row v-if="$route.params.type === 'links'" class="links-container">
          <template v-for="item in result">
            <router-link :to="'/to/'+getBase64Url(item.link)"><span class="links" :class="getColor()"
                                                                    :style="getStyle()">{{ item.name }}</span>
            </router-link>
          </template>
          <template v-for="item in markData">
            <router-link to=""><span class="links" :class="getColor()" :style="getStyle()">{{ item.name }}</span>
            </router-link>
          </template>
          <a @click="contactMe"><span class="links lightBlue" style="animation-delay: .5s">友联申请</span></a>
        </el-row>
        <el-row v-if="$route.params.type === 'clauseTreaty'">
          <div v-html="result"></div>
        </el-row>
      </el-col>
    </div>

  </div>
</template>

<script>
import device from "current-device";
import infoApi from "@/common/api/infoApi";
import clauseTreaty from "./js/ClauseTreaty"

let Base64 = require('js-base64').Base64

export default {
  name: "Info",
  data() {
    return {
      title: '',
      colors: ['orange', "green", "pink", "purple", "blue"],
      result: [],
      markData: [{"name": "新", "url": ""}, {"name": "年", "url": ""}, {
        "name": "快",
        "url": ""
      }, {"name": "乐", "url": ""}, {"name": "→→", "url": ""},]
    }
  },
  mounted() {
    switch (this.$route.params.type) {
      case "links":
        this.title = "友情链接";
        infoApi.getLinks(res => this.result = res)
        break;
      case "clauseTreaty":
        this.title = "条款条约"
        this.result = clauseTreaty;
        break;
      default:
        break;
    }
  },
  methods: {
    getBase64Url(url) {
      return Base64.encodeURI(url);
    },
    contactMe() {
      if (device.windows() || device.desktop()) {
        open(`tencent://message/?uin=475868008&Site=Sambow&Menu=yes`);
      }
      open(`mqqwpa://im/chat?chat_type=wpa&uin=475868008&version=1&src_type=web&web_src=solove.love`);
    },
    getNum(min, max) {
      return parseInt(Math.random() * (max - min + 1) + min, 10);
    },
    getAnimationDelay() {
      return this.getNum(1, 9) / 10
    },
    getSize() {
      return this.getNum(80, 200);
    },
    getColor() {
      let num = this.getNum(0, 4);
      return this.colors[num];
    },
    getStyle() {
      let size = this.getSize();
      return {'animation-delay': this.getAnimationDelay() + 's', width: size + 'px', height: size + 'px'}
    }
  },
  computed: {}
}
</script>

<style scoped lang="less">
.links {
  border-radius: 50%;
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  padding: 10px;
  animation: float-an 4s infinite ease-in-out;

}

@keyframes float-an {
  0% {
    transform: translateY(-10px)
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(-10px)
  }
}

a {
  display: inline-block;
  color: #fff;
  margin: 0 20px;
  transition: all .5s linear;

  &:before {
    content: none;
  }

  &:hover {
    color: #fff !important;
    transform: scale(1.1);
    transition: transform .5s linear;
  }
}

.orange {
  background: radial-gradient(#f0875a 40%, #fff);
  box-shadow: 0 0 1px #f0875a;
}

.green {
  background: radial-gradient(var(--main-green) 40%, #fff);
  box-shadow: 0 0 1px var(--main-green);
}

.pink {
  background: radial-gradient(#ff9d9d 40%, #fff);
  box-shadow: 0 0 1px #ff9d9d;
}

.purple {
  background: radial-gradient(#c866ff 40%, #fff);
  box-shadow: 0 0 1px #c866ff;
}

.blue {
  background: radial-gradient(#66c2ff 40%, #fff);
  box-shadow: 0 0 1px #66c2ff;
}

.lightBlue {
  padding: 20px;
  background: radial-gradient(#66c2ff 80%, #fff);
  box-shadow: 0 0 1px #66c2ff;
}

.links-container {
  height: calc(100vh - 400px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
</style>