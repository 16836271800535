import {handlerRes, hasRes, request} from "@/common/request";
import {Message} from "element-ui";

const homeApi = {

    /**
     * 获取轮播
     * @param callback
     */
    getSwiper(callback) {
        request({
            url: "/home/swiper",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取首页顶部 热门文章  8 篇
     * @param callback
     */
    getTopHotArticle(callback) {
        request({
            url: "/home/hot",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取更多热门文章
     * @param page 页码
     * @param size 单页数据量
     * @param callback
     */
    getTopMoreHotArticle(page, size, callback) {
        request({
            url: `/home/hot.more?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取首页6条精选文章
     * @param callback
     */
    getChosenArticle(callback) {
        request({
            url: "/home/chosen",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取首页更多精选文章
     * @param page
     * @param size
     * @param callback
     */
    getChosenMoreArticle(page, size, callback) {
        request({
            url: `/home/chosen.more?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取 3 篇视频文章
     * @param callback
     */
    getVideoArticle(callback) {
        request({
            url: "/home/video?tagName=视频",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取更多视频文章
     * @param page
     * @param size
     * @param callback
     */
    getVideoArticleMore(page, size, callback) {
        request({
            url: `/home/video.more?page=${page}&size=${size}&tagName=视频`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取最新发布的文章
     * @param callback
     */
    getNewArticle(callback) {
        request({
            url: "/home/new",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取更多最新发布的文章
     * @param page
     * @param size
     * @param callback
     */
    getNewArticleMore(page, size, callback) {
        request({
            url: `/home/new.more?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 订阅
     * @param email
     * @param callback
     */
    feedWebmaster(email, callback) {
        request({
            url: "/home/feed",
            method: "post",
            data: {email},
            success: res => {
                if (hasRes(res)) {
                    Message.success("感谢您的订阅，当站点资源发生更新时，会推送消息到您的邮箱哦~")
                    return callback();
                }
                Message.warning(res.message)
            }
        })
    },

    /**
     * 获取热门标签 5  个
     * @param callback
     */
    getHotTag(callback) {
        request({
            url: "/home/hot.tag",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 根据标签获取文章
     * @param name 标签名称
     * @param page 页码
     * @param size 单页数据量
     * @param callback
     */
    getTagArticleMore(name, page, size, callback) {
        request({
            url: `/home/video.more?page=${page}&size=${size}&tagName=${name}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },
}

export default homeApi;