let emoji = ['😀',
    '😁',
    '😂',
    '😃',
    '😄',
    '🤣',
    '😅',
    '😆',
    '😇',
    '😉',
    '😊',
    '🙂',
    '🙃',
    '😋',
    '😌',
    '😍',
    '🥰',
    '😘',
    '😗',
    '😙',
    '😚',
    '🤪',
    '😜',
    '😝',
    '😛',
    '🤑',
    '😎',
    '🤓',
    '🧐',
    '🤠',
    '🥳',
    '🤗',
    '🤡',
    '😏',
    '😶',
    '😐',
    '😑',
    '😒',
    '🙄',
    '🤨',
    '🤔',
    '🤫',
    '🤭',
    '🤥',
    '😳',
    '😞',
    '😟',
    '😠',
    '😡',
    '🤬',
    '😔',
    '😕',
    '🙁',
    '😬',
    '🥺',
    '😣',
    '😖',
    '😫',
    '😩',
    '🥱',
    '😤',
    '😮',
    '😱',
    '😨',
    '😰',
    '😯',
    '😦',
    '😧',
    '😢',
    '😥',
    '😪',
    '🤤',
    '😓',
    '😭',
    '🤩',
    '😵',
    '🥴',
    '😲',
    '🤯',
    '🤐',
    '😷',
    '🤕',
    '🤒',
    '🤮',
    '🤢',
    '🤧',
    '🥵',
    '🥶',
    '😴',
    '💤',
    '😈',
    '👿',
    '👹',
    '👺',
    '💩',
    '👻',
    '💀',
    '☠',
    '👽',
    '🤖',
    '🎃',
    '😺',
    '😸',
    '😹',
    '😻',
    '😼',
    '😽',
    '🙀',
    '😿',
    '😾',
    '👐',
    '🤲',
    '🙌',
    '👏',
    '🙏',
    '🤝',
    '👍',
    '👎',
    '👊',
    '✊',
    '🤛',
    '🤜',
    '🤞',
    '🤘',
    '🤟',
    '👌',
    '🤏',
    '👈',
    '👉',
    '👆',
    '👇',
    '✋',
    '🤚',
    '🖐',
    '🖖',
    '👋',
    '🤙',
    '💪']


const emojiHandler = {
    data(){
        let temp = [];
        let begin = 0;
        let after = 28;
        let num = after;
        for (let i = 0; i < ~~(emoji.length / num) + 1; ++i){
            temp.push(emoji.slice(begin, after));
            begin = after;
            after += num;
        }
        return temp;
    }
}


export default emojiHandler;
