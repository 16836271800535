<template>
  <div class="base">
    <h2>收藏的文章</h2>
    <Loading :loading="loading" :data="result.list" height="calc(100vh - 385px)">
      <el-row :gutter="10" class="article-coll" v-for="(item,index) in result.list" :key="index" @click.native="$router.push(item.staticUrl)">
        <el-col :span="5" style="height: 100%">
          <el-image :src="getUrls(item.image)" fit="cover"></el-image>
        </el-col>

        <el-col :span="19"
                style="height: 100%;text-align: left;display: flex; justify-content: center; flex-direction: column">
          <SLText line="2" size="18">{{ item.title }}</SLText>
          <SLText line="2" color="#777" size="12">{{ item.description }}</SLText>
          <div class="article-info">
            <div>
              <Avatar class="avatar" :size="30" :lighten="10000" :username="item.author.username"
                      :src="getUrls(item.author.avatar)"></Avatar>
              <p>{{ item.nickname || item.username }}</p>
            </div>
            <span><i class="el-icon-time"> {{ item.date }}</i></span>
            <span><i class="el-icon-view"> {{item.view}}</i></span>
            <span><i class="sl icon-pinglun"> {{ item.comment }}</i></span>
          </div>
        </el-col>
        <div class="operating">
          <el-tooltip content="取消收藏" placement="top">
            <i class="sl icon-cancel-coll cancel" @click.stop="cancelColl(item.id, index)"></i>
          </el-tooltip>
        </div>
      </el-row>
    </Loading>

    <el-pagination :total="result.total" v-if="Object.keys(result).length" :current-page="page" :page-size="size" hide-on-single-page
                   layout="prev, pager, next" @current-change="onPage"></el-pagination>
  </div>
</template>

<script>
import SLText from "@/components/SLText";
import userCenterApi from "@/common/api/userCenterApi";
import Loading from "@/components/Loading";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar";
import articleApi from "@/common/api/articleApi";

export default {
  name: "Collections",
  data(){
    return{
      page: 1,
      size: 10,
      result: {},
      loading: true
    }
  },
  mounted() {
    userCenterApi.getUserCollections(this.page, this.size, res =>{
      this.loading = false
      this.result = res;
    })
  },
  methods:{
    onPage(page){
      this.page = page;
      this.loading = true;
      userCenterApi.getUserCollections(this.page, this.size, res =>{
        this.loading = false;
        this.result = res;
      })
    },
    getUrls(url){
      return getUrl(url);
    },
    cancelColl(id, index){
      articleApi.cancelCollection(id, res =>{
        this.result.list.splice(index, 1);
      })
    }
  },
  components: {Loading, SLText, Avatar}
}
</script>

<style scoped lang="less">
.cancel{
  &:active{
    opacity: 0.8;
  }
}
.article-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  div:nth-child(1) {
    display: flex;
    align-items: center;

    p {
      font-size: 14px;
      margin-left: 2px;
    }

    margin-right: 10px;
  }

  span {
    i {
      font-size: 14px;
    }
  }

  span:nth-child(2), span:nth-child(3), span:nth-child(4) {
    margin-right: 30px;
  }

}

.article-coll {
  padding: 0 20px;
  height: 160px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;

  &:hover > .operating{
    display: flex;

    animation: cancel .5s linear  normal;

    @keyframes cancel{
      0% {
        transform: translateX(100px);
        opacity: 0;
      }
      100%{
        transform: translateX(0) rotate(360deg);
        opacity: 1;
      }
    }
  }
}

.operating {
  position: absolute;
  top: 0;
  right: 22px;
  height: 100%;
  display: none;
  align-items: center;
  cursor: pointer;

  &:hover {
    display: flex;
  }

  i {
    font-size: 28px;
    color: orange;
  }
}
</style>
