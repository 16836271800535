<template>
  <div :class="showTop ? 'show-top': 'hide-top'" :style="{display:display }">
    <div class="back-top" @click="onTop">
      <el-icon name="d-arrow-left" style="font-size: 12px"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "OnTop",
  data() {
    return {
      showTop: false,
      display: "flex"
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showTop = window.pageYOffset > 100;
    },
    onTop() {
      window.scrollTo({top: 0, behavior: "smooth"})
    }
  },
  watch: {
    showTop(){
      if (this.showTop) {
        this.display = "flex"
      } else {
        setTimeout(()=> this.display = "none" ,1000);
      }
    }
  }
}
</script>

<style scoped lang="less">
.back-top {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
  right: 1%;
  bottom: 10%;
  background-color: var(--main-green);
  color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  animation: backTop 4s ease-in-out infinite;
  z-index: 9999;

  @keyframes backTop {
    0% {
      transform: translateY(-5px) rotate(90deg);
    }
    50% {
      transform: translateY(10px) rotate(90deg);
    }
    100% {
      transform: translateY(-5px) rotate(90deg);
    }
  }
}

.show-top {
  opacity: 1;
  animation: show-top-an 1s ease-in normal;

  @keyframes show-top-an {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
}

.hide-top {
  opacity: 0;
  animation: hide-top-an 1s ease-in normal;

  @keyframes hide-top-an {
    0% {
      opacity: 1
    }
    100% {
      opacity: 0;
      display: none;
    }
  }
}


/**
 * 大于等于 992
 */
@media screen and (min-width: 992px) {
  .back-top {
    right: 1%;
  }
}

/**
 * 小于等于 768
 */
@media screen and (max-width: 768px) {
  .back-top {
    right: 5%;
  }
}
</style>
