import {handlerRes, hasRes, request} from "@/common/request";
import Message from "element-ui/packages/message/src/main";
import store from "@/store/index"
import router from "@/router";
import hasPermission from "@/router/permission";

const userApi = {
    /**
     * 登录
     * @param username
     * @param password
     * @param success 成功回调
     */
    login(username, password, success) {
        request({
            url: "/user/login",
            method: "POST",
            data: {username, password},
            success: (res) => {
                if (res.code === 10000) {
                    store.commit("user/setToken", res.result)
                    Message({message: "登陆成功，欢迎回来~", type: "success", customClass: "max-z-index"})
                    this.getUserInfo();
                    success(true)
                } else {
                    Message({message: res.message, type: "error", customClass: "max-z-index"})
                    success(false)
                }
            }
        })
    },

    /**
     * 获取用户信息
     * @param callback 回调
     */
    getUserInfo(callback) {
        request({
            url: "/user/info",
            method: "GET",
            success: (res) => {
                if (res.code === 10000) {
                    store.commit("user/setUserInfo", res.result);
                } else if (res.code === 50001) {
                    this.logout(true);
                } else {
                    console.error("获取用户信息异常~");
                }
                if (callback) callback(res.code === 10000)
            }
        })
    },

    /**
     * 退出登录
     */
    logout(show) {
        store.commit("user/setToken", null);
        store.commit("user/setUserInfo", null);
        if (!show)
            Message({message: "退出成功，要记得回来哦~", type: "success", customClass: "max-z-index"})
        const {permission} = router.currentRoute.meta
        if (!hasPermission(permission)) {
            router.push("/");
        }
    },

    /**
     * 注册
     * @param username
     * @param password
     * @param email
     * @param callback
     */
    register(username, password, email, callback) {
        request({
            url: "/user/register",
            method: "POST",
            data: {username, password, email},
            success: (res) => {
                if (res.code === 10000) {
                    callback(true);
                    Message({
                        message: "注册成功，系统已经下发一封包含激活链接的邮件，请尽快激活您的账户~",
                        type: "success",
                        customClass: "max-z-index",
                        duration: 5000
                    })
                } else {
                    callback(false)
                    Message({message: res.message, type: "error", customClass: "max-z-index"})
                }
            }
        })
    },

    /**
     * 激活账户
     * @param token
     * @param callback
     */
    activeAccount(token, callback) {
        request({
            url: "/user/active/" + token,
            method: "post",
            success: (res) => {
                callback(res.code === 10000);
            }
        })
    },

    /**
     * 发送激活邮件
     * @param email
     * @param callback
     */
    sendEmail(email, callback) {
        request({
            url: "/user/active/send",
            method: "POST",
            data: {email},
            success: (res) => {
                if (callback)
                    callback(res.code === 10000)
                if (res.code === 10000) {
                    Message.success({message: "下发成功，注意查收~", customClass: "max-z-index"})
                } else {
                    Message.warning({message: res.message, customClass: "max-z-index"})
                }
            }
        })
    },

    /**
     * 获取用户积分排行
     */
    getRanking(callback) {
        request({
            url: "/user/ranking",
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                Message.warning(res.message);
            }
        })
    },


    /**
     * 找回密码
     * @param email 邮箱
     * @param callback
     */
    restPassword(email, callback) {
        request({
            url: "/user/retrieve.pw",
            method: "post",
            data: {email},
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 修改密码
     * @param token
     * @param password
     * @param callback
     */
    changePassword(token, password, callback) {
        request({
            url: "/user/rest.pw",
            method: "post",
            data: {token, password},
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取绑定公众号回复码
     * @param callback
     */
    getUserFocusPublicCode(callback) {
        request({
            url: "/user/public.code",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    }
}

export default userApi
