<template>
  <el-row class="menu-nav">
    <el-col class="hidden-xs-only" :md="{span: 16, offset: 4}" :xs="{span: 24, offset: 0}">
      <el-menu :default-active="active" mode="horizontal" text-color="#474747" style="border:none !important"
               active-text-color="var(--main-green)" router>
        <router-link to="/">
          <el-image :src="require('@/assets/logo.png')" class="logo" fit="contain"></el-image>
        </router-link>

        <template v-for="(item,index) in menus">
          <el-menu-item :index="getPath(item)" v-if="!item.children.length">
            {{ item.name }}
          </el-menu-item>
          <el-submenu :index="getPath(item)" v-else>
            <template slot="title">{{ item.name }}</template>
            <template v-for="(items,indexed) in item.children">
              <el-menu-item :index="getPath(items)" v-if="!items.children.length">{{ items.name }}</el-menu-item>
              <el-submenu :index="getPath(items)" v-else>
                <template slot="title">{{ items.name }}</template>
                <template v-for="(itemss,indexeds) in items.children">
                  <el-menu-item :index="getPath(itemss)">{{ itemss.name }}</el-menu-item>
                </template>
              </el-submenu>
            </template>
          </el-submenu>
        </template>


        <el-container class="header-right">
          <el-icon name="search" @click.native="onSearch()"></el-icon>
          <el-container class="login-wrapper">
            <span style="font-size:14px;cursor: pointer" v-if="!isLogin" @click="onLogin">登录/注册</span>
            <el-popover
                trigger="hover"
                width="150px"
                v-model="userMenu"
                v-else
            >
              <el-container slot="reference" style="align-items: center">
                <!--                <el-avatar-->
                <!--                    src="https://solove.love/media/uploads/article/images/2019/05/solove/lo_1557896732_1.gif"></el-avatar>-->
                <Avatar :size="40" :lighten="10000" :username="avatarName" :src="avatar"></Avatar>
                <p style="margin-left: 8px;font-size:14px">{{ username }}</p>
              </el-container>
              <el-container class="user-menu">
                <p @click="onUserMenu('admin')" v-if="level === 'Admin'"><i class="sl icon-manages"></i> 站点管理</p>
                <p @click="onUserMenu('article')"><i class="sl icon-article"></i> 我的文章</p>
                <p @click="onUserMenu('new')"><i class="sl icon-edit"></i> 发表文章</p>
                <p @click="onUserMenu('user')"><i class="sl icon-user"></i> 用户中心</p>
                <p @click="onUserMenu('msg')"><i class="sl icon-msg"></i> 站内消息</p>
                <p @click="onUserMenu('exit')"><i class="sl icon-exit"></i> 退出登录</p>
              </el-container>
            </el-popover>

          </el-container>
        </el-container>

      </el-menu>
    </el-col>
    <el-col class="hidden-md-and-up" :md="{span: 16, offset: 4}" :xs="{span: 24, offset: 0}">
      <div class="mobile-header">
        <el-image :src="require('@/assets/logo.png')" class="logo" fit="contain"></el-image>
        <div class="menu" @click="onDrawer">
          <i class="sl icon-menu" style="font-size:26px; color: rgb(119 119 119)"></i>
        </div>
        <div class="search" @click="onSearch">
          <el-icon name="search" style="font-size:24px; color: rgb(119 119 119)"></el-icon>
        </div>
      </div>
      <el-drawer :visible.sync="drawer" direction="ltr" size="65%">
        <div slot="title">
          <span style="font-size:14px" v-if="!isLogin" @click="onLogin">登录/注册</span>
          <el-container style="align-items: center" v-else>
            <el-avatar
                src="https://solove.love/media/uploads/article/images/2019/05/solove/lo_1557896732_1.gif"></el-avatar>
            <p style="margin-left: 8px;font-size:14px">嘴角边的诱惑</p>
          </el-container>
        </div>
        <div style="border-bottom: 1px #ccc solid"></div>
        <el-menu :default-active="active" mode="vertical" text-color="#474747" style="border:none !important"
                 active-text-color="var(--main-green)" router>
          <template v-for="(item,index) in menus">
            <el-menu-item :index="getPath(item)" v-if="!item.children.length" @click="drawer=false">
              {{ item.name }}
            </el-menu-item>
            <el-submenu :index="getPath(item)" v-else @click="drawer = false">
              <template slot="title">{{ item.name }}</template>
              <template v-for="(items,indexed) in item.children">
                <el-menu-item :index="getPath(items)" v-if="!items.children.length" @click="drawer=false">{{
                    items.name
                  }}
                </el-menu-item>
                <el-submenu :index="getPath(items)" v-else @click="drawer=false">
                  <template slot="title">{{ items.name }}</template>
                  <template v-for="(itemss,indexeds) in items.children">
                    <el-menu-item :index="getPath(itemss)" @click="drawer=false">{{ itemss.name }}</el-menu-item>
                  </template>
                </el-submenu>
              </template>
            </el-submenu>
          </template>
        </el-menu>
      </el-drawer>
    </el-col>
    <el-col>
      <LoginBox ref="login"/>
    </el-col>
  </el-row>

</template>

<script>
import LoginBox from "@/components/LoginBox";
import {mapMutations, mapState} from "vuex";
import Avatar from "vue-avatar"
import userApi from "@/common/api/userApi";
import manageApi from "@/common/api/manageApi";

export default {
  name: 'Headers',
  components: {LoginBox, Avatar},
  data() {
    return {
      drawer: false,
      currentPage: "",
      userMenu: false,
      menus: [],
      active: null
    }
  },
  created() {
    let keys = this.$route.path.split("/user/")
    if (keys.length === 2) {
      this.currentPage = keys[1]
    }
    manageApi.getMenus(res => this.menus = res);
  },
  methods: {
    ...mapMutations({
      "setKw": "search/setKw",
    }),
    /**
     * 搜索框
     */
    onSearch() {
      swal({
        className: "transparent",
        content: {
          element: "input",
          attributes: {
            placeholder: "输入要搜索的内容回车并继续~",
            type: "text"
          },
        },
        buttons: false
      }).then(res => {
        // res 输入框返回值
        // console.log(res)
        if (res.length === 0) {
          return this.$message.info("内容不能为空")
        }
        if (this.$route.name !== "Search")
          this.$router.push("/s/" + res)
        else
          this.setKw(res);
      })
    },
    onDrawer() {
      this.drawer = !this.drawer;
    },
    onUserMenu(name) {
      switch (name) {
        case 'admin':
          if (this.currentPage !== "admin")
            this.$router.push('/user/menu')
          this.currentPage = name;
          break;
        case 'new':
          if (this.currentPage !== "new")
            this.$router.push('/new')
          this.currentPage = name;
          break;
        case 'user':
          if (this.currentPage !== "base")
            this.$router.push("/user/base")
          this.currentPage = name;
          break;
        case 'msg':
          if (this.currentPage !== "msg")
            this.$router.push("/user/msg")
          this.currentPage = name;
          break;
        case 'article':
          if (this.currentPage !== "article")
            this.$router.push("/user/article")
          this.currentPage = name;
          break;
        case 'exit':
          // 退出登录
          userApi.logout()
          break;
        default:
          break;
      }
      this.userMenu = false;
    },
    onLogin() {
      this.$refs.login.show();
    },

    getPath(items) {
      if (items.type === 'Link') {
        return items.link;
      }
      if (items.type === 'Category') {
        return '/article/' + items.categoryId.toString();
      }
      return items.name;
    }
  },
  computed: {
    ...mapState({
      isLogin: state => state.user.isLogin,
      user: state => state.user.userInfo
    }),
    username() {
      if (this.user) {
        return this.user.nickname || this.user.mobile || this.user.email || this.user.username;
      }
      return "default"
    },
    avatar() {
      if (this.user) {
        let reg = /^http(s)?:\/\/.*/gi;
        if (reg.exec(this.user.avatar)) {
          return this.user.avatar;
        }
        return this.$baseUrl + this.user.avatar;
      }
    },
    avatarName() {
      if (this.user) {
        return this.user.username || "default";
      }
      return "default"
    },
    level() {
      if (this.user) {
        return this.user.level;
      }
      return ""
    }
  },
}
</script>

<style lang="less">
.login-wrapper {
  margin-left: 80px;
  align-items: center;
}

.mobile-header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .logo {
    float: none;
    padding: 0;
    border-bottom: none;
  }

  .menu, .search {
    position: absolute;
    top: 16px;
  }

  .menu {
    right: 15px;
  }

  .search {
    left: 15px;
  }
}

.user-menu {
  align-items: center;
  flex-direction: column !important;

  p {
    line-height: 1;
    margin: 10px 0;
    cursor: pointer;

    i {
      font-size: 14px;
      color: var(--main-black);
      font-weight: 600;
    }
  }
}

.transparent {
  input {
    background-color: transparent;
    color: white;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom-color: var(--main-green);
    text-align: center;

    &:focus {
      border-bottom-color: var(--main-green);
    }
  }

  ::-moz-placeholder {
    color: white;
  }

  ::-webkit-input-placeholder {
    color: white;
  }

  :-ms-input-placeholder {
    color: white;
  }
}

.menu-nav {
  box-shadow: 1px 0 20px #55ca565c;
}

.header-right {
  float: right;
  height: 60px;
  align-items: center;
}

.logo {
  float: left;
  height: 58px !important;
  width: 60px !important;
  line-height: 60px;
  margin: 0;
  padding: 0 40px 0 0;
  outline: none;
  opacity: 0.8;
  border-bottom: 2px solid transparent;

  &:before {
    content: '';
    position: absolute;
    left: -110px;
    top: -100px;
    width: 5px;
    height: 100px;
    background-color: #ffffff5c;
    transform: rotate(45deg);
    animation: streamer 1.5s ease-in 1.5s infinite;

    @keyframes streamer {
      from {
        left: -10px;
        top: -50px;
      }
      to {
        left: 100px;
        top: 60px;
      }
    }
  }
}
</style>

<style lang="less">
.swal-overlay {
  background-color: #2b2b2beb;
}
</style>
