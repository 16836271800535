<template>
  <el-row style="margin-bottom:60px">
    <el-col>
      <div class="sl-home-title">
        <h4>最新发布</h4>
      </div>
    </el-col>
    <el-col class="sl-hone-new-article shadow" v-for="(item, index) in result" :key="index" v-if="result.length" @click.native="$router.push(item.staticUrl)">
      <el-image class="img" :src="theUrl(item.image) + '?s=370x220'" fit="cover"/>
      <div class="article-info">
        <SLText line="2" class="title">{{ item.title }}</SLText>
        <div class="user-info">
          <span>{{ item.author.name }}</span>
          <span><i class="el-icon-time"></i> {{ item.date }}</span>
          <span>浏览 {{ item.view }}</span>
        </div>
        <div class="desc">
          <SLText line="2">{{ item.description }}</SLText>
        </div>
      </div>
    </el-col>
    <el-col>
      <div class="sl-home-tag-btn" @click="$router.push({name: 'Latest'})">查看更多</div>
    </el-col>
  </el-row>
</template>

<script>
import SLText from "@/components/SLText";
import homeApi from "@/common/api/homeApi";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "NewReleaseArea",
  data() {
    return {
      result: []
    }
  },
  created() {
    homeApi.getNewArticle(res => this.result = res);
  },
  methods: {
    theUrl(url) {
      return getUrl(url)
    }
  },
  components: {SLText}
}
</script>

<style scoped lang="less">
.shadow {
  box-shadow: 0 0 20px #cccccc40;
}

.sl-hone-new-article {
  cursor: pointer;
  text-align: left;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;

  .img {
    flex: 2.1;
    height: 220px;
    border-radius: 8px;
  }

  .article-info {
    flex: 5;
    padding: 10px;

    .title {
      font-size: 16px !important;
      font-weight: bold;
      height: 46px;
    }

    .user-info {
      span {
        margin-right: 15px;
        color: #777;
        font-size: 14px;
      }

      span:nth-of-type(2) {
        i {
          color: var(--main-green);
        }
      }
    }

    .desc {
      p {
        color: #777 !important;
        margin-top: 20px;
      }
    }
  }
}

.sl-home-tag-btn {
  margin: 10px 0;
  padding: 15px 10px;
  font-weight: 300;
  border: 1px #e0e0e05c solid;
  color: #484848;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s ease;

  &:hover {
    background: var(--main-green);
    color: white;
    font-weight: 400;
    transition: .3s ease;
  }
}

@media screen and(max-width: 768px) {
  .sl-hone-new-article {
    padding: 0 5px;

    .img {
      flex: 2;
      height: 120px;
    }

    .article-info {
      flex: 3;
      padding: 5px;

      .title {
        font-size: 14px !important;
        margin: 2px 0;
        height: auto;
      }

      .user-info {
        margin-top: 3px;

        span {
          font-size: 12px;
          margin-right: 10px;
        }
      }

      .desc {
        p {
          font-size: 12px !important;
          margin: 10px 0 0 0;
        }
      }
    }
  }
}
</style>
