<template>
  <div class="article-authors shadow" v-if="data">
    <el-image class="article-author-bg"
              :src="data.bgImage ? $baseUrl + data.bgImage : require('@/assets/user-default-bg.jpeg')"
              fit="cover"/>
    <div class="author-avatar" @click="$router.push('/u/' + data.id + '/data')">
      <Avatar class="avatar-border" :src="avatar" :style="{'background-color': avatar ? '#fff' : '', 'background-image': avatar? 'url(' + avatar + ') 0% 0% / 60px 60px no-repeat scroll content-box border-box transparent' : ''}"
              :size="60" :lighten="10000" :username="data.username"/>
    </div>
    <div class="username">
      <span>{{ data.name }}</span>
      <span :style="{'background': data.level === 'Admin' ? 'var(--main-green)' : 'var(--main-blue)'}">{{
          data.level === 'Admin' ? '站长' : '作者'
        }}</span>
    </div>
    <div class="author-article">
      <div class="item" v-for="(item, index) in dataList" :key="index" @click="toPage(item)">
        <span>{{ item.num }}</span>
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "AuthorAside",
  data() {
    return {
      dataList: [
        {name: "文章", num: 46, key: "articleCount", routeKey: "article"},
        {name: "点赞", num: 46, key: "praiseCount", routeKey: ""},
        {name: "关注", num: 46, key: "focusOnCount", routeKey: "focus"},
        {name: "粉丝", num: 46, key: "fansCount", routeKey: "fans"},
      ]
    }
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  mounted() {
    for (let i = 0; i < this.dataList.length; i++) {
      this.$set(this.dataList[i], 'num', this.data[this.dataList[i].key]);
      this.$set(this.dataList[i], 'router', '/u/' + this.data.id + "/" + this.dataList[i].routeKey);
    }
  },
  methods: {
    toPage(item) {
      if (item.name !== '点赞') {
        this.$router.push(item.router);
      }
    }
  },
  components: {Avatar},
  computed: {
    avatar() {
      return getUrl(this.data.avatar);
    }
  }
}
</script>

<style scoped lang="less">
.article-authors {
  position: relative;
  margin-bottom: 20px;
  margin-left: 20px;
  border-radius: 4px;
  max-width: 314.52px;

  .author-article {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-bottom: 20px;

    .item {
      display: flex;
      flex-direction: column;
      cursor: pointer;

      span:nth-child(1) {
        font-size: 16px;
        font-weight: 500;
      }

      span:nth-child(2) {
        font-size: 14px;
      }
    }
  }

  .article-author-bg {
    height: 160px;
  }

  .author-avatar {
    position: absolute;
    left: 0;
    top: 0;
    height: 180px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;

    .avatar-border {
      border: 4px #fff solid;
    }
  }

  .username {
    margin: 30px 0 20px 0;

    span {
      margin-right: 5px;
      vertical-align: middle;
    }

    span:nth-child(1) {

    }

    span:nth-child(2) {
      display: inline-block;
      background-color: var(--main-green);
      color: #fff;
      font-size: 12px;
      padding: 1px 2px;
      border-radius: 2px;
      transform: scale(0.9);
    }

  }
}
</style>
