<template>
  <el-row>
    <el-col :md="24" v-if="listData.length">
      <div class="download-info">
        <span>资源</span>
        <div style="background-color: #fff5e657;padding: 20px;margin: 10px">
          <ResourceFileItem :downloads="listData"></ResourceFileItem>
        </div>
        <div style="margin: 10px 0">
          <p>本站所刊载内容均为网络上收集整理，包括但不限于代码、应用程序、影音资源、电子书籍资料等，并且以研究交流为目的，所有仅供大家参考、学习，不存在任何商业目的与商业用途。</p>
          <p>若您使用开源的软件代码，请遵守相应的开源许可规范和精神，若您需要使用非免费的软件或服务，您应当购买正版授权并合法使用。</p>
          <p>如果你下载此文件，表示您同意只将此文件用于参考、学习使用而非任何其他用途。</p>
        </div>
      </div>
<!--      <div class="mask" v-if="!isLogin">-->
<!--        <SLButton @click="loginBox.show()">点击登录</SLButton>-->
<!--      </div>-->
    </el-col>
  </el-row>
</template>

<script>
import ResourceFileItem from "@/views/article/components/ResourceFileItem";
import SLButton from "@/components/SLButton";
import {mapState} from "vuex";

export default {
  name: "Download",
  components: {SLButton, ResourceFileItem},
  props: {
    listData: {
      type: Array,
      default: []
    }
  },
  computed: {
    ...mapState({
      loginBox: state => state.user.loginBox,
      isLogin: state => state.user.isLogin,
    })
  }
}
</script>

<style scoped lang="less">
.download-info {
  position: relative;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #eee;
  //border-left-width: 5px;
  //border-left-color: #fff5e6;
  font-size: 12px;
  border-radius: 4px;

  span:nth-child(1) {
    display: block;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }
}
.mask{
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #ffffff87;
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
