import axios from "axios"
import {baseUrl} from "./env"
import Message from "element-ui/packages/message/src/main";
import store from "@/store"
import router from "@/router"

const req = axios.create({
    baseURL: baseUrl,
    headers: {
        "content-type": "application/json",
    },
    timeout: 10000
});

req.interceptors.request.use(function (config) {
    if (store.state.user.isLogin) {
        config.headers["Authorization"] = `SL ${store.state.user.token}`;
    }
    return config;
});

req.interceptors.response.use(function (response) {
    if (response.data.code === 90000) {
        Message({message: response.data.message, type: "error", customClass: "max-z-index", duration: 10000})
    }
    return response;
}, (e) => {
    if (e.request.status === 401) {
        // 未登录
        store.commit("user/setToken", null)
        Message({message: "请登录~", type: "warning", customClass: "max-z-index"})
        router.push("/")
        setTimeout(() => store.state.user.loginBox.show(), 700)
        return e;
    } else if (e.request.status === 403) {
        Message({message: "阿欧，无权访问~", type: "warning", customClass: "max-z-index"})
        router.push("/")
        return e
    } else if (e.request.status === 404) {
        router.push("/404");
        return e;
    }
    Message.error("网络请求异常，请稍后再试！");
    return e;
});

export const request = ({url, method, data, headers, success, fail}) => {
    Object.assign(req.defaults.headers, headers);
    req({
        url,
        method,
        data,
        headers,
    }).then((res) => {
        success(res.data)
    }).catch(e => {
        if (fail) {
            fail(e);
        }
    });
}

export const hasRes = (res) => {
    return res.code !== undefined && res.code === 10000;
}

export const hasResCode = (res, code) => {
    return res.code !== undefined && res.code === code;
}

export const handlerRes = (res, callback) => {
    if (hasRes(res)) {
        if (res.result) {
            return callback(res.result);
        }
        return callback();
    }
    Message({type: "warning", message: res.message})
    return callback();
}

export default {
    request,
    hasRes
}
