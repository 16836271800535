<template>
  <div class="loading" :style="style">
    <div class="box" :style="style" v-if="!status">
      <slot name="content">
        <div class="content" :class="className">
          <i class="sl icon-loading loading-spinner"></i>
        </div>
      </slot>
    </div>
    <div v-if="status">
      <slot :data="data" v-if="status && notData"></slot>
      <div v-else class="not-data" :style="style">
        <svg-icon name="not-data" w="200" h="200"></svg-icon>
        <slot name="tips">
          <span>这里什么也米有~</span>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  data() {
    return {
      style: {},
      status: true,
      className: "",
    }
  },
  mounted() {
    let sty = {}
    Object.assign(sty, this.offset, {"min-height": this.height});
    this.style = sty;
    this.status = !this.loading
  },
  props: {
    data: {
      type: [Object, Array],
      default: null
    },
    offset: {
      type: Object,
      default: () => {
      }
    },
    height: {
      type: [Number, String],
      default: "auto"
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    notData() {
      if(this.data instanceof Array){
        return this.data.length !== 0;
      } else if (this.data instanceof Object){
        return Object.keys(this.data).length !== 0;
      }
      return this.data !== null && this.data !== undefined;
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.className = "hide";
        setTimeout(() => this.status = true, 1000);
      }
    },
    loading() {
      if(!this.loading){
        this.className = "hide";
        setTimeout(() => this.status = true, 1000);
      } else {
        this.status = !this.loading;
      }
    }
  }
}
</script>

<style scoped lang="less">
.loading {
  position: relative;

  .not-data{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .box {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 999;

    .content {

      .loading-spinner {
        display: inline-block;
        font-size: 18px;
        color: var(--main-green);
        animation: spinner .5s linear infinite;

        @keyframes spinner {
          0% {
            transform: rotate(0deg)
          }
          100% {
            transform: rotate(360deg)
          }
        }
      }
    }
  }
}

.hide {
  animation: hide 1s linear normal;

  @keyframes hide {
    0% {
      transform: scale(1);
      opacity: 1
    }
    100% {
      transform: scale(0);
      opacity: 0
    }
  }
}
</style>
