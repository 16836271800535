<template>
  <div>
    <div class="hidden-context" :class="an ? 'unlock': ''" v-if="hide">
      <div class="content">
        <svg-icon name="lock" w="50" h="50" :class="an ? 'whirling': ''"/>
        <!-- 公众号 -->
        <div class="public" v-if="hiddenType.type === 'PublicNo'">
          <span>微信扫一扫</span>
          <div class="content-qr">
            <el-image class="qrcode" :src="require('@/assets/public-qrcode.jpg')"
                      fit="cover"/>
            <div class="content-info shadow">
              <span>关注公众号</span>
              <span>回复：<i style="font-weight: 900">{{ hiddenType.code }}</i></span>
            </div>
          </div>
        </div>

        <!-- 登录 -->
        <div class="login" v-if="hiddenType.type === 'Login'">
          <span>请先登录</span>
          <div class="content-qr">
            <el-image class="qrcode" src="http://up.deskcity.org/pic/de/fe/17/defe174a1e0fe55fecea00c034bf83fe.jpg"
                      fit="cover"/>
            <div class="content-info shadow">
              <span>手机App扫一扫</span>
              <span>或点击此处<i style="font-style: normal; cursor: pointer;" @click="loginBox.show()"> 登录</i>.</span>
            </div>
          </div>
        </div>

        <!-- 评论 -->
        <div class="comment" v-if="hiddenType.type === 'Comment'">
          <span>原创不易，说点什么吧~</span>
          <input type="text" class="input" v-model="content" placeholder="说点什么" style="text-align: center"/>
          <SLButton :loading="loading" @click="submitCommentData">回复</SLButton>
        </div>

        <!-- 付费查看 -->
        <div class="paid" v-if="hiddenType.type === 'Pay'">
          <span>付费内容，扫一扫支付</span>
          <p>￥10.00</p>
          <el-image class="qrcode" src="http://up.deskcity.org/pic/de/fe/17/defe174a1e0fe55fecea00c034bf83fe.jpg"
                    fit="cover"/>
        </div>

        <!-- 积分查看 -->
        <div class="credit" v-if="hiddenType.type === 'Credit'">
          <span>积分内容，需积分 {{ permission.content.money }}</span>
          <em style="font-size:12px">当前拥有积分：{{ userInfo.credit }}, 您可以在<a><b style="color:var(--main-green)">此处</b></a>查看积分获得途径。</em>
          <SLButton :loading="creditBtnLoading" style="margin-top: 20px" @click="onCredit">解锁内容</SLButton>
        </div>

        <!-- 某个会员等级可见 -->
        <div class="vip" v-if="hiddenType.type ==='Vip'">
          <span>仅限 <span>高级会员</span> 查看</span>
          <SLButton>成为高级会员</SLButton>
        </div>
      </div>
    </div>
    <!-- 下载地址  或富文本内容 -->
    <div v-else v-html="permission.content.content" v-hljs></div>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import {mapState} from "vuex";
import commentApi from "@/common/api/commentApi";
import orderApi from "@/common/api/orderApi";

export default {
  name: "HiddenArea",
  components: {SLButton},
  data() {
    return {
      an: false,
      hide: true,
      hiddenType: [
        {
          type: "",
          status: false
        }
      ],
      content: null,
      loading: false,
      creditBtnLoading: false
    }
  },
  props: {
    source: {
      type: String,
      default: ""
    },
    articleId: {
      type: Number,
      default: null
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getHiddenType() {
      for (let i = 0; i < this.status.length; i++) {
        // 返回无权 的 权限
        if (!this.status[i].status) return this.status[i];
      }
    },
    submitCommentData() {
      this.loading = true;
      let data = {
        userId: this.userInfo.id,
        articleId: this.articleId,
        content: this.content,
      }
      commentApi.submitComment(data, () => {
        this.loading = false;
        let replyData = {
          content: this.content,
          date: "刚刚",
          praise: 0,
          user: {
            username: this.userInfo.username,
            nickname: this.userInfo.nickname,
            avatar: this.userInfo.avatar
          },
          reply: []
        }
        this.$emit("reply", replyData);
        this.content = "";
      });
    },
    onCredit() {
      this.creditBtnLoading = true;
      let data = {
        payType: "Credit",
        type: "Article",
        articleId: this.articleId,
      }
      orderApi.submitOrder(data, () => {
        this.creditBtnLoading = false;
      })
    }
  },
  mounted() {
    this.hiddenType = this.getHiddenType();
    if (this.hiddenType === undefined) {
      this.an = true;
      this.hide = false;
    }
  },
  watch: {
    hidden() {
      this.an = true;
      setTimeout(() => this.hide = false, 2000);
    },
    isLogin() {
      if (this.isLogin) {
        for (let i = 0; i < this.status.length; i++) {
          if (this.status[i].type === 'Login') {
            this.status[i].status = true;
            break;
          }
        }
      } else {
        for (let j = 0; j < this.status.length; j++) {
          if (this.status[j].type === 'Login') {
            this.status[j].status = false;
            break;
          }
        }
        this.an = false;
        this.hide = true;
      }
      let type = this.getHiddenType();
      if (type !== undefined) {
        let t = type.type;
        let s = type.status;
        console.log(t, s)
        this.hiddenType = type;
        // this.$set(this.hiddenType, 'type', t);
        // this.$set(this.hiddenType, 'status', s);
      } else {
        this.an = true;
        setTimeout(() => this.hide = false, 2000);
      }
    },
    "$store.state.article.pushData"() {
      if (this.$store.state.article.pushData) {
        for (let i = 0; i < this.status.length; i++) {
          if (this.$store.state.article.pushData.type === this.status[i].type) {
            this.status[i].status = true;
            break;
          }
        }
        let hiddenType = this.getHiddenType();
        if (hiddenType === undefined) {
          this.an = true;
          setTimeout(() => this.hide = false, 2000);
        } else {
          if (this.hiddenType.type !== hiddenType.type) {
            this.hiddenType = hiddenType;
          }
        }
        this.$store.commit("article/setPushData", null);
      }
    }
  },
  computed: {
    ...mapState({
      permission: state => state.article.permission,
      status: state => state.article.status,
      isLogin: state => state.user.isLogin,
      loginBox: state => state.user.loginBox,
      userInfo: state => state.user.userInfo
    }),
  }
}
</script>

<style lang="less" scoped>
/**
 * 隐藏区域
 */
.hidden-context {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0;
  align-items: center;
  height: 260px;
  border-bottom: 1px dashed #55ca5691;

  .content {
    position: absolute;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: -60px;
    left: 0;
    width: 100%;
    background: linear-gradient(to bottom, #ffffffde 0%, #fff 12%);
    z-index: 100;

    .public, .login, .comment, .paid, .credit, .vip {
      display: flex;
      flex-direction: column;
      align-items: center;

      .content-qr {
        display: flex;
        flex-direction: row;
        align-items: center;

        .qrcode {
          width: 150px;
          height: 150px;
        }

        .content-info {
          position: relative;
          display: flex;
          flex-direction: column;
          margin-left: 15px;
          font-size: 12px;
          padding: 20px 30px;
          border-radius: 10px;

          &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border: 8px solid transparent;
            border-right: 8px #fff solid;
            left: -14px;
            top: 32px;
            z-index: 9999;
          }

          span {
            margin: 0;

            i {
              color: var(--main-green);
              font-weight: 600;
            }
          }
        }
      }

      span {
        margin: 30px 0;
      }
    }

    .login {

    }

    .comment {

      .input {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px #cccccc6b solid;
        margin: 10px 0 30px 0;
        width: 230px;
        outline: none;
        color: var(--main-black);
        padding: 15px;

        &:hover, &:focus {
          border-color: var(--main-green) !important;
          transition: .2s ease-in;
        }
      }
    }

    .paid {
      span {
        margin-bottom: 20px;
      }

      p {
        margin-top: 0;
        font-weight: bold;
        color: var(--main-red);
      }

      .qrcode {
        width: 150px !important;
        height: 150px !important;
      }
    }

    .vip {
      margin-top: 30px;

      span {
        span {
          font-weight: 600;
        }
      }
    }
  }
}

.unlock {
  transform: translateY(233px);
  opacity: 0;
  transition: 1s ease-in 1s;
}

.whirling {
  transform: rotate(360deg) scale(0);
  transition: 1s ease;
}
</style>
