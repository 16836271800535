<template>
  <div class="register">
    <video muted autoplay loop :src="require('@/static/video/register-1.mp4')"/>
    <div class="mark">
      <span class="register-logo" @click="$router.push('/')"
            :style="{'background-image': 'url(' + require('@/assets/logo.png')+ ')'}"></span>
      <div class="register-box">
        <h2>注册账户</h2>
        <el-form :model="form" style="margin-top: 60px" :rules="rules" ref="form">
          <el-form-item prop="email">
            <el-input v-model="form.email" prefix-icon="sl icon-email" placeholder="输入你的邮箱"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" show-password prefix-icon="sl icon-lock"
                      placeholder="输入密码（6 - 20）"></el-input>
          </el-form-item>
          <el-form-item prop="newPw">
            <el-input v-model="form.newPw" show-password prefix-icon="sl icon-lock" placeholder="再次输入密码"
                      @keyup.enter.native="submitForm"></el-input>
          </el-form-item>
          <el-form-item style="display: flex; justify-content: center;margin-top: 45px">
            <SLButton class="btn" :loading="loading" @click="submitForm">注 册</SLButton>
          </el-form-item>
        </el-form>
      </div>
      <span @click="onLogin">已有账户？点击登录</span>
    </div>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import {mapState} from "vuex";
import commonApi from "@/common/api/commonApi";


export default {
  name: "PromoteRegisterPage",
  components: {SLButton},
  data() {
    return {
      form: {
        email: "",
        password: "",
        newPw: ""
      },
      loading: false,
      rules: {
        email: [
          {required: true, message: "请输入邮箱"},
          {
            validator: (rule, value, callback) => {
              let reg = /.*@.*\.\w+/g
              if (!reg.test(value.trim())) return callback(new Error("邮箱格式不正确"))
              callback();
            }
          }
        ],
        password: [
          {required: true, message: "请输入密码"},
          {min: 6, max: 20, message: "密码需在6-20位之间"},
          {
            validator: (r, v, c) => {
              let regex = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
              if (regex.exec(v.trim()) != null) return c(new Error("密码不能包含中文"));
              let pwReg = /[0-9]+/gi;
              let raw = pwReg.exec(v.trim())
              if (raw.length > 0 && raw[0].length === v.trim().length) return c(new Error("密码不能为纯数字"))
              c();
            }
          }
        ],
        newPw: {
          validator: (r, v, c) => {
            if (this.form.password.trim() !== v) return c(new Error("两次输入的密码不一致"))
            c();
          }
        },
      }
    }
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 效验通过
          let data = {
            encryptionId: this.$route.params.uid,
            email: this.form.email,
            password: this.form.password
          };
          this.loading = true;
          commonApi.promoteRegister(data, res => {
            this.loading = false;
            if (res) {
              this.$message.success("系统已经下发一封包含激活链接的邮件到您的邮箱，请注意查收~");
              for (const key of Object.keys(this.form)) {
                this.form[key] = "";
              }
            }
          });
        } else {
          return false;
        }
      });
    },
    onLogin() {
      this.$router.push("/");
      setTimeout(() => this.loginBox.show(), 1000)
    }
  },
  computed: {
    ...mapState({
      loginBox: state => state.user.loginBox,
    })
  }
}
</script>

<style scoped lang="less">
/deep/ .sl {
  display: block;
  margin: 0 auto;
  font-size: 14px !important;
}

.register {
  position: relative;
  width: 100vw;
  height: 100vh;

  video {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .mark {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    backdrop-filter: blur(15px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .register-logo {
      width: 80px;
      height: 80px;
      background: center center no-repeat;
      background-size: contain;
      object-fit: contain;
      opacity: 0.9;
      margin-bottom: 50px;
      cursor: pointer;
    }

    .register-box {
      padding: 20px 40px;
      width: 350px;
      min-height: 420px;
      background: #fff;
      border-radius: 10px;
      box-shadow: 0 0 5px #5d5d5d;
    }

    span:nth-child(3) {
      display: block;
      margin-top: 20px;
      color: #ffffffbf;
      font-size: 13px;

      &:hover {
        color: #ffffff;
        cursor: pointer;
      }
    }

    .btn {
      background: #6847cae8;
      color: #fff;
      width: 240px;

      &:active {
        opacity: 0.5;
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }
}
</style>