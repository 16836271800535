const state = {
    keyword: ""
}

const mutations = {
    setKw(state, kw) {
        state.keyword = kw
    }
}

export default {
    namespaced: true,
    state,
    mutations
}