<template>
  <div class="webmaster-feed shadow">
    <span>订阅本站</span>
    <div class="webmaster-feed-wrapper">
      <div class="webmaster-feed-sub">
        <i class="sl icon-EMAILMARKETING webmaster-feed-email"></i>
        <span>订阅本站的最新动态</span>
        <div class="webmaster-feed-input">
          <input placeholder="输入你的Email..." v-model="email" @keyup.enter="onFeed"/>
          <SLButton class="webmaster-feed-btn" width="68.16" @click="onFeed" :loading="loading"><i class="el-icon-s-promotion"> 订阅</i></SLButton>
        </div>
        <p>当本站有最新资源发布、更新时，会推送信息到您的邮箱~</p>
      </div>
    </div>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import homeApi from "@/common/api/homeApi";

export default {
  name: "FeedAside",
  components: {SLButton},
  data() {
    return {
      email: "",
      loading: false
    }
  },
  methods: {
    onFeed() {
      let reg = /.*@.*\.\w+/
      if (!reg.test(this.email)) {
        this.$message.warning("邮箱格式不正确");
        setTimeout(() => this.email = "", 1000)
        return;
      }
      this.loading = true
      homeApi.feedWebmaster(this.email, res => {
        this.email = "";
        this.loading = false;
      })
    }
  }
}
</script>

<style scoped lang="less">
.webmaster-feed {
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  width: 360px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 60px;
    font-size: 20px;
    font-weight: bold;
    background-color: #1bc17217;
  }

  .webmaster-feed-wrapper {
    position: relative;
    height: 300px;
    padding: 20px;
    background-image: url("../../assets/feed-bg.jpg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(10px);
      border-radius: 8px;
      z-index: 8;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00000003;
      border-radius: 8px;
      z-index: 9;
    }

    .webmaster-feed-sub {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      color: #fff;
      z-index: 10;

      span {
        font-weight: 400;
        background-color: transparent;
      }

      p {
        width: 80%;
        font-size: 13px;
      }

      .webmaster-feed-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 10px 0;

        input {
          outline: none;
          padding: 10px;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          color: var(--main-green);
          border: 1px solid #ccc;
        }

        .webmaster-feed-btn {
          background-color: var(--main-green);
          padding: 8.5px 10px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 100;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          color: #ffF;

          &:active {
            opacity: 0.8;
          }
        }
      }

      .webmaster-feed-email {
        font-size: 50px;
        font-weight: 900;
      }
    }

  }

}
</style>
