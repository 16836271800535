<template>
  <el-row>
    <el-col class="footer">
      <el-row class="footer-container">
        <el-col class="footer-information">
          <router-link to="/info/links">友情链接</router-link>
          <router-link to="/info/clauseTreaty">条款条约</router-link>
          <router-link to="/info/clauseTreaty">隐私政策</router-link>
          <a href="/sitemap.xml">网站地图</a>
          <router-link to="/info/clauseTreaty">免责声明</router-link>
        </el-col>
        <el-col class="footer-web-info">
          <span>©2015-{{ year }}</span>
          <span>Skill's Private Plot.</span>
          <span><a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备18025241号-1.</a></span>
          <span style="color:red">♥</span>
          <span>Designed by <router-link to="/">skill's.</router-link> </span>
          <span><i class="sl icon-feed footer-feed"></i></span>
        </el-col>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "SLFooter",
  data() {
    return {
      year: null
    }
  },
  mounted() {
    this.year = new Date().getFullYear()
  },
}
</script>

<style scoped lang="less">
.footer {
  background-color: #000;
  color: white;
  font-size: 14px;
  padding: 50px 20px;

  .footer-container {
    .footer-information {
      margin-bottom: 20px;

      a {
        color: white;
        margin: 0 10px;
      }
    }

    .footer-web-info {
      font-size: 12px;

      span {
        margin: 0 5px;

        a {
          color: white;
        }

        .footer-feed {
          position: relative;
          cursor: pointer;

          &:hover {
            &:after {
              transform: scale(1);
              transition: .3s ease;
            }
          }

          &:after {
            content: "\e6ac";
            position: absolute;
            left: 0;
            top: 0;
            color: orange;
            transform: scale(0);
            transition: .3s ease;
          }
        }
      }
    }
  }

}

@media screen and (max-width: 768px) {
  .footer {
    .footer-container {
      .footer-information {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        a {
          margin: 0;
        }
      }
    }

  }
}

@media screen and (min-width: 992px) {

}
</style>

