<template>
  <div class="base">
    <h2>私信</h2>
    <div class="base-wrapper">
      <el-tabs type="card" v-model="card" style="width: 100%">
        <el-tab-pane label="收件箱" name="1">
          <Loading :loading="loading" :data="result.list" height="558px">
            <div class="msg" v-for="(item, index) in result.list" :key="index">
              <div class="info">
                <span>时间：</span>
                <span>{{ item.date }}</span>
                <span>发信人：</span>
                <span><router-link :to="'/u/'+ item.fromUser.id +'/data'">{{
                    item.fromUser.nickname || item.fromUser.username
                  }}</router-link></span>
                <span>状态：</span>
                <span>已读</span>
                <span @click="id=item.fromUser.id;privateMessageBox = true">回复</span>
              </div>
              <div class="msg-info">
                <div>
                  <Avatar class="avatar" :size="40" :lighten="10000" :username="item.fromUser.username"
                          :src="getUrls(item.fromUser.avatar)"></Avatar>
                </div>
                <div class="content">
                  {{ item.message }}
                </div>
              </div>
            </div>
          </Loading>
          <el-pagination :total="result.total" :current-page="page" :page-size="size" hide-on-single-page
                         layout="prev, pager, next" @current-change="onPage"></el-pagination>
        </el-tab-pane>


        <el-tab-pane label="发件箱" name="2">
          <Loading :loading="loadingF" :data="resultF.list" height="558px">
            <div class="msg" v-for="(item, index) in resultF.list" :key="index">
              <div class="info">
                <span>时间：</span>
                <span>{{ item.date }}</span>
                <span>收件人：</span>
                <span><router-link :to="'/u/'+ item.toUser.id +'/data'">{{
                    item.toUser.nickname || item.toUser.username
                  }}</router-link></span>
<!--                <span>状态：</span>-->
<!--                <span>已读</span>-->
<!--                <span>回复</span>-->
              </div>
              <div class="msg-info">
                <div>
                  <Avatar class="avatar" :size="40" :lighten="10000" :username="item.fromUser.username"
                          :src="getUrls(item.fromUser.avatar)"></Avatar>
                </div>
                <div class="content">
                  {{ item.message }}
                </div>
              </div>
            </div>
          </Loading>
          <el-pagination :total="resultF.total" :current-page="pageF" :page-size="sizeF" hide-on-single-page
                         layout="prev, pager, next" @current-change="onPageF"></el-pagination>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
        :visible.sync="privateMessageBox"
        width="50%"
        center>
      <template slot="title">私信</template>
      <textarea maxlength="255" v-model="msg" class="content-area" placeholder="说点什么呢？"></textarea>
      <template slot="footer">
        <div class="btn-group">
          <SLButton @click="privateMessageBox = false" style="margin-right: 20px" width="100">取 消</SLButton>
          <SLButton :loading="sendMsgLoading" width="100" @click="sendMessage"><i class="el-icon-s-promotion"
                                                                                  style="font-size: 14px"></i>
          </SLButton>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import userCenterApi from "@/common/api/userCenterApi";
import Loading from "@/components/Loading";
import Avatar from "vue-avatar";
import getUrl from "@/common/utils/getUrl";
import userPersonalApi from "@/common/api/userPersonalApi";
import SLButton from "@/components/SLButton";

export default {
  name: "Messages",
  components: {Loading, Avatar, SLButton},
  data() {
    return {
      card: "1",
      loading: true,
      loadingF: true,
      page: 1,
      size: 12,
      result: {},
      pageF: 1,
      sizeF: 12,
      resultF: {},
      privateMessageBox: false,
      sendMsgLoading: false,
      msg: "",
      id:"",
    }
  },
  mounted() {
    userCenterApi.getPrivateMessage(this.page, this.size, false, res => {
      this.loading = false;
      this.result = res;
    })
    userCenterApi.getPrivateMessage(this.pageF, this.sizeF, true, res => {
      this.loadingF = false;
      this.resultF = res;
    })
  },
  methods: {
    getUrls(url) {
      return getUrl(url);
    },
    onPage(page) {
      this.page = page;
      this.loading = true;
      userCenterApi.getPrivateMessage(this.page, this.size, false, res => {
        this.loading = false;
        this.result = res;
      })
    },
    onPageF(page) {
      this.pageF = page;
      this.loadingF = true;
      userCenterApi.getPrivateMessage(this.pageF, this.sizeF, true, res => {
        this.loadingF = false;
        this.resultF = res;
      })
    },
    sendMessage() {
      if (this.msg.trim().length < 10) {
        return this.$message.warning("内容不能少于10个字符哦~")
      }
      this.sendMsgLoading = true;
      userPersonalApi.sendMessage(this.id, this.msg.trim(), state => {
        this.sendMsgLoading = false;
        if (state) {
          this.privateMessageBox = false;
          this.$message.success("私信发送成功~");
          this.msg = "";
        }
      })
    }
  },
}
</script>

<style scoped lang="less">
/deep/ .avatar {
  span {
    margin: 0 !important;
  }
}

.content-area {
  outline: none;
  resize: none;
  overflow: hidden;
  font-size: 14px;
  padding: 20px 30px;
  height: 100px;
  width: calc(100% - 62px);
  color: var(--main-black);
  border: 1px #cccccc4b solid;
  border-radius: 8px;
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;

  .btn {

    &:hover {
      color: var(--main-green);
      background: #fff;
      border-color: var(--main-green);
    }
  }

  .active {
    color: var(--main-green);
    background-color: #fff;
    border-color: var(--main-green);
  }
}
.msg {
  position: relative;
  padding: 10px;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;

  .info {
    span {
      margin-right: 40px;
    }

    span:nth-child(1), span:nth-child(3), span:nth-child(5) {
      margin-right: 2px;
    }

    span:nth-child(2), span:nth-child(4), span:nth-child(6) {
      color: #777;
    }

    span:nth-child(7) {
      color: var(--main-green);
      cursor: pointer;
    }
  }

  .msg-info {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;

    .content {
      position: relative;
      background-color: #f3f7fb;
      font-size: 14px;
      padding: 10px 20px;
      border-radius: 8px;
      max-width: calc(100% - 110px);
      word-break: break-all;
      margin-left: 20px;

      &:before {
        content: "";
        position: absolute;
        left: -10px;
        top: calc(50% - 5px);
        width: 0;
        height: 0;
        border: 5px solid transparent;
        border-right: 5px solid #f3f7fb;
      }

    }
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #cccccc4b;
  }
}
</style>
