import {hasRes, request} from "@/common/request";
import message from "element-ui/packages/message/src/main";

const manageApi = {

    /**
     * 更新添加菜单
     * @param data
     * @param callback
     */
    addOrUpdateMenu(data, callback) {
        if (!data || !data.length) return callback();
        request({
            url: "/menu/update",
            data: data,
            method: "POST",
            success: res => {
                if (callback) callback();
                if (hasRes(res)) {
                    return message.success("保存成功~")
                }
                message.error(res.message)
            }
        })
    },

    /**
     * 删除菜单
     * @param mid 菜单id
     * @param callback
     */
    deleteMenu(mid, callback) {
        request({
            url: "/menu/dl",
            method: "DELETE",
            data: {"mid": mid},
            success: res => {
                if (hasRes(res)) {
                    callback();
                    return message.success("删除成功")
                }
                message.error(res.message)
            }
        })
    },

    /**
     * 获取菜单数据
     * @param callback
     */
    getMenus(callback) {
        request({
            url: "/menu",
            method: "GET",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                console.error("获取菜单数据异常")
            }
        })
    }
}

export default manageApi;
