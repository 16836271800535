import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import Meta from 'vue-meta'
import 'sweetalert'
import Icon from 'vue2-svg-icon/Icon.vue';
import {baseUrl} from "@/common/env"

Vue.config.productionTip = false
Vue.component(`svg-icon`, Icon);
Vue.use(ElementUI);
Vue.use(Meta);
Vue.prototype.$baseUrl = baseUrl;

Vue.directive('hljs',function (el) {
    let blocks = el.querySelectorAll('pre');
    blocks.forEach((block)=>{
        hljs.highlightBlock(block)
    })
})


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
