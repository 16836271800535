<template>
  <el-row>
    <el-col :md="{span: 16, offset: 4}" v-if="Object.keys(result).length">
      <h1>{{ result.title }}</h1>
      <h4>{{result.category.name}}</h4>
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center">
        <Avatar :lighten="10000" :username="result.author.username" :src="getUrl(result.author.avatar)" :size="30"/>
        <span
            style="padding-left: 10px"> {{ result.author.nickname || result.author.email || result.author.username }}</span>
        <span style="padding-left: 20px">{{ result.date }}</span>
      </div>
      <div class="article-details" v-html="result.content"></div>
      <div class="hidden-content" v-if="result.permission">
        <h3>隐藏内容</h3>
        <span>查阅权限：
        <template v-for="item in result.permission.permission">
          {{item}}
        </template>
        </span>
        <span style="padding-left: 50px">所需(积分)金额：{{result.permission.content.money || "不需要"}}</span>
        <div style="margin-top: 50px;text-align: left" v-html="result.permission.content.content"></div>
      </div>
      <div class="download">
        <div class="item-dl" v-for="(item,index) in result.downloads" :key="index">
          <svg-icon :name="getName(item.name)" w="40"></svg-icon>
          <div class="dl-info">
            <SLText>{{ item.name }}</SLText>
            <div style="display: flex;flex-direction: row">
              <span>{{ item.type }}</span>
              <span>提取码：{{ item.code || '无' }}</span>
            </div>
            <div class="mark-menu">
              <el-tooltip :content="`当前附件所需积分${item.money}，点击下载会自动从您的积分中扣除~`" placement="top">
                <i class="el-icon-s-opportunity"
                   style="font-size: 25px; color: orange;background: #fff;border-radius: 50%;padding: 3px"></i>
              </el-tooltip>
              <el-tooltip content="点击跳转下载页面" placement="top" style="margin-left: 40px;animation-delay: .1s"
                          :data-clipboard-text="item.code" :id="`code${index}`"
                          @click.native="onDownload(item.url, index)">
                <i class="sl icon-download"
                   style="font-size: 28px; color: var(--main-green);background: #fff;border-radius: 50%"></i>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <div class="btn">
        <el-button type="danger" size="mini" @click="onExamine(false)">驳 回</el-button>
        <el-button type="success" size="mini" @click="onExamine(true)">通 过</el-button>
        <el-button type="warning" size="mini" @click="$router.push('/edit/' + result.id)">编辑</el-button>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import SLText from "@/components/SLText";
import commonApi from "@/common/api/commonApi";
import Avatar from "vue-avatar"
import getUrl from "@/common/utils/getUrl";

let Base64 = require('js-base64').Base64
import Clipboard from 'clipboard';

export default {
  name: "ExamineArticlePage",
  data() {
    return {
      result: {},
      getUrl: getUrl
    }
  },
  created() {
    commonApi.getArticleDetails(this.$route.params.articleId, res => this.result = res);
  },
  methods: {
    onExamine(status) {
      commonApi.examineArticle(this.result.id, status, () => {
        this.$message.success(status ? "文章审核已通过" : "文章驳回成功")
        setTimeout(() => this.$router.push("/user/article-manage"), 1000)
      })
    },
    getName(name) {
      let suffix = [".zip", '.rar', ".pdf", ".7z", ".md", ".dmg"];
      for (const items of suffix) {
        if (name.toLowerCase().indexOf(items) > 0) {
          return items.replaceAll(".", "");
        }
      }
      return "resource";
    },
    toDownload(url) {
      return Base64.encodeURI(url);
    },
    onDownload(url, index) {
      let id = `code${index}`;
      let clipboard = new Clipboard('#' + id)
      clipboard.on('success', e => {
        this.$message({message: "提取码已复制至剪切板~", duration: 4000, type: "success"})
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.warn('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
      this.$router.push('/to/' + this.toDownload(url))
    }
  },
  components: {SLText, Avatar},
}
</script>

<style scoped lang="less">
.article-details {
  margin: 40px 0;
  text-align: left;
  min-height: 30vh;
}

.hidden-content {
  border: 2px var(--main-green) dashed;
  padding: 10px;
  border-radius: 10px;
}

/deep/ .el-tooltip {
  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.5;
  }

  cursor: pointer;
  animation: icon-an .5s linear normal;
}

.btn {
  margin: 50px 0;
}

@keyframes icon-an {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px)
  }
}

.download {
  padding: 20px;

  .item-dl {
    position: relative;
    display: inline-flex;
    padding: 10px 15px;
    border: 1px #cccccc3d solid;
    border-radius: 8px;
    flex-direction: row;

    .mark-menu {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(1px);
      background-color: #0000005c;
      display: none;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
    }

    .dl-info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 200px;
      justify-content: center;

      &:hover .mark-menu {
        display: flex;
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
        color: #2c3e50e0;
      }

      span, p {
        font-size: 13px;
        padding-left: 10px;
        margin-bottom: 2px;
      }

      div:nth-child(2) {
        span:nth-child(1) {
          display: block;
          padding: 1px 2px;
          margin-left: 10px;
          font-size: 12px;
          color: #fff;
          border-radius: 4px;
          background-color: var(--main-green);
        }
      }
    }
  }
}
</style>