<template>
  <div>
    <div class="article-comment">
      <div class="user-info">
        <Avatar class="avatar" :size="40" :lighten="10000" :username="commentData.user.nickname"
                :src="getAvatarUrl(commentData.user.avatar)"></Avatar>
        <span>{{ commentData.user.nickname }}</span>
      </div>
      <div class="content">
        <span>{{ commentData.content }}</span>
        <div class="comment-date" v-if="commentData.id">
          <div class="interactive">
            <span @click="onPraise"><i class="sl icon-zan"></i> 赞({{ commentData.praise }})</span>
            <span><i class="sl icon-pinglun"
                     @click="onReplyComment"> 回复({{ commentData.reply.length }})</i></span>
            <span style="color: var(--main-green)" @click="onChange"
                  v-if="commentData.reply.length">{{ title ? '收起' : '更多' }}</span>
          </div>
          <span>{{ commentData.date }}</span>
        </div>
        <CommentMore :status="title" :list-data="commentData.reply" :parentId="commentData.id" :article-id="articleId"/>
      </div>
      <ReplyBox ref="replyBox" :parent="commentData" :article-id="articleId" @reply="onReplyData"/>
    </div>
  </div>
</template>

<script>
import CommentMore from "@/views/article/components/CommentMore";
import ReplyBox from "./ReplyBox"
import Avatar from "vue-avatar";
import {mapState} from "vuex";
import commentApi from "@/common/api/commentApi";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "Comment",
  components: {CommentMore, ReplyBox, Avatar},
  data() {
    return {
      title: false,
      showReply: false
    }
  },
  methods: {
    onChange() {
      this.title = !this.title;
    },
    onReplyData(data) {
      this.commentData.reply.unshift(data);
    },
    onReplyComment() {
      if (!this.isLogin)
        return this.loginBox.show();
      this.$refs.replyBox.show();
    },
    onPraise() {
      commentApi.praiseComment(this.commentData.id, () => {
        this.$set(this.commentData, "praise", this.commentData.praise + 1);
      })
    },
    getAvatarUrl(url){
      return getUrl(url);
    }
  },
  props: {
    commentData: {
      type: Object,
      default: () => {
      }
    },
    articleId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      loginBox: state => state.user.loginBox,
      isLogin: state => state.user.isLogin
    })
  }
}
</script>

<style scoped lang="less">
.article-comment {
  margin-top: 30px;
  margin-bottom: 40px;

  .user-info {
    display: flex;
    align-items: center;
    color: #777;
    font-size: 14px;

    i {
      font-size: 16px;
      color: orange;
      font-weight: 900;
    }

    .avatar {
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .content {
    margin-left: 50px;
    font-size: 13.5px;
    padding-bottom: 20px;
    border-bottom: 1px #cccccc4b solid;

    .comment-date {
      display: flex;
      margin-top: 20px;
      align-items: center;
      color: #999;
      justify-content: space-between;

      .interactive {
        display: flex;
        flex-direction: row;
        font-size: 13.5px;
        margin-right: 10px;

        span {
          position: relative;
          cursor: pointer;
          font-size: 13.5px;
          margin-right: 10px;

          &:hover {
            color: var(--main-green)
          }
        }
      }
    }

    //.content-more {
    //  margin-top: 20px;
    //  padding-left: 10px;
    //  border-left: 5px solid #50d6522b;
    //  transition: height 1s linear;
    //}
    //
    //.hidden-more {
    //  height: 0;
    //  visibility: hidden;
    //  transition: 1s linear;
    //}
  }
}
</style>
