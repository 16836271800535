<template>
  <div class="video-wrapper" :style="{visibility: visibility}">
    <div class="video-container" :class="box ? 'video-show': 'video-hide'">
      <iframe :src="src" frameBorder="0" allowFullScreen></iframe>
      <div class="video-close" @click="close"><i class="el-icon-close"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoBox",
  data() {
    return {
      visibility: 'hidden',
      box: false,
    }
  },
  props: {
    url: {
      type: String,
      default: ""
    }
  },
  methods: {
    show() {
      this.box = true;
      this.visibility = "visible"
    },
    close() {
      this.box = false;
      setTimeout(() => this.visibility = 'hidden', 500)
    }
  },
  computed: {
    src() {
      if (!this.box) {
        return `/iframe.html`
      }
      return this.url;
    }
  }
}
</script>

<style scoped lang="less">
.video-hide {
  //visibility: hidden;
  animation: box-h .5s ease normal;

  @keyframes box-h {
    from {
      transform: scale(1)
    }
    to {
      transform: scale(0)
    }
  }
}

.video-show {
  animation: box-s .5s ease normal;

  @keyframes box-s {
    from {
      transform: scale(0)
    }
    to {
      transform: scale(1)
    }
  }
}

.video-wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000bf;
  z-index: 99999;

  .video-container {
    position: relative;
    width: 900px;
    height: 506px;
    border-radius: 8px;
    background-color: #000000;

    iframe {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      outline: none;
    }

    .video-close {
      position: absolute;
      right: 0;
      top: -25px;

      i {
        font-weight: bold;
        font-size: 20px;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .video-container {
    width: 95% !important;
    height: 30% !important;
  }
}

@media screen and(min-width: 992px) {

}
</style>
