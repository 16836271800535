import {request, hasRes, hasResCode, handlerRes} from "@/common/request";
import Message from "element-ui/packages/message/src/main";
import Router from "@/router";
import store from "@/store/index";

const articleApi = {
    /**
     * 获取静态url
     * @param title 文章标题
     * @param callback
     */
    getStaticUrl(title, callback) {
        if (title) {
            request({
                url: "/article/static.url?translateStr=" + title,
                method: "get",
                success: res => {
                    if (hasRes(res)) {
                        callback(res.result);
                        return;
                    }
                    callback(null);
                }
            });
        }
    },

    /**
     * 获取所有分类
     * @param callback
     */
    getAllCategory(callback) {
        request({
            url: "/article/category",
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result)
                }
                console.error("获取分类信息异常")
            }
        });
    },

    /**
     * 搜索标签
     * @param names 标签名称
     * @param callback
     */
    searchTags(names, callback) {
        if (names === null || names.length === 0) return;
        request({
            url: `/article/tag.search?name=${names}`,
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                callback([])
                console.error("搜索标签异常")
            }
        })
    },

    /**
     * 新建文章
     * @param data 表单数据
     * @param level
     * @param isEdit
     * @param callback
     */
    createNewArticle(data, level, isEdit, callback) {
        request({
            url: "/article/new",
            method: "post",
            data: data,
            success: res => {
                if (hasRes(res)) {
                    isEdit ? Message.success(level === "Admin" ? "修改成功~" : "修改成功，请等待站长审核...")
                        : Message.success(level === "Admin" ? "发布成功~" : "发布成功，请等待站长审核...")
                } else {
                    Message.warning(res.message);
                }
                callback(hasRes(res));
            }
        })
    },

    /**
     * 获取编辑回显数据
     * @param aid
     * @param callback
     */
    getArticleData(aid, callback) {
        if (!aid) return;
        request({
            url: "/article?articleId=" + aid,
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                if (hasResCode(res, 40003) || hasResCode(res, 70001)) {
                    Router.push("/");
                }
            }
        })
    },


    /**
     * 搜索分类
     * @param kw 关键字
     * @param callback
     */
    searchCategory(kw, callback) {
        if (!kw) return;
        request({
            url: "/article/category.search?keyword=" + kw,
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                callback([])
            }
        })
    },

    /**
     * 获取分类下的文章
     * @param cid 分类id
     * @param page 页码
     * @param size 数据量
     * @param order 排序类型
     * @param callback
     */
    getCategoryArticle(cid, page, size, order, callback) {
        request({
            url: "/article/list?" + `cid=${cid}&page=${page}&size=${size ? size : 12}&order=${order}`,
            method: "get",
            success: res => callback(res.result)
        })
    },

    /**
     * 获取文章详情页文章内容
     * @param url 文章链接
     * @param callback
     */
    getArticleDetails(url, callback) {
        request({
            url: "/article/details?url=/" + url,
            method: "get",
            success: res => {
                store.commit("article/setPermission", res.result.permission);
                store.commit("article/setStatus", res.result.status);
                callback(res.result);
            }
        })
    },

    /**
     * 收藏文章
     * @param articleId 文章id
     * @param callback
     */
    collection(articleId, callback) {
        request({
            url: "/article/collection?articleId=" + articleId,
            method: "post",
            success: res => {
                if (hasRes(res)) {
                    callback()
                    return Message.success("收藏成功~");
                }
                Message.warning(res.message);
            }
        })
    },


    /**
     * 取消收藏
     * @param articleId 文章id
     * @param callback
     */
    cancelCollection(articleId, callback) {
        request({
            url: "/article/collection?articleId=" + articleId,
            method: "delete",
            success: res => {
                if (hasRes(res)) {
                    callback()
                    return Message.success("收藏已取消~");
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 赞文章
     * @param articleId
     * @param callback
     */
    praiseArticle(articleId, callback) {
        request({
            url: "/article/praise?articleId=" + articleId,
            method: "post",
            success: res => {
                if (hasRes(res)) {
                    return callback()
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 获取标签下的文章
     * @param tagName 标签名称
     * @param sort 排序 Newest 最新 Recent 最近更新  MostView 最多浏览 Collect 最多收藏
     * @param limit 每页数量
     * @param offset 偏移量
     * @param callback
     */
    getArticlesByTag(tagName, sort, limit, offset, callback) {
        request({
            url: `/article/tag?tagName=${tagName}&sort=${sort}&page=${limit}&size=${offset}`,
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 获取文章大纲
     * @param articleId 文章id
     * @param callback
     */
    getArticleNavigation(articleId, callback) {
        request({
            url: `/article/nav?articleId=${articleId}`,
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 搜索 文章
     * @param kw 关键字
     * @param page
     * @param size
     * @param callback
     */
    searchArticle(kw, page, size, callback) {
        request({
            url: `/article/search?page=${page}&size=${size}`,
            method: "post",
            data: {
                kw
            },
            success: res => handlerRes(res, callback)
        })
    }
}

export default articleApi;
