import {hasRes, request} from "@/common/request";
import {Message} from "element-ui";

const orderApi = {
    /**
     * 提交订单
     * @param data
     * @param callback
     */
    submitOrder(data, callback) {
        request({
            url: "/order",
            method: "POST",
            data: data,
            success: res => {
                callback();
                if (hasRes(res)) {
                    return;
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 积分支付 下载链接
     * @param data
     * @param callback
     */
    creditPayResource(data, callback) {
        request({
            url: "/order/resource",
            method: "POST",
            data: data,
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result.url);
                }
                callback(null);
                Message.warning(res.message);
            }
        })
    }
}

export default orderApi;
