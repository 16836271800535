<template>
  <div style="background-color:#f8f8f8">
    <el-row style="text-align:left;">
      <el-col :md="{span: 16, offset: 4}">
        <!-- 标题 -->
        <div class="sl-home-title">
          <h4>热门</h4>
          <p @click="$router.push({name: 'Hot'})">查看更多</p>
        </div>
        <!-- 列表 -->
        <div class="home-new-list">
          <el-row :gutter="15">
            <el-col :md="6" :xs="24" v-for="(item, index) in hot" :key="index" class="home-hot-list">
              <div class="home-new-list-item" @click="toDetail(item)">
                <div class="new-article-img">
                  <el-image :src="getUrls(item.image) + '?s=412x220'" fit="cover"
                            style="height: 100%;border-radius: 8px"></el-image>
                  <SLTag class="new-tag" type="dark" color="rgb(0 0 0 / 35%)" @click.native="$router.push({name: 'Tag', params:{name: item.tag}})">{{ item.tag }}</SLTag>
                </div>
                <div style="display: flex;justify-items: center; justify-content:center; flex-direction: column">
                  <div class="new-article">
                    <SLText line="2" style="min-height: 38px">{{ item.title }}</SLText>
                    <SLText line="2" style="font-size: 12px; color: #7d7d7d;min-height: 32px">{{ item.description }}</SLText>
                  </div>
                  <div class="new-user">
<!--                    <el-avatar src="https://solove.love/media/uploads/article/images/2019/05/solove/lo_1557896732_1.gif"-->
<!--                               :size="20"></el-avatar>-->
                    <Avatar :lighten="10000"
                            :src="getUrls(item.author.avatar)"
                            :username="item.author.username"
                            :size="20"/>
                    <span style="padding-left: 5px"> {{ item.author.name }}</span>
                    <span><el-icon name="time"></el-icon>{{ item.date }}</span>
                    <span>浏览 {{item.view}}</span>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import SLText from "@/components/SLText";
import SLTag from "@/components/SLTag";
import homeApi from "@/common/api/homeApi";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar"

export default {
  name: "TopHotArea",
  data() {
    return {
      hot: null
    }
  },
  created() {
    homeApi.getTopHotArticle(res => {
      this.hot = res.splice(4, 8);
    })
  },
  methods: {
    toDetail(item) {
      this.$router.push(item.staticUrl)
    },
    getUrls(url) {
      if (url)
        return getUrl(url);
    }
  },
  components: {
    SLText,
    SLTag,
    Avatar
  }
}
</script>

<style scoped lang="less">

.home-new-list {
  margin-bottom: 30px;

  .new-article {
    margin: 5px 0 5px 0;
    padding: 0 5px;
  }

  .new-user {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0 5px;

    span {
      margin-right: 5px;
    }
  }

  .new-article-img {
    position: relative;

    .new-tag {
      position: absolute;
      top: 10px;
      left: 10px;
    }
  }
}

.home-new-list-item {
  cursor: pointer;
  height: 370px;
  box-shadow: 0 0 20px #cccccc52;
  border-radius: 6px;

  &:hover {
    box-shadow: 0 0 20px #cccccc;
    transform: scale(1.01);
    transition: .3s ease;
  }

}

@media screen and(max-width: 768px) {
  .home-new-list-item {
    padding: 0 5px
  }

  .new-article-img {
    height: 240px;
  }

  .home-hot-list {
    margin-bottom: 20px;
  }
}


@media screen and(min-width: 992px) {
  .new-article-img {
    height: 220px;
  }

  .home-new-list-item {
    margin-bottom: 20px;
  }
}
</style>
