import {handlerRes, hasRes, request} from "@/common/request";
import {Message} from "element-ui";

const userPersonalApi = {

    /**
     * 获取用户资料
     * @param uid 用户ID
     * @param callback
     */
    getPersonalData(uid, callback) {
        request({
            url: "/user/personals.data?uid=" + uid,
            method: 'get',
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 获取当前用户是否关注当前用户
     * @param uid
     * @param callback true 已关注
     */
    getPersonalFocusState(uid, callback) {
        request({
            url: "/user/personals.focus.state?uid=" + uid,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 关注或取消关注用户
     * @param uid 要关注或取消关注用户的id
     * @param state true 关注  false取消关注
     * @param callback 成功true
     */
    focusPersonalOrCancel(uid, state, callback) {
        request({
            url: "/user/personals.focus",
            method: "post",
            data: {
                uid,
                state
            },
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 发送私信
     * @param uid 目标用户id
     * @param msg 消息
     * @param callback bool success is true
     */
    sendMessage(uid, msg, callback) {
        request({
            url: "/user/personal.private.message",
            method: "post",
            data: {
                to: uid,
                message: msg
            },
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取用户发布的文章
     * @param uid 用户id
     * @param page 页码
     * @param callback
     */
    getPersonalArticle(uid, page, callback) {
        request({
            url: `/user/personals.article?uid=${uid}&page=${page}&size=12`,
            method: 'get',
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取粉丝列表
     * @param uid 用户id
     * @param page 页码
     * @param callback array
     */
    getFansList(uid, page, callback) {
        request({
            url: `/user/personals.fans.list?uid=${uid}&page=${page}&size=12`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },


    /**
     * 获取关注列表
     * @param uid 用户id
     * @param page 页码
     * @param callback array
     */
    getFocusList(uid, page, callback) {
        request({
            url: `/user/personals.focus.list?uid=${uid}&page=${page}&size=12`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },
}

export default userPersonalApi;