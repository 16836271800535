<template>
  <ul>
    <template v-for="(item,index) in nodes">
      <li @click="toLocation(item.tagId)">{{ item.name }}</li>
      <NavigationItem :nodes="item.children" v-if="item.children.length"></NavigationItem>
    </template>
  </ul>
</template>

<script>
export default {
  name: "NavigationItem",
  props: {
    nodes: {
      type: Array,
      default: []
    }
  },
  methods: {
    toLocation(id) {
      let element = document.getElementById(id);
      let rect = element.getBoundingClientRect();
      let top = window.pageYOffset + rect.top;

      window.scrollTo({behavior: "smooth", top: top})
    }
  }
}
</script>

<style scoped>

</style>
