<template>
  <div class="other-login">
    <span><i :class="className"></i>{{ status ? "登录失败，请稍后再试~" : "系统处理中，请稍后..."}}</span>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import {request} from "@/common/request";

export default {
  name: "Login",
  data() {
    return{
      status: false,
      className: "sl icon-loading loading"
    }
  },
  mounted() {
    setTimeout(() => this.getUserInfo(this.$route.params.token), 1000)
  },
  methods: {
    ...mapMutations({
      setToken: "user/setToken",
      setUserInfo: "user/setUserInfo"
    }),
    getUserInfo(token) {
      request({
        url: "/user/info",
        method: "GET",
        headers: {
          Authorization: "SL " + token
        },
        success: (res) => {
          if (res.code === 10000) {
            this.setUserInfo(res.result);
            this.setToken(token);
            this.$message.success("登录成功, 即将跳转首页~");
            setTimeout(() => {
              this.$router.push("/");
            }, 1000)
          } else {
            this.className = "el-icon-warning warn";
            setTimeout(() => this.status = true, 500)
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.other-login {
  height: calc(100vh - 217px);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {

    i {
      display: inline-block;
      margin-right: 5px;
      font-size: 15px;
      color: var(--main-green)
    }

    .loading {
      animation: loading .5s linear infinite;

      @keyframes loading {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }
}
.warn {
  color: orange !important;
  font-size: 18px !important;
  animation: warn .5s linear normal;

  @keyframes warn {
    0% {
      transform: rotate(0deg) scale(0);
    }
    100% {
      transform: rotate(360deg) scale(1);
    }
  }
}
</style>
