<template>
  <div class="active-account">
    <span :style="{'padding-bottom': btn ? 0 : '40px' }"><i :class="className"></i> {{ text }}</span>
    <SLButton style="margin-top: 30px" v-if="btn" @click="dialog = true" :disabled="sendStatus">
      {{ sendStatus ? "已发送" : "重新发送激活邮件" }}
    </SLButton>
    <el-dialog :visible.sync="dialog" title="发送激活邮件" width="30%" center>
      <el-input v-model="email" @keyup.enter.native="sendEmail">
        <template slot="prepend">Email</template>
      </el-input>
      <span slot="footer" class="btn-group">
        <SLButton style="margin-right: 20px" @click="dialog=false">取消</SLButton>
        <SLButton @click="sendEmail" :loading="btnLoading">确定</SLButton>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import userApi from "@/common/api/userApi";
import SLButton from "@/components/SLButton";

export default {
  name: "ActiveAccountPage",
  components: {SLButton},
  data() {
    return {
      // el-icon-success success
      className: "sl icon-loading loading",
      loading: true,
      s: 6,
      text: "系统处理中，请稍后...",
      btn: false,
      dialog: false,
      email: "",
      btnLoading: false,
      sendStatus: false
    }
  },
  created() {
    let token = this.$route.params.token;
    userApi.activeAccount(token, (status) => {
      setTimeout(() => this.changeSuccess(status), 3000);
    });
  },
  methods: {
    changeSuccess(status) {
      if (status) {
        this.className = "el-icon-success success";
      } else {
        this.className = "el-icon-warning warn success"
      }
      setTimeout(() => {
        this.loading = false;
        if (status) {
          // 激活成功
          this.text = `激活成功，${this.s}s 返回首页`;
          let t = setInterval(() => {
            if (this.s === 0) {
              clearInterval(t);
              this.$router.push("/");
              setTimeout(() => this.loginBox.show(), 800);
            }
            --this.s;
            this.text = `激活成功，${this.s}s 返回首页`;
          }, 1000)
        } else {
          // 激活失败
          this.text = "链接无效或已过期，激活失败！";
          this.btn = true;
        }
      }, 500)
    },
    sendEmail() {
      userApi.sendEmail(this.email.trim(), (status) => {
        if (status) {
          this.dialog = false;
          this.sendStatus = true;
          this.email = '';
        }
      });
    }
  },
  computed: {
    ...mapState({
      loginBox: state => state.user.loginBox,
    }),
  },
}
</script>

<style scoped lang="less">
.active-account {
  height: calc(100vh - 217px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    display: flex;
    align-items: center;

    i {
      font-size: 20px;
      color: var(--main-green);
      margin-right: 5px;
    }
  }

  .success {
    animation: succ .5s linear normal;

    @keyframes succ {
      0% {
        transform: rotate(0deg) scale(0);
      }
      100% {
        transform: rotate(360deg) scale(1);
      }
    }
  }

  .warn {
    color: orange;
  }
}

.loading {
  animation: loading .5s linear infinite;

  @keyframes loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.btn-group {
  display: flex;
  justify-content: center;
}
</style>
