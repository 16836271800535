<template>
  <el-row>
    <el-col>
      <div style="display:flex;justify-content: space-between;">
        <div style="display: flex; flex-direction: row">
          <SLButton @click="dialog = true; type='Link'"><i class="el-icon-link"></i></SLButton>
          <SLButton style="margin-left:5px" @click="dialog = true;"><i class="sl icon-menu"></i>
          </SLButton>
        </div>
        <SLButton style="margin-right: 20px" @click="onSubmit" :loading="submitting">保存</SLButton>
      </div>
      <SLMenu v-model="menuData" @change="onChangeMenu"></SLMenu>
    </el-col>
    <el-dialog :title="title ? title : type === 'Link'? '添加节点或链接' : '添加分类'" width="30%" center :visible.sync="dialog"
               @closed="type='';name='';link='';item='';">
      <el-select v-if="type !== 'Link'"
                 v-model="selected"
                 multiple
                 filterable
                 remote
                 reserve-keyword
                 placeholder="请输入关键词"
                 :remote-method="remoteMethod"
                 :loading="loading"
                 style="width: 100%"
                 value-key="name"
      >
        <el-option
            v-for="(item, index) in categorys"
            :key="index"
            :label="item.name"
            :value="item">
        </el-option>
      </el-select>
      <div v-else class="input-link">
        <!-- 添加链接 -->
        <el-input class="input" v-model="name" placeholder="请输入名称" @keyup.enter.native="onAddData"/>
        <el-input class="input" v-model="link" style="flex: 3; margin-left: 10px" placeholder="请输入链接或留空，空为节点"
                  @keyup.enter.native="onAddData"/>
      </div>
      <span slot="footer" class="dialog-footer">
        <SLButton @click="dialog = false">取 消</SLButton>
        <SLButton @click="onAddData()">确 定</SLButton>
      </span>
    </el-dialog>
  </el-row>
</template>

<script>
import SLMenu from "@/views/user/Components/SLMenu";
import SLButton from "@/components/SLButton";
import articleApi from "@/common/api/articleApi";
import menageApi from "@/common/api/manageApi";

export default {
  name: "Menu",
  data() {
    return {
      menuData: [],
      dialog: false,
      type: "",
      categorys: [],
      loading: false,
      selected: [],
      name: "",
      link: "",
      submitting: false,
      title: "",
      change: false,
      item: null,
    }
  },
  mounted() {
    menageApi.getMenus((res) => this.menuData = res)
  },
  methods: {
    onAddData() {
      if (!this.change) {
        if (this.type === 'Link') {
          if (this.name.trim().length === 0) {
            return this.$message.warning("请输入名称")
          }
          // let regex = /^http(s)?:\/\/.*(\.)?.*$/g
          // if (this.link && !regex.test(this.link)) {
          //   return this.$message.warning("链接格式不正确")
          // }
          if (!this.link) return this.$message.warning("请输入链接路由名称")
          this.dialog = false;
          if (this.link) {
            this.menuData.push({
              name: this.name,
              type: "Link",
              link: this.link,
            })
          } else {
            this.menuData.push({
              name: this.name,
              type: 'Node',
            })
          }
          this.name = "";
          this.link = "";
          return;
        }
        this.dialog = false;
        this.selected.forEach(item => {
          item["type"] = "Category";
          let cid = item.id;
          delete item.id;
          item["categoryId"] = cid;
        });
        this.menuData = this.menuData.concat(this.selected);
        this.selected = [];
      } else {
        this.change = false;
        // 修改
        this.changeData(this.menuData, this.item);

        this.item = null;
        this.dialog = false;
      }
    },
    remoteMethod(e) {
      this.loading = true;
      articleApi.searchCategory(e, res => {
        this.loading = false;
        this.categorys = res;
      })
    },
    onSubmit() {
      this.submitting = true;
      menageApi.addOrUpdateMenu(this.menuData, () => this.submitting = false);
    },
    onChangeMenu(item) {
      this.dialog = true;
      this.title = "修改";
      this.type = 'Link';
      this.name = item.name;
      this.link = item.link;
      this.change = true;
      this.item = item;
    },
    changeData(source, data) {
      for (let i = 0; i < source.length; i++) {
        if (source[i].id === data.id && source[i].link === data.link && source[i].name === data.name && source[i].type === data.type) {
          // this.$set(source[i], 'name', data.name);
          // this.$set(source[i], 'link', data.link);
          // this.$set(source[i], 'type', this.link ? 'Link' : "Node");
          source[i].name = this.name;
          source[i].link = this.link;
          source[i].type = this.link ? 'Link' : "Node";
          return;
        }
        if (source[i].children !== undefined && source[i].children.length)
          this.changeData(source[i].children, data)
      }
    }
  },
  components: {
    SLButton, SLMenu,
  },
}
</script>

<style scoped lang="less">
.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.input-link {
  display: flex;
  flex-direction: row;

  .input {
    flex: 2;
  }
}
</style>
