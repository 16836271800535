<template>
  <div class="emoji-icon">
    <el-popover ref="tip" trigger="click" placement="top" @hide="hideTip">
      <i class="sl icon-emoji" slot="reference"></i>
      <el-carousel :initial-index="0" ref="emojiS" :autoplay="false" height="130px" style="width:202px;"
                   indicator-position="none" arrow="never">
        <el-carousel-item v-for="(item, index) in emoji" :key="index">
          <div class="item-icon">
            <span @click="emojiClick(index, indexes)" v-for="(items, indexes) in item" :key="indexes">{{ items }}</span>
          </div>
        </el-carousel-item>
      </el-carousel>
      <div class="indicator">
        <span :class="index === emojiIndex ? 'active' : ''" v-for="(item, index) in emoji" :key="index"
              @click="setIndex(index)"></span>
      </div>
    </el-popover>
  </div>
</template>

<script>
import emojiHandler from "@/static/js/emoji.js"

export default {
  name: "Emoji",
  data(){
    return{
      emoji: [],
      emojiIndex: 0
    }
  },
  mounted() {
    this.emoji = emojiHandler.data();
  },
  methods: {
    hideTip(){
      this.setIndex(0)
    },
    emojiClick(i1, i2){
      this.$refs.tip.showPopper = false;
      this.$emit('change', this.emoji[i1][i2])
    },
    setIndex(index){
      this.emojiIndex = index;
      this.$refs.emojiS.setActiveItem(index)
    },
  }
}
</script>

<style scoped lang="less">
.emoji-icon {
  color: #777;

  i {
    font-size: 18px;
    cursor: pointer;
  }

}

.item-icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  cursor: pointer;

  span {
    font-size: 18px;
    margin: 2px;
  }
}

.indicator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    position: relative;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      width: 5px;
      height: 5px;
      top: 7.5px;
      left: 7.5px;
      border-radius: 50%;
      cursor: pointer;
      background-color: #cccccc;
      z-index: 999;
      transition: background-color .2s ease-in;
    }

  }

  .active {
    &:before {
      background-color: #55ca56c9;
      transition: .2s ease-in;
    }
  }
}
</style>
