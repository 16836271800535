<template>
  <div class="bubble" :class="position" ref="bubbles">
    <span class="icon" :style="styles"></span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Bubble",
  data() {
    return {
      top: 0,
      left: 0,
    }
  },
  props: {
    position: {
      type: String,
      default: "left" // left top right bottom
    }
  },
  mounted() {
    this.top = (this.$refs.bubbles.clientHeight - 8) / 2;
    this.left = (this.$refs.bubbles.clientWidth - 8) / 2;
  },
  computed: {
    styles() {
      let temp = {}
      switch (this.position) {
        case 'left':
          temp['top'] = this.top + 'px';
          break;
        case 'top':
          temp['left'] = this.left + 'px';
          break;
        case 'right':
          temp['top'] = this.top + 'px';
          break;
        case 'bottom':
          temp['left'] = this.left + 'px';
          break;
        default:
          temp['top'] = this.top + 'px';
          break;
      }
      return temp;
    },
  }
}
</script>

<style scoped lang="less">
.bubble {
  position: relative;
  border: 1px #cccccc4b solid;
  border-radius: 8px;

  .icon {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border: 8px transparent solid;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 8px transparent solid;

    }
  }
}

.left {
  .icon {
    left: -16px;
    border-right: 8px #cccccc4b solid;

    &:before {
      left: -6px;
      top: -8px;
      border-right: 8px #fff solid;
    }
  }
}

.top {
  .icon {
    top: -16px;
    border-bottom: 8px #cccccc4b solid;

    &:before {
      border-bottom: 8px #fff solid;
      top: -7px;
      left: -8px;
    }
  }
}

.right {
  .icon{
    right: -16px;
    border-left: 8px #cccccc4b solid;

    &:before {
      right: -6px;
      top: -8px;
      border-left: 8px #fff solid;
    }
  }
}

.bottom {
  .icon{
    top: auto;
    bottom: -16px;
    border-top: 8px #cccccc4b solid;

    &:before {
      right: -8px;
      bottom: -6px;
      border-top: 8px #fff solid;
    }
  }
}
</style>
