import store from "@/store"

function hasPermission(pms) {
    if(pms === undefined) return true
    let status = store.state.user.isLogin;
    if(pms === "isLogin") return pms === "isLogin" && status
    if(store.state.user.userInfo === null) return false;
    let level = store.state.user.userInfo.level;
    return level === "Admin";
}

export default hasPermission;
