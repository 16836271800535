<template>
  <div class="navigation shadow" v-if="nodeTree.length">
    <svg-icon w="50" name="navigation" style="margin-bottom: 10px"/>
    <el-collapse v-model="activeName" accordion style="margin: 10px 0">
      <template v-for="(item,index) in nodeTree">
        <el-collapse-item :title="item.name" :name="index.toString()" v-if="item.children.length">
          <NavigationItem :nodes="item.children" @to="toLocation"></NavigationItem>
        </el-collapse-item>
        <div class="el-collapse-item__header" @click="toLocation(item.tagId)" style="border: none" v-else>{{
            item.name
          }}
        </div>
      </template>

    </el-collapse>
  </div>
</template>

<script>
import articleApi from "@/common/api/articleApi";
import NavigationItem from "@/components/aside/NavigationItem";

export default {
  name: "Navigation",
  components: {NavigationItem},
  data() {
    return {
      activeName: "0",
      nodeTree: []
    }
  },
  props: {
    articleId: {
      type: Number,
      default: null
    }
  },
  created() {
    articleApi.getArticleNavigation(this.articleId, res => {
      this.nodeTree = res;
      this.$emit("state", this.nodeTree.length > 0);
    });
  },
  methods: {
    toLocation(id) {
      let element = document.getElementById(id);
      let rect = element.getBoundingClientRect();
      let top = window.pageYOffset + rect.top;

      window.scrollTo({behavior: "smooth", top: top})
    }
  }
}
</script>

<style scoped lang="less">
.navigation {
  max-width: 275.52px;
  margin-left: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background: #fff;
  padding: 20px;

  /deep/ ul, li {
    text-align: start;
    cursor: pointer;
  }

  /deep/ li {
    &:hover {
      color: var(--main-green);
    }

    &:before {
      top: 0;
    }
  }
}

</style>
