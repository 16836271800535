import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home/Home.vue'
import ArticleDetailPage from "@/views/article/ArticleDetailPage";
import ArticleListPage from "@/views/list/ArticleListPage";
import SearchPage from "@/views/search/SearchPage";
import PersonalPage from "@/views/personal/PersonalPage";
import UserCenterPage from "@/views/user/UserCenterPage";
import Error404 from "@/views/notFund/404"
import Data from "@/views/personal/pages/Data";
import Article from "@/views/personal/pages/Article";
import Fans from "@/views/personal/pages/Fans";
import Focus from "@/views/personal/pages/Focus";
import BaseSettings from "@/views/user/pages/BaseSettings";
import Credit from "@/views/user/pages/Credit";
import Messages from "@/views/user/pages/Messages";
import Collections from "@/views/user/pages/Collections";
import MyArticle from "@/views/user/pages/MyArticle";
import MyFocus from "@/views/user/pages/MyFocus";
import MyFans from "@/views/user/pages/MyFans";
import ArticlePage from "@/views/common/ArticlePage";
import hasPermission from "@/router/permission";
import elmesg from "element-ui/packages/message/src/main";
import ActiveAccountPage from "@/views/common/ActiveAccountPage";
import Login from "@/views/user/Login";
import WebSettings from "@/views/user/pages/webmaster/WebSettings";
import Menu from "@/views/user/pages/webmaster/Menu";
import ArticleManage from "@/views/user/pages/webmaster/ArticleManage";
import UserManage from "@/views/user/pages/webmaster/UserManage";
import TO from "@/views/common/TO";
import TagPage from "@/views/list/TagPage";
import HotListPage from "@/views/list/HotListPage";
import VideoListPage from "@/views/list/VideoListPage";
import NewArticlePage from "@/views/list/NewArticlePage";
import ChangePassword from "@/views/user/ChangePassword";
import Info from "@/views/footer/Info";
import PromoteRegisterPage from "@/views/common/PromoteRegisterPage";
import ExamineArticlePage from "@/views/user/pages/webmaster/ExamineArticlePage";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: 'skills 的自留地，乐于分享、乐于折腾、',
        }
    },
    {
        path: '/examine/:articleId',
        name: 'Examine',
        component: ExamineArticlePage,
        meta: {
            permission: "isAdmin"
        },
    },
    {
        path: "/promote/:uid",
        name: "Promote",
        component: PromoteRegisterPage
    },
    {
        path: '/:article.html',
        name: "ArticleDetail",
        component: ArticleDetailPage,
    },
    {
        path: '/tag/:name',
        name: 'Tag',
        component: TagPage
    },
    {
        path: '/s/:name',
        name: 'Search',
        component: SearchPage
    },
    {
        path: '/article/:name',
        name: 'Article',
        component: ArticleListPage
    },
    {
        path: '/edit/:id',
        name: 'Edit',
        component: ArticlePage,
        meta: {
            permission: "isLogin"
        }
    },
    {
        path: '/new',
        name: 'New',
        component: ArticlePage,
        meta: {
            permission: "isLogin"
        }
    },
    {
        path: '/latest',
        name: 'Latest',
        component: NewArticlePage,
    },
    {
        path: '/hot',
        name: 'Hot',
        component: HotListPage
    },
    {
        path: '/video',
        name: 'Video',
        component: VideoListPage
    },
    {
        path: '/change/:token',
        name: 'Change',
        component: ChangePassword
    },
    {
        path: '/info/:type',
        name: 'Info',
        component: Info
    },
    {
        path: '/u/:id',
        name: 'Personal',
        component: PersonalPage,
        children: [
            {
                path: "data",
                name: "data",
                component: Data
            },
            {
                path: 'article',
                name: 'article',
                component: Article
            },
            {
                path: 'fans',
                name: 'fans',
                component: Fans,
            },
            {
                path: 'focus',
                name: 'focus',
                component: Focus
            },
            {
                path: "",
                name: "default",
                component: Data
            }
        ]
    },
    {
        path: '/user',
        name: 'UserCenter',
        component: UserCenterPage,
        meta: {
            permission: "isLogin"
        },
        children: [
            {
                path: 'base',
                name: "Base",
                component: BaseSettings,
                meta: {
                    permission: "isLogin"
                }
            },
            {
                path: 'credit',
                name: "Credit",
                component: Credit,
                meta: {
                    permission: "isLogin"
                }
            },
            {
                path: 'msg',
                name: "Message",
                component: Messages,
                meta: {
                    permission: "isLogin"
                }
            },
            {
                path: 'collection',
                name: "Collection",
                component: Collections,
                meta: {
                    permission: "isLogin"
                }
            },
            {
                path: 'article',
                name: "MyArticle",
                component: MyArticle,
                meta: {
                    permission: "isLogin"
                }
            },
            {
                path: 'focus',
                name: "MyFocus",
                component: MyFocus,
                meta: {
                    permission: "isLogin"
                }
            },
            {
                path: 'fans',
                name: "MyFans",
                component: MyFans,
            },
            {
                path: 'website',
                name: 'WebSettings',
                component: WebSettings,
                meta: {
                    permission: "isAdmin"
                },
            },
            {
                path: 'menu',
                name: 'Menu',
                component: Menu,
                meta: {
                    permission: "isAdmin"
                },
            },
            {
                path: 'article-manage',
                name: 'ArticleManage',
                component: ArticleManage,
                meta: {
                    permission: "isAdmin"
                },
            },
            {
                path: 'user-manage',
                name: 'UserManage',
                component: UserManage,
                meta: {
                    permission: "isAdmin"
                },
            },
            {
                path: '',
                name: "Base",
                component: BaseSettings,
                meta: {
                    permission: "isLogin"
                }
            },
        ]
    },
    {
        path: "/active/:token",
        name: "Active",
        component: ActiveAccountPage
    },
    {
        path: "/login/:token",
        name: "Active",
        component: Login
    },
    {
        path: "/to/:url",
        name: "TO",
        component: TO
    },
    // 404
    {
        path: '*',
        name: '404',
        component: Error404
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    const {permission} = to.meta;
    if (permission === undefined) {
        return next();
    }
    if (hasPermission(permission)) {
        return next();
    }
    next("/")
    elmesg.warning(permission !== "isAdmin" ? "请先登录~" : "无权访问~")
})

export default router
