<template>
  <div class="page-404">
    <div class="bg">
      <img src="../../assets/404-bg.png" >
      <img class="qiao" src="../../assets/404-bg-2.png" >
    </div>
    <div class="info">
      <h1>404</h1>
      <span>这里什么也米有~</span>
      <SLButton>去首页看看？({{s}}s)</SLButton>
    </div>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
export default {
  name: "Error404",
  data() {
    return {
      s: 6
    }
  },
  mounted() {
    let self = this;
    let t = setInterval(() =>{
      if(self.s === 0){
        clearInterval(t);
        this.$router.push("/")
      } else {
        --this.s;
      }
    }, 1000);
  },
  components: {SLButton}
}
</script>

<style scoped lang="less">
.page-404{
  width: 100vw;
  height: calc(100vh - 217px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .bg{
    position: relative;

    .qiao{
      transform-origin:  center center;
      animation: qiaoan 1s linear infinite;

      @keyframes qiaoan{
        0% {transform: rotate(-15deg)}
        40% {transform: rotate(25deg) translateX(10px)}
        100% {transform: rotate(-15deg)}
      }
    }

    img:nth-child(2){
      position: absolute;
      top: 0;
      right: 5px;
    }
  }

  .info{
    margin: 20px 0;
    span{
      display: block;
      margin: 20px 0;
    }
  }
}
</style>
