<template>
  <div id="app">
    <Headers v-if="$route.name !== 'Promote'"/>
    <transition name="toPage" mode="out-in" style="height:100vh">
      <router-view/>
    </transition>
    <OnTop v-if="$route.name !== 'Promote'"/>
    <SLFooter v-if="$route.name !== 'Promote'"/>
  </div>
</template>

<script type="text/javascript">
import Headers from "@/components/Headers";
import OnTop from "@/components/OnTop";
import SLFooter from "@/components/SLFooter";
import userApi from "@/common/api/userApi";
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import pushUtil from "@/common/utils/pushUtil";

let loadings;
export default {
  metaInfo: {
    title: 'Skill\'s 的自留地 | 乐于分享、乐于折腾、',
  },
  data() {
    return {
      transitionName: '',
      client: null,
      userSubscribe: false,
    }
  },
  beforeCreate() {
    loadings = this.$loading({spinner: "spinners"});
    let socket = new SockJS(this.$baseUrl + '/push',);
    let stompClient = Stomp.over(socket);
    this.client = stompClient;
    let headers = {
      Authorization: this.$store.state.user.token ? "SL " + this.$store.state.user.token : null
    }
    stompClient.connect(headers, function (frame) {
      stompClient.subscribe('/exchange/push_exchange/global', function (message) {
        try {
          let body = JSON.parse(message.body);
          pushUtil(body);
        } catch (e) {
          console.error("序列化出错，数据格式有误");
        }
      });
    });

  },
  mounted() {
    this.$nextTick(() => loadings.close());
    // this.onCopy();
    userApi.getUserInfo()
    this.pushMethods();
  },
  destroyed() {
    this.client.disconnect();
  },
  methods: {
    /**
     * 监听复制事件
     */
    // onCopy() {
    //   window.addEventListener("copy", (e) => {
    //     // clipboardData 对象是为通过编辑菜单、快捷菜单和快捷键执行的编辑操作所保留的，也就是你复制或者剪切内容
    //     let clipboardData = e.clipboardData || window.clipboardData;
    //     // 如果 未复制或者未剪切，直接 return
    //     if (!clipboardData) return;
    //     // Selection 对象 表示用户选择的文本范围或光标的当前位置。
    //     // 声明一个变量接收 -- 用户输入的剪切或者复制的文本转化为字符串
    //     let text = window.getSelection().toString();
    //     if (text) {
    //       // 如果文本存在，首先取消默认行为
    //       e.preventDefault();
    //       // 通过调用 clipboardData 对象的 setData(format,data) 方法，设置相关文本
    //       // format 一个 DOMString 类型 表示要添加到 drag object 的拖动数据的类型
    //       // data 一个 DOMString 表示要添加到 drag object 的数据
    //       clipboardData.setData('text/plain', text + `\r\n\n【 转载自 skill\'s 的自留地 】 原文地址: ${window.location.href}\n`)
    //     }
    //   });
    // },

    pushMethods() {
      if (this.$store.state.user.isLogin && !this.userSubscribe) {
        this.userSubscribe = true;
        let socket = SockJS(this.$baseUrl + "/push");
        let client = Stomp.over(socket);
        let headers = {
          Authorization: this.$store.state.user.token ? "SL " + this.$store.state.user.token : null
        }
        client.connect(headers, (frame) => {
          client.subscribe("/user/exchange/push_exchange/push_one", (message) => {
            console.log(message);
            let body = JSON.parse(message.body);
            pushUtil(body);
          })
        });
      }
    },

  },
  watch: {
    "$store.state.user.isLogin"() {
      this.pushMethods()
    }
  },
  components: {
    SLFooter,
    OnTop,
    Headers
  },
}
</script>

<style lang="less">
@import "//at.alicdn.com/t/font_2250277_10tpg42zfyd.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

body {
  overflow: hidden;
}

// 定义进入过渡生效时的状态。在整个进入过渡的阶段中应用，在元素被插入之前生效，在过渡/动画完成之后移除。
// 这个类可以被用来定义进入过渡的过程时间，延迟和曲线函数。
.toPage-enter-active {
  animation: bounce-left .3s ease-in;

  @keyframes bounce-left {
    0% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      opacity: 1;
      transform: scale(1)
    }
  }
}

// 定义进入过渡的结束状态。在元素被插入之后下一帧生效 (与此同时 v-enter 被移除)，在过渡/动画完成之后移除。
.toPage-enter-to {
  overflow-y: hidden;
}

// 定义离开过渡生效时的状态。在整个离开过渡的阶段中应用，在离开过渡被触发时立刻生效，
// 在过渡/动画完成之后移除。这个类可以被用来定义离开过渡的过程时间，延迟和曲线函数。
.toPage-leave-active {
  transform-origin: 21% 0;
  animation: lik .8s ease-in-out;

  @keyframes lik {
    30% {
      transform: rotate(13deg);
    }
    50% {
      transform: rotate(9deg);
    }
    60% {
      transform: rotate(13deg);
    }
    100% {
      transform: translateY(100%);
      opacity: 0;
    }
  }
}

// 定义离开过渡的结束状态。在离开过渡被触发之后下一帧生效 (与此同时 v-leave 被删除)，
// 在过渡/动画完成之后移除。
.toPage-leave-to {

}

.sl {
  font-size: 12px;
}

.el-image {
  height: 100%;
  width: 100%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.el-select-dropdown__list {
  min-width: 150px;
}

.el-select-dropdown__item {
  text-align: center;
}

a, i, section, button {
  outline: none !important;
}

a, .is-link {
  position: relative;
  color: var(--main-black);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(--main-green) !important;

    &:before {
      transform: scale(1);
      transition: transform .3s ease-in;
    }

  }

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: var(--main-green);
    transform: scale(0);
    transition: transform .3s ease-in-out;
  }
}

.el-select .el-input.is-focus .el-input__inner {
  border-color: var(--main-green) !important;
}

.el-dialog__headerbtn:focus .el-dialog__close, .el-dialog__headerbtn:hover .el-dialog__close {
  color: var(--main-green) !important;
}

img {
  border-radius: 2px;
}

.transparent {
  background-color: var(--main-transparent);
}

.el-tabs__active-bar {
  background-color: var(--main-green) !important
}

.gradient-mask {
  &:after {
    content: "";
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(to top, #00000047 1%, transparent);
  }
}

.shadow {
  box-shadow: 0 0 20px #cccccc80;
}

input {
  &:focus {
    border-color: var(--main-green) !important;
  }
}

.el-radio__input.is-checked + .el-radio__label {
  color: var(--main-green) !important;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: var(--main-green) !important;
  background: var(--main-green) !important;
}

.base {
  position: relative;
  width: calc(100% - 10px);

  h2 {
    position: relative;
    text-align: left;
    margin-left: 20px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -10px;
      height: 1px;
      width: calc(100% - 20px);
      background: #cccccc4b;
      z-index: 10;
    }
  }

  .base-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      flex: 1;
      margin-right: 20px;
      font-size: 14px;
    }

    .container {
      flex: 9;
      display: flex;
      flex-direction: row;

      .input {
        border: none;
        border-bottom: 1px solid #cccccc4b;
        outline: none;
        line-height: 30px;
        padding: 0 10px;
        width: 100%;
      }

      .frontCover {
        position: relative;
        width: 100%;
        height: 280px;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: -1px;
          width: 20px;
          height: 20px;
          cursor: pointer;
          background: url("assets/u-bg-right.png") no-repeat center center;
        }
      }
    }
  }
}
.el-main{
  overflow: hidden !important;
}
.el-tabs__item.is-active {
  color: var(--main-green) !important;
}

.el-tabs__item:hover {
  color: var(--main-green) !important;
}

.el-tabs__item {
  color: var(--main-black) !important
}

.el-pagination {
  button {
    &:hover {
      color: var(--main-green) !important;
    }

    &:disabled {
      &:hover {
        color: #ccc !important;
      }
    }
  }

  .el-pager {
    .active {
      color: var(--main-green);
    }

    .number {
      &:hover {
        color: var(--main-green);
      }
    }

    li {
      &:hover {
        color: var(--main-green);
      }
    }
  }
}

.el-tooltip__popper.is-light {
  border: 1px solid #cccccc4b !important;
}

.el-tooltip__popper .popper__arrow {
  border-bottom-color: #cccccc4b !important;
  border-top-color: #cccccc4b !important;
}

.el-select-dropdown__item.selected {
  color: var(--main-green) !important;
}

@media screen and(max-width: 768px) {
  .toPage-leave-active {
    transform-origin: -11% 0.1%;
  }
}

.max-z-index {
  z-index: 9999999 !important;
}

:root {
  /**
   * 全局颜色变量
   */
  --main-green: #67C23A;
  --main-black: #2c3e50;
  --main-red: #ff0000cc;
  --main-blue: #32c4ff;
  --main-transparent: rgba(0, 0, 0, 0.13);
  outline: none;

  ::selection {
    background-color: var(--main-green);
    color: #fff;
  }

  caret-color: var(--main-green);
}
</style>
