<template>
  <div class="box" v-if="closed">
    <!-- 登录 -->
    <div class="login" v-if="isLogin === 'login'" :class="className">
      <div class="qr" @click="onChange('qr')"><i class="sl icon-qr-code"></i></div>
      <div class="close" @click="onClose"><i class="el-icon-close"></i></div>
      <h2>登录</h2>
      <el-input class="input" type="text" placeholder="用户名" v-model="username" clearable/>
      <el-input class="input" placeholder="密码" show-password v-model="password" clearable
                @keyup.enter.native="onLogin"/>
      <SLButton class="btn" @click="onLogin" :loading="loading">登 录</SLButton>
      <div class="tips">
        <span @click="onChange('forget')">忘记密码？</span>
        <span @click="onChange('register')">没有账户？</span>
      </div>

      <el-divider><span style="color:#777; font-weight: 100">other</span></el-divider>
      <div class="other">
        <i class="sl icon-qq" @click="onOtherLogin('qq')"></i>
        <i class="sl icon-github" @click="onOtherLogin('github')"></i>
        <i @click="onOtherLogin('gitee')">
          <svg-icon name="gitee"></svg-icon>
        </i>
      </div>
    </div>

    <!-- 注册 -->
    <div class="register" v-if="isLogin === 'register'" :class="className">
      <div class="close" @click="onClose"><i class="el-icon-close"></i></div>
      <h2>注册</h2>
      <el-input class="input" type="text" placeholder="用户名" v-model="username" clearable/>
      <el-input class="input" type="text" placeholder="Email" v-model="email" clearable/>
      <el-input class="input" placeholder="输入密码" show-password v-model="password" clearable/>
      <el-input class="input" placeholder="再次输入密码" show-password v-model="passwordTow" clearable
                @keyup.enter.native="onRegister"/>
      <SLButton class="btn" @click="onRegister" :loading="loading">注 册</SLButton>
      <div class="tips">
        <span @click="onChange('login')">已有账户，点击登录</span>
      </div>
    </div>

    <!-- 二维码登录 -->
    <div class="qr-login" v-if="isLogin === 'qr'" :class="className">
      <div class="close" @click="onClose"><i class="el-icon-close"></i></div>
      <h2>扫码登录</h2>
      <span>打开App，扫一扫</span>
      <el-image src="https://img.tukuppt.com//ad_preview/00/23/97/5c9a676eb3caf.jpg!/fw/980" class="qrcode"
                fit="cover"/>
      <div class="tips">
        <span @click="onChange('login')">密码登录 <i class="el-icon-right"></i></span>
      </div>
    </div>

    <!-- 忘记密码 -->
    <div class="forget login" v-if="isLogin === 'forget'" :class="className">
      <div class="close" @click="onClose"><i class="el-icon-close"></i></div>
      <h2>忘记密码</h2>
      <p><i class="el-icon-info"></i> 系统将下发一封包含修改密码的链接到您邮箱，请注意查收.</p>
      <el-input class="input" type="text" placeholder="注册时的邮箱或用户名" v-model="email" clearable @keyup.enter.native="onRestPw"/>

      <SLButton class="btn" @click="onRestPw" :loading="restLoading">找 回</SLButton>

      <el-divider><span style="color:#777; font-weight: 100">other</span></el-divider>
      <div class="other">
        <i class="sl icon-qq" @click="onOtherLogin('qq')"></i>
        <i class="sl icon-github" @click="onOtherLogin('github')"></i>
        <i @click="onOtherLogin('gitee')">
          <svg-icon name="gitee"></svg-icon>
        </i>
      </div>
    </div>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import userApi from "@/common/api/userApi";
import {mapMutations} from "vuex";
import Message from "element-ui/packages/message/src/main";

export default {
  name: "LoginBox",
  components: {SLButton},
  data() {
    return {
      username: '',
      password: '',
      passwordTow: '',
      email: '',
      isLogin: 'login',  //  forget 忘记密码
      className: '',
      closed: false,
      loading: false,
      restLoading: false
    }
  },
  created() {
    this.setLoginBox(this)
  },
  methods: {
    ...mapMutations({
      setLoginBox: "user/setLoginBox",
    }),
    onChange(to) {
      this.className = "transition";
      setTimeout(() => {
        this.isLogin = to;
        this.className = '';
        this.password = '';
        this.username = '';
      }, 500)
    },
    onClose() {
      this.className = "closed";
      setTimeout(() => {
        this.closed = false;
        this.className = "";
        this.isLogin = 'login';
      }, 500)
    },
    show() {
      this.closed = true;
      this.className = 'showAn';
      setTimeout(() => {
        this.className = '';
      }, 500)
    },
    // 登录
    onLogin() {
      this.loading = true;
      userApi.login(this.username, this.password, (e) => {
        this.loading = false;
        if (e)
          setTimeout(() => {
            this.onClose();
            this.password = "";
            this.username = "";
          }, 500)
      });
    },

    /**
     * 注册
     */
    onRegister() {
      if (this.username.trim().length > 16 || this.username.trim().length < 5) {
        this.$message({message: "用户名长度必须大于5个字符且小于16~", type: "warning", customClass: "max-z-index"})
        return;
      }
      let regex = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
      if (regex.exec(this.username) != null) {
        this.$message({message: "用户名不能包含中文~", type: "warning", customClass: "max-z-index"})
        return;
      }
      let emailReg = /.*@.*\..*/gi
      if (!emailReg.exec(this.email)) {
        this.$message({message: "邮箱格式不正确~", type: "warning", customClass: "max-z-index"})
        return;
      }
      if (regex.exec(this.password) != null) {
        this.$message({message: "密码不能包含中文~", type: "warning", customClass: "max-z-index"})
        return;
      }
      let pwReg = /[0-9]+/gi;
      let raw = pwReg.exec(this.password.trim())
      if (raw.length > 0 && raw[0].length === this.password.trim().length) {
        this.$message({message: "密码不能全部数字~", type: "warning", customClass: "max-z-index"})
        return;
      }

      if (this.password.trim().length > 20 || this.password.trim().length < 6) {
        this.$message({message: "密码长度需在（6 - 20）之间~", type: "warning", customClass: "max-z-index"})
        return;
      }
      if (this.password.trim() !== this.passwordTow.trim()) {
        this.$message({message: "两次输入的密码不一致~", type: "warning", customClass: "max-z-index"})
        return;
      }

      this.loading = true;
      userApi.register(this.username.trim(), this.password.trim(), this.email.trim(), (e) => {
        if (e) {
          // 注册成功 去激活账户
          setTimeout(() => this.onChange("login"), 1000)
        }
        this.loading = false;
      })
    },

    onOtherLogin(e) {
      if (e === 'github') {
        window.location.href = "https://github.com/login/oauth/authorize?client_id=7d98b270fd107f2d8ada&scope=user:email,read:user&state=1609385632767&sallow_signup=true";
      }
      if (e === "qq") {
        window.location.href = "https://graph.qq.com/oauth2.0/authorize?response_type=code&redirect_uri=https%3A%2F%2Fapi.solove.love%2Foauth%2Fqq&state=c2tpbGxz&client_id=101926579&scope=get_user_info"
      }
      if (e === "gitee") {
        window.location.href = "https://gitee.com/oauth/authorize?client_id=0642827c2cc3a902cc065756b30e02c150fa1d8ae8f51675def9c6664c0ed007&redirect_uri="+ this.$baseUrl +"/oauth/gitee&response_type=code&scope=user_info%20emails";
      }
    },

    onRestPw(){
      let reg = /.*@.*\.\w+/
      if (!reg.test(this.email)){
        this.email = ""
        return this.$message({message: "Email格式不正确~", type: "warning", customClass: "max-z-index"})
      }
      this.restLoading = true;
      userApi.restPassword(this.email, res => {
        if (res){
          this.$message({message: "系统已经下发一封包含修改密码的链接到您邮箱，请注意查收~", type: "success", customClass: "max-z-index"})
          this.closed = false;
        }
        this.restLoading = false;
      })
    }
  }
}
</script>

<style scoped lang="less">
.box {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #101d16e3;
  z-index: 99999;

  .showAn {
    animation: da .5s linear;
    @keyframes da {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1)
      }
    }
  }

  .login, .register, .qr-login, .forget {
    position: relative;
    padding: 20px;
    overflow: hidden;
    width: 350px;
    background-color: #fff;
    border-radius: 4px;
    height: 400px;

    .input {
      margin-bottom: 20px;
    }

    .btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      height: 45px;
    }

    .tips {
      font-size: 12px;
      margin: 30px 0;
      color: #777;
      cursor: pointer;
    }

    .close {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
    }
  }

  .login {
    .other {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      i {
        font-size: 24px;
        color: #fff;
        cursor: pointer;
      }

      i:nth-child(1) {
        border-radius: 50%;
        padding: 4px;
        background: #0896de;
        margin-right: 20px;
      }

      i:nth-child(2) {
        color: var(--main-black);
        font-size: 32px;
        margin-right: 20px;
      }

      i:nth-child(3) {
        width: 32px;
        height: 32px;
      }
    }

    .qr, .close {
      position: absolute;
      font-size: 20px;
      color: #777;
    }

    .qr {
      left: 0;
      top: 0;
      background-color: #cccccc4b;
      padding: 2px 5px;
      border-top-left-radius: 4px;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        left: 9px;
        top: 9px;
        border: 23px solid transparent;
        border-left: 23px #fff solid;
        transform: rotate(45deg);
      }

      i {
        font-size: 20px;
      }
    }
  }

  .register {
    .tips {
      margin-top: 25px;
    }

    .input {
      margin-bottom: 15px;
    }
  }

  .qr-login {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .qrcode {
      width: 200px;
      height: 200px;
      margin-top: 40px;
    }

    span {
      margin-top: 5px;
      font-size: 14px;
      color: #777;
    }
  }

  .forget {
    p {
      padding: 20px;
      font-size: 12px;
      color: #777;
    }

    .btn {
      margin-bottom: 40px;
      margin-top: 15px;
    }
  }
}

.transition {
  animation: tan .5s linear !important;

  @keyframes tan {
    0% {
      transform: rotateY(0);
    }
    100% {
      transform: rotateY(180deg) rotateZ(1deg);
      opacity: 0;
    }
  }
}


.hide {
  display: none !important;
}

.closed {
  animation: closeAn .5s linear !important;

  @keyframes closeAn {
    0% {
      transform: scaleY(1)
    }
    100% {
      transform: scaleY(0)
    }
  }
}
</style>
