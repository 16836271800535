<template>
  <el-row>
    <el-col>
      <div class="search-bg">
        <span><i class="sl" :class="className" style="font-size:20px"></i> {{ result.deliver }}</span>
      </div>
    </el-col>
    <el-col :md="{offset: 4, span: 16}" class="sl-tag-wrapper">
      <div class="header">
        <SLButton :disabled="currentIndex === index" v-for="(item, index) in category" :key="index"
                  @click="btnChange(index)">{{ item }}
        </SLButton>
      </div>
      <el-row :gutter="20" style="min-height: calc(100vh - 468px)">
        <Loading height="calc(100vh - 468px)" :loading="loading" :data="result.list">
          <el-col :md="6" v-for="(item, index) in result.list" :key="index" class="item-article"
                  @click.native="onClickDetails(item.staticUrl)">
            <div class="shadow">
              <el-image :src="getArticleImageUrl(item.image) + '?s=410x240'" style="height: 240px" fit="cover"/>
              <div style="padding: 0 5px">
                <SLText>{{ item.title }}</SLText>
                <div class="info">
            <span>
              <Avatar :size="20" :lighten="10000" style="display: inline-block;vertical-align:middle"
                      :username="item.author.name"
                      :src="getArticleImageUrl(item.author.avatar)"></Avatar>
              <span> {{ item.author.name }}</span>
            </span>
                  <span>{{ item.date }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </Loading>

      </el-row>

      <el-col class="limit-page">
        <el-pagination class="page" :page-size="12" :total="result.total"
                       :current-page="result.current" :hide-on-single-page="true"
                       layout="prev, pager, next"
                       @current-change="onChangePage"
        ></el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>

<script>
import SLButton from "@/components/SLButton";
import SLText from "@/components/SLText";
import articleApi from "@/common/api/articleApi";
import Avatar from "vue-avatar"
import Loading from "@/components/Loading";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "ArticleListPage",
  data() {
    return {
      category: ["最新发布", "最近更新", "最多浏览", "最多收藏"],
      currentIndex: 0,
      type: "",
      result: {},
      currentPage: 1,
      loading: false,
      order: "Newest"
    }
  },
  mounted() {
    this.type = this.$route.name;
    if (this.type === "Article") {
      let cid = this.$route.params.name;
      if (isNaN(cid)) return;
      this.loading = true;
      articleApi.getCategoryArticle(this.$route.params.name, this.currentPage, 12, this.order, res => {
        this.result = res;
        this.loading = false
      })
    }

  },
  methods: {
    getArticleImageUrl(url) {
      return getUrl(url)
    },
    btnChange(index) {
      this.currentIndex = index;
      switch (index) {
        case 0:
          this.order = "Newest";
          break;
        case 1:
          this.order = "Recent";
          break;
        case 2:
          this.order = "MostView";
          break;
        case 3:
          this.order = "Collect";
          break;
      }
      if (this.type === "Article") {
        let cid = this.$route.params.name;
        if (isNaN(cid)) return;
        this.loading = true;
        articleApi.getCategoryArticle(this.$route.params.name, this.currentPage, 12, this.order, res => {
          this.result = res;
          this.loading = false
        })
      }
    },
    onChangePage(e) {
      if (this.type === "Article") {
        let cid = this.$route.params.name;
        if (isNaN(cid)) return;
        this.loading = true;
        articleApi.getCategoryArticle(this.$route.params.name, this.currentPage, 12, this.order, res => {
          this.result = res;
          this.loading = false
        })
      }
    },
    onClickDetails(url) {
      this.$router.push(url)
    }
  },
  components: {SLText, SLButton, Avatar, Loading},
  computed: {
    className() {
      return this.type === 'Tag' ? "icon-tag" : "icon-menu"
    },
  },
  watch: {
    "$route.params.name"() {
      this.loading = true;
      articleApi.getCategoryArticle(this.$route.params.name, this.currentPage, 12, this.order, res => {
        this.result = res;
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="less">
.sl-tag-wrapper {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .item-article {
    cursor: pointer;

    .info {
      font-size: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    span {
      vertical-align: middle;
      color: #777;
    }

    .shadow {
      margin-bottom: 20px;
      transition: .2s linear;

      &:hover {
        box-shadow: 0 0 20px #cccccc;
        transform: scale(1.02);
        transition: .2s linear;
      }
    }
  }
}

.search-bg {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/tag-page-bg.png') no-repeat;
  background-size: cover;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--main-transparent);
    z-index: 10;
  }

  span {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    z-index: 11;
  }
}

.limit-page {
  margin: 20px 0;
}

</style>

<style lang="less">
.page {
  .el-pager {
    .active {
      color: var(--main-green)
    }

    li {
      transition: .2s linear;

      &:hover {
        color: var(--main-green);
        transition: .2s linear;
      }
    }
  }

  button {
    transition: .2s linear;

    &:hover {
      color: var(--main-green);
      transition: .2s linear;
    }
  }
}
</style>
