<template>
  <div class="replyBox">
    <el-dialog
        :visible.sync="isShow"
        width="30%"
        center>
      <div slot="title">
        <i class="sl icon-aite" style="font-size:16px"> {{ parent.user.nickname }}</i>
      </div>

      <el-input placeholder="说点什么吧~" v-model="replyContent">
        <div slot="prepend">
          <Emoji @change="onChange"/>
        </div>
      </el-input>

      <span slot="footer" class="dialog-footer">
        <el-button @click="isShow = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="onReply">回 复</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Emoji from "@/views/article/components/Emoji";
import commentApi from "@/common/api/commentApi";
import {mapState} from "vuex";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "ReplyBox",
  components: {Emoji},
  data() {
    return {
      replyContent: '',
      isShow: false,
      loading: false,
    }
  },
  props: {
    parent: {
      type: Object,
      default: () => {
      }
    },
    articleId: {
      type: Number,
      default: null
    },
    parentId: {
      type: Number,
      default: null,
    }
  },
  methods: {
    onChange(e) {
      this.replyContent += e;
    },
    show() {
      this.isShow = true
    },
    onReply() {
      if (!this.isLogin) {
        return this.loginBox.show();
      }
      this.loading = true;
      let data = {
        userId: this.userInfo.id,
        articleId: this.articleId,
        content: this.replyContent,
        replyId: this.parent.id,
        parentId: this.parentId || this.parent.id
      }
      commentApi.submitComment(data, (state) => {
        console.log(state);
        if (state) {
          this.isShow = false;
          let replyData = {
            content: this.replyContent,
            date: "刚刚",
            praise: 0,
            user: {
              username: this.userInfo.username,
              nickname: this.userInfo.nickname || this.userInfo.email || this.userInfo.username,
              avatar: getUrl(this.userInfo.avatar)
            },
            parent: this.parent.user
          }
          this.replyContent = "";
          this.$emit("reply", replyData);
        }
        this.loading = false;
      })
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      isLogin: state => state.user.isLogin,
      loginBox: state => state.user.loginBox
    })
  }
}
</script>

<style scoped lang="less">

</style>
