<template>
  <Loading :loading="loading" height="calc(100vh - 572px)" :data="result.list">
    <el-row :gutter="10">
      <el-col :md="6" v-for="(item,index) in result.list" :key="index">
        <div class="user-info"
             :style="{'background-image': 'url(' + getBgImage(item.bgImage) + ')'}">
          <div class="wrapper">
            <Avatar class="avatar"
                    :src="getAvatarUrl(item.avatar)"
                    :username="item.username"
                    :lighten="1000"
                    :size="50"/>
            <span>{{ item.nickname }}</span>
            <span>{{ item.description || '这家伙很懒、什么也没留下、' }}</span>
            <div class="btn-group">
              <SLButton @click="onFocusUser(item.id)" width="81.56" :loading="focusBtnLoading"><i class="sl"
                                                                                                  :class="focused ? 'icon-focus-on' : 'icon-focus'">
                {{ focused ? '已关注' : '关注' }}</i></SLButton>
              <SLButton class="btn" @click="privateMessageBox = true;uid=item.id"><i class="sl icon-email"> 私信</i></SLButton>
              <el-tooltip effect="light" placement="bottom">
                <SLButton class="btn"><i class="sl icon-menu"></i></SLButton>
                <div slot="content" class="menu">
                  <a :href="'/u/'+ item.id + '/data'">Ta的主页</a>
                  <a :href="'/u/'+ item.id + '/article'">Ta的文章</a>
                </div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </el-col>
      <el-col style="margin-top:20px">
        <el-pagination :current-page="page" :page-size="12" :total="result.total" layout="prev, pager, next"
                       :hide-on-single-page="true" @current-change="onPage"></el-pagination>
      </el-col>
    </el-row>
    <el-dialog
        :visible.sync="privateMessageBox"
        width="50%"
        center>
      <template slot="title">私信</template>
      <textarea maxlength="255" v-model="msg" class="content-area" placeholder="说点什么呢？"></textarea>
      <template slot="footer">
        <div class="btn-group">
          <SLButton @click="privateMessageBox = false" style="margin-right: 20px" width="100">取 消</SLButton>
          <SLButton :loading="sendMsgLoading" width="100" @click="sendMessage"><i class="el-icon-s-promotion"
                                                                                  style="font-size: 14px"></i>
          </SLButton>
        </div>
      </template>
    </el-dialog>
  </Loading>
</template>

<script>
import SLButton from "@/components/SLButton";
import Loading from "@/components/Loading";
import userPersonalApi from "@/common/api/userPersonalApi";
import Avatar from "vue-avatar"
import getUrl from "@/common/utils/getUrl";

export default {
  name: "Fans",
  data() {
    return {
      loading: true,
      result: {},
      page: 1,
      privateMessageBox: false,
      msg:"",
      sendMsgLoading: false,
      focusBtnLoading: false,
      focused: false,
      uid: null
    }
  },
  mounted() {
    console.log(this.$route.params.id)
    userPersonalApi.getFocusList(this.$route.params.id, this.page, res => {
      this.loading = false;
      this.result = res;
    })
  },
  methods: {
    getAvatarUrl(url) {
      return getUrl(url);
    },
    onPage(e){
      this.page = e;
      this.loading = true;
      userPersonalApi.getFocusList(this.$route.params.id, this.page, res =>{
        this.loading = false;
        this.result = res;
      })
    },
    getBgImage(url){
      if (url)
        return getUrl(url);
      return require('@/assets/user-default-bg.jpeg');
    },
    onFocusUser(uid) {
      this.focusBtnLoading = true;
      userPersonalApi.focusPersonalOrCancel(uid, !this.focused, state => {
        this.focusBtnLoading = false;
        if (state) {
          this.focused = !this.focused;
          if (this.focused) {
            this.$message.success("谢谢您的关注~")
          } else {
            this.$message.success("关注已取消~")
          }
        }
      })
    },
    sendMessage() {
      if (this.msg.trim().length < 10) {
        return this.$message.warning("内容不能少于10个字符哦~")
      }
      this.sendMsgLoading = true;
      userPersonalApi.sendMessage(this.uid, this.msg.trim(), state => {
        this.sendMsgLoading = false;
        if (state) {
          this.privateMessageBox = false;
          this.$message.success("私信发送成功~");
          this.msg = "";
        }
      })
    }
  },
  components: {Loading, SLButton, Avatar}
}
</script>

<style scoped lang="less">
.user-info {
  position: relative;
  height: 220px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin-bottom: 10px;
  border-radius: 5px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    background-color: #00000017;
    z-index: 1;
  }

  .wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;

    .avatar {
      border: 3px #ffffff solid;
    }

    span {
      color: #fff;
    }

    span:nth-child(2) {
      margin: 8px 0;
    }

    span:nth-child(3) {
      font-size: 12px;
    }

    .btn-group {
      display: flex;
      flex-direction: row;
      margin-top: 20px;

      .btn {
        padding: 4px 8px;
      }

      button:nth-child(1), button:nth-child(2) {
        margin-right: 10px;
      }

      button:nth-child(3) {
        padding: 4px 15px;
      }
    }
  }
}
.content-area {
  outline: none;
  resize: none;
  overflow: hidden;
  font-size: 14px;
  padding: 20px 30px;
  height: 100px;
  width: calc(100% - 62px);
  color: var(--main-black);
  border: 1px #cccccc4b solid;
  border-radius: 8px;
}
.btn-group{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>
<style lang="less">
.menu {
  display: flex;
  flex-direction: column;

  a {
    display: block;
    margin: 5px;
    color: var(--main-black)
  }
}
</style>
