import {hasRes, request} from "@/common/request";
import Message from "element-ui/packages/message/src/main";

const commentApi = {
    /**
     * 提交评论数据
     * @param data 数据
     * @param callback 回调
     */
    submitComment(data, callback) {
        request({
            url: "/article/comment",
            method: "post",
            data: data,
            success: res => {
                callback(hasRes(res));
                if (hasRes(res)) {
                    return Message.success(res.message);
                }
                Message.warning(res.message);
            }
        })
    },

    /**
     * 获取所有评论
     * @param aid 文章id
     * @param callback
     */
    getCommentAll(aid, callback) {
        request({
            url: "/article/comment/all?aid=" + aid,
            method: "get",
            success: res => {
                if (hasRes(res)) {
                    return callback(res.result);
                }
                return callback([]);
            }
        })
    },

    /**
     * 赞文章评论
     * @param cid 评论id
     * @param callback
     */
    praiseComment(cid, callback) {
        request({
            url: "/article/comment/praise",
            method: "POST",
            data: {cid},
            success: res => {
                if (hasRes(res)){
                    return callback();
                }
                Message.warning(res.message);
            }
        })
    }
}

export default commentApi;
