<template>

  <el-row>
    <Loading :loading="loading" :data="articleResult" :offset="{top: '10px'}" height="calc(100vh - 217px)">
      <el-col :md="{span: 16, offset: 4}" style="padding: 0 5px">
        <el-col class="sl-breadcrumb">
          <el-row>
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>文章</el-breadcrumb-item>
              <el-breadcrumb-item>正文</el-breadcrumb-item>
            </el-breadcrumb>
          </el-row>
        </el-col>
        <el-row class="article-title" v-if="articleResult">
          <h1>{{ articleResult.title }}</h1>
          <span class="desc">{{ articleResult.description }}</span>
          <div class="user-info">
            <Avatar :size="20" style="display:inline-block;vertical-align: middle;" :lighten="10000"
                    :username="articleResult.author.name || 'D'" :src="authorAvatar"/>
            <span> {{ articleResult.author.name || '' }}</span>
            <span>发布于</span>
            <span>{{ articleResult.date }}</span>
            <span><i class="sl icon-hot"></i> {{ views }}</span>
            <span v-if="userInfo && userInfo.level === 'Admin'" @click="$router.push('/edit/'+ articleResult.id)"><i
                class="sl icon-edit"></i> 编辑</span>
          </div>
        </el-row>

        <el-container>
          <el-main style="padding: 0;">
            <el-row v-if="articleResult">
              <el-col class="article-content">
                <!-- 详情页内容 -->
                <div id="details" class="articleContent" v-html="articleResult.content" v-hljs></div>
                <!-- 隐藏区域  类型 富文本 下载链接 -->
                <HiddenArea :articleId="articleResult.id" v-if="articleResult.status.length" @reply="hiddenReply"/>
                <Download :list-data="articleResult.downloads"></Download>
                <!-- 赏 -->
                <el-row v-if="articleResult.author.alipayGathering && articleResult.author.weixinGathering">
                  <el-col class="reward-title">
                    <span>「 跪求打赏 」</span>
                  </el-col>
                  <el-col class="reward">
                    <el-popover placement="top" trigger="hover">
                      <div class="reward-btn" slot="reference">赏</div>
                      <div class="reward-qr-code">
                        <span v-if="articleResult.author.weixinGathering">
                          <el-image :src="$baseUrl + articleResult.author.weixinGathering" style="width: 150px;"/>
                        </span>
                        <span v-if="articleResult.author.alipayGathering">
                          <el-image :src="$baseUrl + articleResult.author.alipayGathering" style="width: 150px;"/>
                        </span>
                      </div>
                    </el-popover>
                  </el-col>
                </el-row>

                <!-- 版权 -->
                <el-row>
                  <el-col class="article-copyright">
                    <i class="sl icon-copyright"></i>
                    <span>
               除特别注明外，本站所有文章均为 <a href="/" style="color:var(--main-green)">skill's</a> 原创，转载请注明出处来自 <a
                        style="color:var(--main-green)" :href="localUrl">{{ localUrl }}</a>
            </span>
                  </el-col>
                </el-row>

                <!-- 文章标签 -->
                <el-row v-if="articleResult.tags.length">
                  <el-col class="article-tags">
                    <i class="sl icon-tag"></i>
                    <template v-for="(item,index) in articleResult.tags">
                      <SLTag @click.native="toTagPage(item.name)">{{ item.name }}</SLTag>
                    </template>
                  </el-col>
                </el-row>

                <!-- 相关文章 -->
                <el-row class="interrelated" :gutter="10" v-if="articleResult.related.length">
                  <el-col>
                    <div class="sl-home-title">
                      <h4>相关文章</h4>
                    </div>
                  </el-col>
                  <el-col class="item gradient-mask" :md="8" v-for="(item, index) in articleResult.related"
                          :key="index" @click.native="$router.push(item.staticUrl)">
                    <div class="article-info">
                      <SLText color="white">{{ item.title }}</SLText>
                      <div class="info">
                        <span>{{ item.date }}</span>
                        <i class="sl icon-pinglun"> {{ item.comment }}</i>
                      </div>
                    </div>
                    <el-image style="transition: .2s ease;" :src="theUrl(item.image) + '?s=410x240'" fit="cover"/>
                  </el-col>
                </el-row>

                <!-- 评论 -->
                <el-row>
                  <el-col>
                    <div class="sl-home-title">
                      <h4>评论</h4>
                    </div>
                  </el-col>
                  <el-col>
                    <!-- 输入区域 -->
                    <div class="submit-comment">
                      <textarea v-model="content" placeholder="说点什么吧" maxlength="1000"/>

                      <div class="not-login" v-if="!isLogin">
                        <SLButton @click="loginBox.show">立即登录</SLButton>
                      </div>
                      <div class="submit-btn-emoji">
                        <Emoji @change="emojiChange"/>
                        <SLButton class="submit-btn" :disabled="!isLogin" :loading="submitCommentLoading"
                                  @click="onSubmitComment">回复
                        </SLButton>
                      </div>
                    </div>
                    <Loading :loading="commentLoading" height="300px" :data="comments" style="margin-bottom: 20px">
                      <Comment :comment-data="item" :article-id="articleResult.id" v-for="(item,index) in comments"
                               :key="index"/>
                      <span slot="tips">快来抢沙发吧~</span>
                    </Loading><!-- 列表 -->
                  </el-col>
                </el-row>

              </el-col>
            </el-row>
          </el-main>
          <el-aside width="360px" class="hidden-xs-only">
            <el-row style="padding-top: 20px" v-if="articleResult">
              <el-col>
                <WebmasterAside style="width: 316px"/>
                <AuthorAside :data="articleResult.author" v-if="articleResult.author"/>
                <FixedArea style="z-index:10" top="10" v-if="state">
                  <Navigation :articleId="articleResult.id" @state="onState"></Navigation>
                </FixedArea>
                <template v-if="!state">
                  <WealthAside style="width: 315px"/>
                </template>
              </el-col>
            </el-row>
          </el-aside>
        </el-container>
      </el-col>
      <LeftShareAside :data="articleResult"/>
    </Loading>
  </el-row>
</template>

<script>
import WealthAside from "@/components/aside/WealthAside";
import WebmasterAside from "@/components/aside/WebmasterAside";
import LeftShareAside from "@/views/article/components/LeftShareAside";
import AuthorAside from "@/components/aside/AuthorAside";
import SLTag from "@/components/SLTag";
import SLText from "@/components/SLText";
import HiddenArea from "@/views/article/components/HiddenArea";
import Comment from "@/views/article/components/Comment";
import SLButton from "@/components/SLButton";
import Avatar from "vue-avatar";
import Emoji from "@/views/article/components/Emoji";
import articleApi from "@/common/api/articleApi";
import Loading from "@/components/Loading";
import {mapState} from "vuex";
import commentApi from "@/common/api/commentApi";
import Download from "@/views/article/components/Download";
import Navigation from "@/components/aside/Navigation";
import FixedArea from "@/components/FixedArea";
import getUrl from "@/common/utils/getUrl";

export default {
  name: "ArticleDetailPage",
  data() {
    return {
      localUrl: window.location.href,
      hidden: true,
      content: "", // 评论输入框
      articleResult: null,
      loading: true,
      submitCommentLoading: false,
      comments: [],
      commentLoading: false,
      state: true
    }
  },
  methods: {
    emojiChange(e) {
      this.content += e
    },
    onState(s) {
      this.state = s;
    },
    appendHighlightCore() {
      let head = document.head || document.getElementsByTagName('head')[0];
      let script = document.createElement('script');
      script.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/10.5.0/highlight.min.js");
      head.appendChild(script);
    },
    onSubmitComment(replyId, replyUserId) {
      this.submitCommentLoading = true;
      let data = {
        userId: this.userInfo.id,
        articleId: this.articleResult.id,
        content: this.content,
        replyId: replyId || null,
        replyUserId: replyUserId || null
      }
      commentApi.submitComment(data, state => {
        this.submitCommentLoading = false;
        // 本地评论列表更新数据
        let replyData = {
          content: this.content,
          date: "刚刚",
          praise: 0,
          user: {
            username: this.userInfo.username,
            nickname: this.userInfo.nickname || this.userInfo.email || this.userInfo.username,
            avatar: getUrl(this.userInfo.avatar)
          },
          reply: []
        }
        if (state) {
          this.comments.unshift(replyData);
          this.content = '';
        }
      })
    },
    hiddenReply(data) {
      console.log(data)
      this.comments.unshift(data);
    },
    toTagPage(name) {
      console.log(name)
      this.$router.push({name: 'Tag', params: {name: name}})
    },
    theUrl(url) {
      return getUrl(url);
    }
  },
  mounted() {
    this.appendHighlightCore();
    window.scrollTo({top: 0});
    this.commentLoading = true;
    let url = this.$route.params.article + '.html';
    articleApi.getArticleDetails(url, res => {
      this.articleResult = res;
      this.loading = false;
      commentApi.getCommentAll(res.id, res => {
        this.commentLoading = false;
        this.comments = res;
      });
    });
  },
  components: {
    FixedArea,
    Navigation,
    Download,
    Loading,
    Emoji, Avatar,
    SLButton, Comment, HiddenArea, SLText, SLTag, AuthorAside, LeftShareAside, WebmasterAside, WealthAside
  },
  computed: {
    views() {
      let view = this.articleResult.view;
      if (view >= 1000) {
        return `${view / 1000}k`;
      }
      if (view >= 10000) {
        return `${view / 10000}w`
      }
      return view;
    },
    ...mapState({
      isLogin: state => state.user.isLogin,
      loginBox: state => state.user.loginBox,
      userInfo: state => state.user.userInfo
    }),
    authorAvatar() {
      let reg = /http(s)?:\/\/.*/g;
      if (reg.test(this.articleResult.author.avatar)) {
        return this.articleResult.author.avatar;
      }
      return this.$baseUrl + this.articleResult.author.avatar;
    },
  },
  // watch:{
  //   "$router.params.url"(){
  //     // window.scrollTo({top: 0});
  //     // this.commentLoading = true;
  //     // let url = this.$route.params.article + '.html';
  //     // articleApi.getArticleDetails(url, res => {
  //     //   this.articleResult = res;
  //     //   this.loading = false;
  //     //   commentApi.getCommentAll(res.id, res => {
  //     //     this.commentLoading = false;
  //     //     this.comments = res;
  //     //   });
  //     // });
  //     // console.log(this.$route.params.url)
  //   // }
  // }
  beforeRouteUpdate(to, from, next) {

    if (to.name === from.name) {
      console.log(to, from)
        window.scrollTo({top: 0});
        this.commentLoading = true;
        this.loading = true;
        let url = this.$route.params.article + '.html';
        articleApi.getArticleDetails(url, res => {
          this.articleResult = res;
          this.loading = false;
          commentApi.getCommentAll(res.id, res => {
            this.commentLoading = false;
            this.comments = res;
          });
        });
      return;
    }
    next();
  },
}
</script>
<style>
.hljs {
  padding: 1em !important;
}
</style>
<style scoped lang="less">
@import "https://cdnjs.cloudflare.com/ajax/libs/highlight.js/10.5.0/styles/atom-one-light.min.css";

#details{
  /deep/ img{
    margin: 0 auto;
    display: block;
  }
}

.sl-breadcrumb {
  margin-top: 50px;
}

.articleContent {
  width: 100%;
}

.submit-btn-emoji {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.article-title {

  .desc {
    display: block;
    margin: 20px 0;
  }

  h1 {
    margin-bottom: 10px;
    margin-top: 2.5em;
  }

  span {
    color: #777;
    font-size: 12px;
  }

  .user-info {
    margin: 10px 0;

    span {
      vertical-align: middle;
      margin-right: 10px;
    }

    span:nth-child(2) {
      font-weight: 500;
    }

    span:nth-child(5) {
      i {
        color: pink;
      }
    }

    span:nth-child(6) {
      color: orange;
      cursor: pointer;
    }
  }
}

/**
 * 文章详情样式
 */
.article-content {
  position: relative;
  text-align: left;
  min-height: 100vh;

  img {
    width: 100%;
    object-fit: cover;
  }

  /deep/ a {
    color: var(--main-green) !important;
    font-style: italic;
  }
}

/deep/ ul > li {
  position: relative;
  list-style: none;

  &:before {
    content: "\e602";
    font-family: 'sl', serif;
    font-size: 14px;
    position: absolute;
    top: 3px;
    left: -27px;
  }
}

.submit-comment {
  position: relative;
  overflow: hidden;

  textarea {
    position: relative;
    outline: none;
    resize: none;
    overflow: hidden;
    font-size: 14px;
    padding: 20px 30px;
    height: 100px;
    width: calc(100% - 62px);
    color: var(--main-black);
    border: 1px #cccccc4b solid;
    border-radius: 8px;
  }

  .submit-btn {
    color: white;
    width: 100px;
    background: var(--main-green);
  }

  .not-login {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffffa8;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
  }
}

.reward {
  display: flex;
  justify-content: center;
  margin: 10px 0;
  padding: 0 !important;

  .reward-btn {
    cursor: pointer;
    margin: 10px 0;
    padding: 6px 10px;
    width: 60px;
    border-radius: 5px;
    color: #fff;
    background-color: #ff0000ba;
    text-align: center;
  }

}

.reward-title {
  color: #8899a6;
  font-size: 14px;
  text-align: center;
  margin-top: 30px;
}

.reward-qr-code {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 10px;

  span {
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/**
 * 文章标签
 */
.article-tags {
  display: flex;
  padding: 0 !important;
  align-items: center;

  div {
    margin: 0 10px;
    color: var(--main-black);
    padding: 4px 10px;
    font-weight: 500;
    border-color: #0a0a0a0f;
    transition: all .2s ease-in;

    &:hover {
      color: #fff;
      border-color: var(--main-green);
      background-color: var(--main-green);
      transition: all .2s ease-in;
    }
  }

  i {
    font-size: 20px;
    margin-right: 10px;
  }
}

/**
 * 相关文章
 */
.interrelated {
  margin: 30px 0;

  .gradient-mask {
    &:after {
      left: 5px;
      width: calc(100% - 10px);
    }
  }

  .item {
    position: relative;
    height: 210px;
    margin-bottom: 10px;
    overflow: hidden;
    cursor: pointer;

    .article-info {
      position: absolute;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      left: 10px;
      top: 0;
      height: 100%;
      width: calc(100% - 20px);
      color: #fff;
      z-index: 10;

      .info {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 14px;
        margin-bottom: 10px;
        justify-content: space-between;
      }

      &:hover + div {
        transform: scale(1.01);
        transition: all .2s ease;
      }
    }
  }


}

/**
 * 版权
 */
.article-copyright {
  background-color: #2dce0b1a;
  padding: 20px;
  border-radius: 8px;
  margin: 40px 0;
  font-size: 14px;

  i {
    font-size: 14px;
  }
}

</style>
