<template>
  <div class="sl-share hidden-xs-only">
    <div style="height: 20px; margin-bottom: 10px; font-size: 13px; color: #ccc"><span
        v-if="addPraise">{{ addPraise }}</span></div>
    <div :class="praiseAn ? 'sl-praise-an' : 'sl-praise-hide'">+1</div>
    <div class="share-zan" @click="onPraise">
      <i class="sl" :class="praise?'icon-zan-fill' : 'icon-zan'"></i>
    </div>
    <div class="zan-num">{{ praiseCount }}</div>
    <div style="width:50%;margin-left: 25%">
      <el-divider style="width: 20px"/>
    </div>
    <!-- 收藏 -->
    <el-tooltip effect="dark" placement="top" :content="'已有' + collectionCount + '人收藏'">
      <div @click="onCollection"><i :class="collection ? 'el-icon-star-on': 'el-icon-star-off'"></i></div>
    </el-tooltip>
    <!--    <el-tooltip effect="dark" placement="top" content="分享到新浪微博">-->
    <!--      <div><i class="sl icon-sina"></i></div>-->
    <!--    </el-tooltip>-->
    <el-tooltip effect="dark" placement="top" content="分享到微信">
      <div>
        <el-popover placement="right">
          <i slot="reference" class="sl icon-weixin"></i>
          <div style="position:relative">
<!--            L（低）	〜7％-->
<!--            M（中）	〜15％-->
<!--            Q（四分位数）	〜25％-->
<!--            H（高）	〜30％-->
            <VueQrcode :logo="require('@/assets/logo.png')" :value="$baseUrl + $route.path" :options="{width: 150, margin: 1, errorCorrectionLevel: 'M'}"></VueQrcode>
            <img :src="require('@/assets/logo.png')" class="qr-logo"/>
          </div>
        </el-popover>
      </div>
    </el-tooltip>
  </div>
</template>

<script>
import articleApi from "@/common/api/articleApi";
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
  name: "LeftShareAside",
  data() {
    return {
      praise: false,  // 是否已赞
      praiseAn: false, // 赞动画
      collection: false, // 是否收藏
      addPraise: 0, // 赞的数量
      praiseCount: 0,
      collectionCount: 0
    }
  },
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.collection = this.data.collection;
    this.praiseCount = this.data.praise;
    this.collectionCount = this.data.collectionCount;
  },
  methods: {
    onPraise() {
      this.praise = true;
      ++this.addPraise;
      ++this.praiseCount;
      this.praiseAn = true;
      setTimeout(() => this.praiseAn = false, 1000);
      articleApi.praiseArticle(this.data.id, () => {
      });
    },
    onCollection() {
      if (this.collection) {
        // 取消收藏
        articleApi.cancelCollection(this.data.id, () => {
          --this.collectionCount;
          this.collection = false;
        })

      } else {
        // 收藏
        articleApi.collection(this.data.id, () => {
          ++this.collectionCount;
          this.collection = true;
        });
      }
    }
  },
  components:{VueQrcode}
}
</script>

<style scoped lang="less">
.share-qr {
  width: 150px;
  height: 150px;
}

.sl-share {
  position: fixed;
  left: 12.66667%;
  top: 30%;
  display: flex;
  flex-direction: column;

  .sl-praise-hide {
    display: none;
  }

  .sl-praise-an {
    display: flex;
    position: absolute;
    top: 0;
    left: 12px;
    align-items: center;
    justify-content: center;
    background-color: red;
    color: #fff;
    border-radius: 50%;
    font-size: 12px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 5px red;
    animation: addan 1s ease-in;

    @keyframes addan {
      0% {
        transform: translateY(0);
      }

      50% {
        transform: translateY(-60px);
      }

      60% {
        transform: translateY(-70px);
      }

      70% {
        transform: translateY(-70px);
      }

      100% {
        transform: translateY(-100px);
        opacity: 0;
      }
    }
  }

  .share-zan {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border: .5px #cccccc4a solid;
    border-radius: 50%;

    &:hover {
      border-color: pink;
      animation: daborder .8s ease-in;
      @keyframes daborder {
        0% {
          box-shadow: 0 0 2px 1px pink;
        }
        50% {
          box-shadow: 0 0 10px 8px pink;
        }
        100% {
          box-shadow: 0 0 2px 1px pink;
        }
      }
    }

    &:active {
      transform: scale(1.3);
      transition: .2s ease-in;
    }

    i {
      font-size: 25px;
      color: pink;
      font-weight: 100;
    }
  }

  .zan-num {
    color: #777;
    margin-top: 10px;
    font-size: 13px;
  }

  div:nth-child(6), div:nth-child(7), div:nth-child(8) {
    margin: 10px 0;
    cursor: pointer;

    i {
      font-size: 20px;
      font-weight: 500;
    }
  }

  div:nth-child(6) {
    color: #ffca00;
  }

  //div:nth-child(7) {
  //  color: #ff1e00d1;
  //}
  div:nth-child(7) {
    color: var(--main-green);
  }

  //div:nth-child(8) {
  //  color: var(--main-green);
  //}
}
.qr-logo{
  position: absolute;
  width: 30px;
  left: calc(50% - 15px);
  top: calc(50% - 12px);
  background: white;
}
</style>
