<template>
  <el-row>
    <el-col :md="{span:16, offset: 4}">
      <div class="article-change">
        <el-row>
          <el-col>
            <el-breadcrumb separator="/" style="margin-bottom: 35px">
              <el-breadcrumb-item :to="{ name: 'Home' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>{{ isEdit ? '编辑文章' : '发布文章' }}</el-breadcrumb-item>
            </el-breadcrumb>
          </el-col>
          <el-col class="spacing">
            <input type="text" style="font-size: 18px" placeholder="输入文章标题" v-model="formData.title"
                   @blur="onStaticUrl"/>
          </el-col>

          <el-col class="spacing" v-show="isStaticUrl">
            <input type="text" style="font-size: 14px" placeholder="文章静态url"
                   v-model="formData.staticUrl" :disabled="isEdit"/>
          </el-col>

          <el-col class="spacing article-type">
            <div style="text-align: left;width: 100%">
              <span>类型</span>
              <el-select v-model="formData.articleType" filterable placeholder="请选择" class="choose"
                         popper-class="popper" width="100%">
                <el-option
                    v-for="(item, index) in articleType"
                    :key="index"
                    value-key="name"
                    :label="item.name"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>

            <div :md="8" style="text-align: left;width: 100%">
              <span>分类</span>
              <el-select v-model="formData.categoryId" filterable placeholder="请选择" class="choose" width="100%">
                <el-option
                    v-for="(item, index) in categories"
                    :key="index"
                    value-key="name"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>

            <div :md="8" style="text-align: left;width: 100%">
              <span>标签</span>
              <el-select v-model="formData.tagIds"
                         filterable placeholder="选择已有或创建新的标签"
                         multiple
                         allow-create
                         remote
                         :multiple-limit="3"
                         value-key="name"
                         :loading="loading"
                         :remote-method="searchTabHandler"
                         @blur="tags = []"
                         class="choose">
                <el-option
                    v-for="(item, index) in tags"
                    :key="index"
                    value-key="name"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
          </el-col>

          <el-col class="spacing">
            <ckeditor v-model="formData.content" placeholder="请输入文章正文..." @change="editorChange"></ckeditor>
          </el-col>

          <el-col class="spacing sl-download">
            <span><h3>文章附件</h3></span>
            <div style="display: flex; flex-direction: row;">
              <el-tooltip content="点击增加下载地址" placement="top">
                <SLButton style="margin-bottom: 20px; margin-right: 20px" @click="onDownloadUrl('add')"><i
                    class="el-icon-plus"></i></SLButton>
              </el-tooltip>
              <el-tooltip content="点击减少下载地址" placement="top">
                <SLButton style="margin-bottom: 20px" @click="onDownloadUrl('reduce')"><i class="el-icon-minus"></i>
                </SLButton>
              </el-tooltip>

              <div>

              </div>
            </div>
            <el-row :gutter="10" class="input-down" v-for="(item, index) in formData.download" :key="index">
              <div class="permission-menu">
                <el-radio v-model="formData.download[index].type" label="Free" @change="onDownloadType">免费</el-radio>
                <el-radio v-model="formData.download[index].type" label="Credit" @change="onDownloadType">积分</el-radio>
                <el-radio v-model="formData.download[index].type" label="Money" @change="onDownloadType">金额</el-radio>
              </div>
              <el-col :md="6">
                <el-input placeholder="文件名" v-model="item.name"/>
              </el-col>

              <el-col :md="8">
                <el-input placeholder="下载地址" v-model="item.url"/>
              </el-col>

              <el-col :md="4">
                <el-input placeholder="提取码" v-model="item.code"/>
              </el-col>

              <el-col :md="6" v-if="item.type === 'Credit' || item.type === 'Money'">
                <el-input type="number" placeholder="所需支付金额（单位元）" v-model="item.money"
                          v-if="item.type === 'Money'"/>
                <el-input type="number" placeholder="所需积分" v-model="item.money" v-else/>
              </el-col>
            </el-row>
          </el-col>

          <el-col class="spacing permission">
            <el-checkbox v-model="formData.hidden" @change="onIsHidden">添加隐藏内容？</el-checkbox>
          </el-col>

          <div v-if="formData.hidden">
<!--            <el-col class="spacing">-->
<!--              <el-divider>隐藏内容</el-divider>-->
<!--              <el-radio-group v-model="articleHiddenType" size="mini" @change="hiddenChange">-->
<!--                <el-radio-button label="下载链接" name="Download"></el-radio-button>-->
<!--                <el-radio-button label="文本内容" name="Content"></el-radio-button>-->
<!--              </el-radio-group>-->
<!--            </el-col>-->

            <!-- 权限 -->
            <el-col class="spacing permission">
              <el-checkbox-group v-model="checkList" @change="checkChange">
                <el-tooltip v-for="(item, index) in permissions" :key="index" placement="top" :content="item.content">
                  <el-checkbox :label="item.name" :disabled="item.status"></el-checkbox>
                </el-tooltip>
              </el-checkbox-group>
              <div style="margin-top: 20px" v-if="checkList.indexOf('会员等级') >= 0">
                <el-divider></el-divider>
                <el-radio-group v-model="formData.userLevel">
                  <el-radio label="Ordinary">普通用户</el-radio>
                  <el-radio label="Senior">高级会员</el-radio>
                  <el-radio label="Admin">管理员</el-radio>
                </el-radio-group>
              </div>
            </el-col>

            <el-col class="spacing">
              <div v-if="checkList.indexOf('付费') >= 0 || checkList.indexOf('积分') >= 0">
                <input type="number" style="margin-bottom:20px; width:30%" placeholder="输入隐藏内容查看所需价格（单位元）"
                       v-if="checkList.indexOf('付费') >= 0" v-model="formData.hiddenContent.money"/>
                <input type="number" style="margin-bottom:20px; width:30%" placeholder="输入隐藏内容查看所需积分" v-else
                       v-model="formData.hiddenContent.money"/>
              </div>
              <ckeditor id="hiden" v-model="formData.hiddenContent.content" placeholder="请输入隐藏内容..."></ckeditor>
            </el-col>
          </div>

          <el-col class="spacing" style="display: flex; justify-content: center;">
            <SLButton class="submit-btn" :loading="submitLoading" @click="submitForm">提交</SLButton>
          </el-col>
        </el-row>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import CKEditor from "@/components/ckeditor/CKEditor.vue";
import SLButton from "@/components/SLButton";
import articleApi from "@/common/api/articleApi";
import {mapState} from "vuex";

export default {
  name: "ArticlePage",
  data() {
    return {
      articleId: this.$route.params.id,
      isEdit: false,
      categories: [],
      loading: false,
      tags: [],
      permissions: [
        {name: '公众号', content: '关注公众号可查看'}, {name: '登录', content: '登陆后可查看'},
        {name: '评论', content: '评论后可查看'}, {name: '付费', content: "支付指定费用后可查看"},
        {name: '积分', content: "支付指定积分后可查看"}, {name: '会员等级', content: '指定会员等级可查看'},
      ],
      articleHiddenType: "下载链接",
      checkList: [],
      articleType: [
        {name: '公共文章', value: "Public"}, {name: "私有文章", value: "Private"}
      ],
      formData: {
        title: null,
        staticUrl: null,
        articleType: null,
        categoryId: null,
        tagIds: [],
        content: null,
        // NotThrough 未通过 Audit审核中  OK 审核通过
        status: "Audit",
        articleHiddenType: "Download",
        articleHiddenPermissionType: [],
        download: [],
        hiddenContent: {
          money: null,
          content: null,
        },
        userLevel: null,
        hidden: false,
      },
      count: 0,
      submitLoading: false,
    }
  },
  mounted() {
    let reg = /.*edit.*/;
    this.isEdit = reg.test(this.$route.path);
    if (this.isEdit) {
      articleApi.getArticleData(this.$route.params.id, res => {
        this.formData = res;
        if (this.formData.hidden) {
          this.articlePermissionTransform(this.formData.articleHiddenPermissionType);
          this.checkChange(this.checkList, true);
        } else {
          let data = {
            articleHiddenPermissionType: [],
            hiddenContent: {
              money: null,
              content: null,
            },
            userLevel: null,
          }
          Object.assign(this.formData, data);
        }
      });
    }
    articleApi.getAllCategory(res => this.categories = res);
    setTimeout(() => {
      if (this.userInfo.level === "Admin") {
        this.formData.status = "OK";
      }
    }, 800)
  },
  methods: {
    onIsHidden(e) {
      if (e) {
        this.hiddenChange("下载链接");
        this.articleHiddenType = "下载链接";
      }
    },
    articlePermissionTransform(data) {
      for (let i = 0; i < data.length; ++i) {
        if (data[i] === 'PublicNo') this.checkList.push("公众号");
        if (data[i] === 'Login') this.checkList.push("登录");
        if (data[i] === 'Comment') this.checkList.push("评论");
        if (data[i] === 'Credit') this.checkList.push("积分");
        if (data[i] === 'UserLevel') this.checkList.push("会员等级");
        if (data[i] === 'Pay') this.checkList.push("付费");
      }
    },
    searchTabHandler(query) {
      if (query !== '') {
        // 网络搜索标签
        this.loading = true;
        articleApi.searchTags(query, res => {
          this.loading = false;
          this.tags = res;
        });
      }
    },
    submitForm() {
      this.submitLoading = true;
      articleApi.createNewArticle(this.formData, this.userInfo.level, this.isEdit, (status) => {
        this.submitLoading = false;
        if (status) {
          let url = this.formData.staticUrl;
          this.formData = {};
          setTimeout(() => this.$router.push(url), 2000);
        }
      });
    },
    editorChange(e) {
      // console.log(e)
    },
    onDownloadUrl(type) {
      if (type === 'add') {
        this.formData.download.push({name: '', url: '', code: '', money: '', type: "Free"})
      } else {
        this.formData.download.splice(this.formData.download.length - 1, 1)
      }
    },
    onDownloadType(e){
    },
    checkChange(e, init) {
      if (e.indexOf("评论") >= 0 || this.checkList.indexOf("付费") >= 0 ||
          this.checkList.indexOf("积分") >= 0 || this.checkList.indexOf("会员等级") >= 0
          || this.checkList.indexOf("公众号") >= 0) {
        if (this.checkList.indexOf("登录") < 0) {
          this.checkList.push("登录")
        }
      }
      this.$set(this.permissions[1], "status", e.indexOf("评论") >= 0 || this.checkList.indexOf("付费") >= 0
          || this.checkList.indexOf("积分") >= 0 || this.checkList.indexOf("会员等级") >= 0
          || this.checkList.indexOf("公众号") >= 0);
      this.$set(this.permissions[4], 'status', e.indexOf('付费') >= 0);
      this.$set(this.permissions[3], 'status', e.indexOf('积分') >= 0);

      if (init !== undefined) {
        return;
      }
      // 置空已设置的金额
      if (this.checkList.indexOf("积分") < 0 && this.checkList.indexOf("付费") < 0) {
        // for (let j = 0; j < this.formData.download.length; j++) {
        //   this.formData.download[j].money = null;
        // }
        this.formData.hiddenContent.money = null;
      }

      // 转换后端需要数据格式
      this.formData.articleHiddenPermissionType = [];
      for (let i = 0; i < this.checkList.length; i++) {
        switch (this.checkList[i]) {
          case "公众号":
            this.formData.articleHiddenPermissionType.push("PublicNo")
            break;
          case "登录":
            this.formData.articleHiddenPermissionType.push("Login")
            break;
          case "评论":
            this.formData.articleHiddenPermissionType.push("Comment")
            break;
          case "积分":
            this.formData.articleHiddenPermissionType.push("Credit")
            break;
          case "会员等级":
            this.formData.articleHiddenPermissionType.push("UserLevel")
            break;
          case "付费":
            this.formData.articleHiddenPermissionType.push("Pay")
            break;
        }
      }
    },
    hiddenChange(e) {
      if (e === '下载链接') {
        // 置空 隐藏文本内容
        this.formData.articleHiddenType = "Download"
      } else {
        this.formData.articleHiddenType = "Content";
        this.formData.download = [
          {name: '', url: '', code: '', money: ''}
        ]
      }
    },
    onStaticUrl(e) {
      if (this.count < 2 && !this.isEdit) {
        articleApi.getStaticUrl(e.target.value, (res) => {
          this.$set(this.formData, "staticUrl", `/${res}.html`);
          this.count += 1;
        });
      }
    }
  },
  components: {SLButton, ckeditor: CKEditor},
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
    isStaticUrl(){
      if (this.userInfo)
      return this.userInfo.level === 'Admin'
    }
  }
}
</script>

<style scoped lang="less">

/deep/ .ck-focused {
  border-color: var(--main-green) !important;
}

.article-change {
  margin: 60px 0 30px 0;

  input, .input {
    outline: none;
    border-style: none none solid none;
    border-color: #cccccc4b;
    border-width: 1px;
    padding: 5px 0;
    color: var(--main-black);
    width: 100%;
    text-align: center;
    font-size: 14px;

    &:disabled {
      background-color: #fff;
      font-size: 14px;
      color: #777;
    }
  }

  .article-type {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .spacing {
    margin-bottom: 30px;

    .submit-btn {
      padding: 10px 50px;
      background-color: var(--main-green);
      color: #fff;
    }

    /deep/ .is-active > .el-radio-button__inner {
      background-color: var(--main-green);
      border-color: var(--main-green);
      box-shadow: -1px 0 0 0 var(--main-green);

      &:hover {
        color: #fff;
      }
    }

    /deep/ .el-radio-button__inner:hover {
      color: var(--main-green);
    }

    /**
      编辑器 大小
     */
    /deep/ #hiden + .ck-editor {
      /deep/ .ck-editor__editable_inline {
        height: 450px;
      }
    }

    /deep/ #editor + .ck-editor {
      /deep/ .ck-editor__editable_inline {
        height: 800px;
      }
    }
  }

  .sl-download {
    .input-down {
      margin-bottom: 20px;

      .permission-menu{
      }
    }
  }

  .permission {
    span {
      display: block;
      margin-bottom: 20px;
    }

    /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
      color: var(--main-green);
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: var(--main-green);
      border-color: var(--main-green)
    }

    /deep/ .el-checkbox__inner:hover {
      border-color: var(--main-green);
    }
  }
}

.choose {
  margin-left: 20px;
  width: 80%;

  /deep/ .el-input {
    .el-input__inner:hover {
      border-color: var(--main-green)
    }
  }

  /deep/ .el-input.is-focus .el-input__inner {
    border-color: var(--main-green)
  }
}
</style>

<style lang="less">
.popper {
  //width: 100%;
}
</style>

