<template>
  <div ref="feed" id="feed" :class="fixed ? 'feed-fixed': ''" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FixedArea",
  data() {
    return {
      fixed: false,
      style: {}
    }
  },
  props: {
    left: {
      type: String,
      default: ""
    },
    right: {
      type: String,
      default: "16.66667"
    },
    top: {
      type: String,
      default: "10"
    },
    width: {
      type: String,
      default: ""
    }
  },
  mounted() {
    let self = this;
    setTimeout(() =>{
      try {
        let discountTop = window.pageYOffset + self.$refs.feed.getBoundingClientRect().top
        this.style["left"] = self.$refs.feed.getBoundingClientRect().left + 'px';
        window.addEventListener("resize", (e) =>{
          self.fixed = false;
          setTimeout(()=>{
            let elementById = document.getElementById("feed");
            try {
              this.style["left"] = elementById.getBoundingClientRect().left + 'px';
            } catch (e){}
          }, 10)
        });
        window.addEventListener("scroll", () => {
          self.fixed = window.pageYOffset >= discountTop;
        })
      } catch (e) {
        console.warn("固定区域被移除")
      }
    }, 1000);
    this.style["top"] = this.top + 'px';
    // this.style["right"] = this.right + '%';
    this.style["left"] = this.left + '%';
    this.style["width"] = this.width + '%';
  },
  beforeDestroy() {
    window.removeEventListener("scroll", () => {
    })
    window.removeEventListener("resize",()=>{})
  }
}
</script>

<style scoped>
.feed-fixed {
  position: fixed;
  top: 10px;
  right: 0;
  width: 21.846%;
}
</style>
