<template>
  <el-row>
    <el-col>
      <div class="person-bg" :style="bg">
        <div class="user-info-wrapper">
          <div class="info">
            <!--            <img class="user-avatar"-->
            <!--                 src="https://solove.love/media/uploads/article/images/2019/05/solove/lo_1557896732_1.gif"/>-->
            <div class="user-avatar">
              <Avatar :size="60" :lighten="10000"
                      :username="userData.username || 'd'"
                      :src="avatar || ''"></Avatar>
            </div>
            <h3>{{ userData.nickname || userData.email || userData.username }}</h3>
            <span>{{ userData.extension.description || "这个家伙很懒、什么也没留下" }}</span>
            <div class="btn">
              <SLButton @click="onFocusUser" :loading="focusBtnLoading"><i class="sl"
                                                                           :class="focused ? 'icon-focus-on' : 'icon-focus'">
                {{ focused ? '已关注' : '关注' }}</i></SLButton>
              <SLButton @click="privateMessageBox = true"><i class="sl icon-email"> 私信</i></SLButton>
            </div>
          </div>
        </div>
      </div>
    </el-col>

    <el-row>
      <el-col class="btn-group">
        <SLButton class="btn" :class="current === index ? 'active' : ''" v-for="(item,index) in btn" :key="index"
                  @click="onClick(index)">{{ item }}
        </SLButton>
      </el-col>
      <el-col :md="{span: 16, offset: 4}" style="margin-bottom: 40px">
        <transition name="toPage" mode="out-in">
          <router-view :userData="userData"/>
        </transition>
      </el-col>
    </el-row>
    <el-dialog
        :visible.sync="privateMessageBox"
        width="50%"
        center>
      <template slot="title">私信</template>
      <textarea maxlength="255" v-model="msg" class="content-area" placeholder="说点什么呢？"></textarea>
      <template slot="footer">
        <div class="btn-group">
          <SLButton @click="privateMessageBox = false" style="margin-right: 20px" width="100">取 消</SLButton>
          <SLButton :loading="sendMsgLoading" width="100" @click="sendMessage"><i class="el-icon-s-promotion"
                                                                                  style="font-size: 14px"></i>
          </SLButton>
        </div>
      </template>
    </el-dialog>
  </el-row>
</template>

<script>
import SLButton from "@/components/SLButton";
import userPersonalApi from "@/common/api/userPersonalApi";
import Avatar from "vue-avatar";
import getUrl from "@/common/utils/getUrl";

/**
 * 个人主页
 */
export default {
  name: "PersonalPage",
  data() {
    return {
      btn: ["Ta的资料", "Ta的文章", "Ta的粉丝", "Ta关注的"],
      current: 0,
      focused: true,
      focusBtnLoading: false,
      userData: {
        avatar: null,
        nickname: "",
        extension: {
          bgImage: null
        }
      },
      privateMessageBox: false,
      sendMsgLoading: false,
      msg: ""
    }
  },
  created() {
    userPersonalApi.getPersonalFocusState(this.$route.params.id, res => this.focused = res);
  },
  mounted() {
    window.scrollTo({top: 0})
    let uid = this.$route.params.id;
    userPersonalApi.getPersonalData(uid, res => {
      // console.info(res);
      this.userData = res;
    })

    let url = window.location.href;
    let name = url.split(uid + '/');
    let str;
    if (name.length > 1) {
      str = name[1]
    }
    this.getCurrentIndex(str);
  },
  methods: {
    onClick(index) {
      if (this.current === index) return;
      this.current = index;
      switch (index) {
        case 0:
          this.$router.push({name: 'data'});
          break;
        case 1:
          this.$router.push({name: 'article'});
          break;
        case 2:
          this.$router.push({name: 'fans'});
          break;
        case 3:
          this.$router.push({name: 'focus'});
          break;
        default:
          this.$router.push({name: 'data'});
          break;
      }
    },
    getCurrentIndex(name) {
      switch (name) {
        case 'article':
          this.current = 1;
          break;
        case "fans":
          this.current = 2;
          break;
        case "focus":
          this.current = 3;
          break;
        default:
          this.current = 0;
          break;
      }
    },
    onFocusUser() {
      this.focusBtnLoading = true;
      userPersonalApi.focusPersonalOrCancel(this.$route.params.id, !this.focused, state => {
        this.focusBtnLoading = false;
        if (state) {
          this.focused = !this.focused;
          if (this.focused) {
            this.$message.success("谢谢您的关注~")
          } else {
            this.$message.success("关注已取消~")
          }
        }
      })
    },
    sendMessage() {
      if (this.msg.trim().length < 10) {
        return this.$message.warning("内容不能少于10个字符哦~")
      }
      this.sendMsgLoading = true;
      userPersonalApi.sendMessage(this.$route.params.id, this.msg.trim(), state => {
        this.sendMsgLoading = false;
        if (state) {
          this.privateMessageBox = false;
          this.$message.success("私信发送成功~");
          this.msg = "";
        }
      })
    }
  },
  components: {SLButton, Avatar},
  computed: {
    avatar() {
      if (this.userData.avatar)
        return getUrl(this.userData.avatar);
    },
    bg() {
      if (this.userData.extension.bgImage) {
        let url = this.$baseUrl + this.userData.extension.bgImage;
        return {background: 'url('+ url +')  no-repeat center center'};
      }
      return "";
    }
  }
}
</script>

<style scoped lang="less">
.content-area {
  outline: none;
  resize: none;
  overflow: hidden;
  font-size: 14px;
  padding: 20px 30px;
  height: 100px;
  width: calc(100% - 62px);
  color: var(--main-black);
  border: 1px #cccccc4b solid;
  border-radius: 8px;
}

.person-bg {
  position: relative;
  background: url("../../assets/user-default-bg.jpeg") no-repeat center center;
  background-size: cover;
  height: 280px;
  color: #fff;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background: var(--main-transparent);
  }

  .user-info-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;

    .info {
      position: relative;

      h3 {
        margin: 10px 0;
      }

      .btn {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 95px;
          color: #fff;
          margin-top: 10px;
        }

        button:nth-child(1) {
          margin-right: 10px;
          background: linear-gradient(#20c5a5 5%, #1abc9c, #20c5a5 95%);
        }

        button:nth-child(2) {
          background: linear-gradient(#8ba9c7 5%, #7795b4, #8ba9c7 95%);
        }
      }

    }

    .user-avatar {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin: 0 auto;
      border: 10px solid #ffffff4b;
    }
  }
}

.btn-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 20px 0;

  .btn {

    &:hover {
      color: var(--main-green);
      background: #fff;
      border-color: var(--main-green);
    }
  }

  .active {
    color: var(--main-green);
    background-color: #fff;
    border-color: var(--main-green);
  }
}
</style>
