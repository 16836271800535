<template>
  <div class="rest">
    <el-col :md="{span: 16, offset: 4}">
      <el-row class="rest center">
        <h3>修改密码</h3>
        <div class="input-area">
          <el-form>
            <el-form-item>
              <el-input placeholder="输入新密码" clearable v-model="pw" show-password/>
            </el-form-item>
            <el-form-item>
              <el-input placeholder="再次输入新密码" v-model="newPw" show-password @keyup.enter.native="submitPassword"/>
            </el-form-item>
          </el-form>
        </div>
        <SLButton :loading="loading" width="95.33" style="background:var(--main-green);color: #fff" @click="submitPassword">确认修改</SLButton>
      </el-row>
    </el-col>
  </div>
</template>

<script>
import SLButton from "@/components/SLButton";
import userApi from "@/common/api/userApi";

export default {
  name: "ChangePassword",
  components: {SLButton},
  data() {
    return {
      pw: "",
      newPw: "",
      loading: false,
    }
  },
  methods: {
    submitPassword() {
      if (!this.pw){return;}
      let regex = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/gi;
      if (regex.exec(this.pw) != null) {
        this.$message({message: "密码不能包含中文~", type: "warning", customClass: "max-z-index"})
        return;
      }
      let pwReg = /[0-9]+/gi;
      let raw = pwReg.exec(this.pw.trim())
      if (raw.length > 0 && raw[0].length === this.pw.trim().length) {
        this.$message({message: "密码不能全部数字~", type: "warning", customClass: "max-z-index"})
        return;
      }

      if (this.pw.trim().length > 20 || this.pw.trim().length < 6) {
        this.$message({message: "密码长度需在（6 - 20）之间~", type: "warning", customClass: "max-z-index"})
        return;
      }
      if (this.pw.trim() !== this.newPw.trim()) {
        this.$message({message: "两次输入的密码不一致~", type: "warning", customClass: "max-z-index"})
        return;
      }

      let token = this.$route.params.token;
      if (!token) {
        this.$message({message: "数据异常,请重新获取修改链接~", type: "warning", customClass: "max-z-index"})
        return;
      }
      this.loading = true;
      userApi.changePassword(token, this.pw.trim(), res => {
        this.loading = false;
        if (res) {
          this.$message({message: "密码修改成功，即将跳转到首页~", type: "success", customClass: "max-z-index", duration: 3000})
          this.pw = "";
          this.newPw = "";
          setTimeout(() => this.$router.push("/"), 2000)
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.rest {
  height: calc(100vh - 215px);
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.input-area {
  margin-top: 50px;
  margin-bottom: 80px;
  width: 300px;
}
</style>