import {handlerRes, request} from "@/common/request";

const infoApi = {
    /**
     * 获取友情链接
     * @param callback
     */
    getLinks(callback) {
        request({
            url: "/links",
            method: "get",
            success: res => handlerRes(res, callback)
        })
    }
}

export default infoApi;