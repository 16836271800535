<template>
  <div class="to-link">
    <iframe id="outside" style="margin-top: 10px;border:none" :src="url" width="100%"
            height="100%"></iframe>
    <div class="loaders" :class="className" v-if="loading">
      <i class="sl icon-loading loading"></i>
      <span style="margin-top: 20px">请稍后...</span>
    </div>
  </div>

</template>

<script>
let Base64 = require('js-base64').Base64

export default {
  name: "TO",
  data() {
    return {
      loading: true,
      className: "",
      url: ""
    }
  },
  mounted() {
    this.url = Base64.decode(this.$route.params.url);
    // 百度网盘需跳转外部
    // "https://pan.baidu.com/s/1b8lBmI"
    let regx = /.*pan.baidu.com.*/g
    if (regx.test(this.url)){
      setTimeout(() => window.location.href = this.url, 1000)
    } else{
      let iframe = document.getElementById("outside");
      iframe.onload = this.onLoad;
    }
  },
  methods: {
    onLoad(e) {
      this.className = "hidden";
      setTimeout(() => {
        this.loading = false;
        this.className = "";
      }, 1400);
    }
  }
}
</script>

<style scoped lang="less">
.to-link {
  position: relative;
  height: calc(100vh - 216px);

  .loaders {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 10px;
    width: 100%;
    height: calc(100% - 10px);
    background-color: #fff;

    .loading {
      font-size: 18px;
      color: var(--main-green);
      animation: loader .4s linear infinite;

      @keyframes loader {
        0% {
          transform: rotate(0deg)
        }
        100% {
          transform: rotate(360deg)
        }
      }
    }
  }
}

.hidden {
  opacity: 0;
  transition: opacity 1.5s linear;
}
</style>
