<template>
  <p :style="{'-webkit-line-clamp': line, 'font-size': size + 'px', color: color}">
    <slot></slot>
  </p>
</template>

<script>
export default {
  name: "SLText",
  props: {
    line: {
      type: [Number, String],
      default: 1
    },
    size: {
      type: [Number, String],
      default: 14
    },
    color: {
      type: String,
      default: "var(--main-black)"
    }
  }
}
</script>

<style scoped>
  p{
    overflow: hidden;
    display: -webkit-box;
    text-align: start;
    margin: 10px 0;
    -webkit-box-orient: vertical;
  }
</style>
