let data = `
<div style="text-align: left">
<p>本站允许访问者查看其上的材料，但只能用于个人的、非商业用途，且不侵犯本站资料的任何著作权及其他合法权利。<br>
                         
                          <br>
                          <strong>免责声明与责任限制</strong>  <br>
                          本站的内容均基于<a href="http://creativecommons.org/licenses/by-nc-sa/2.5/cn/" style="color: var(--main-green)" target="_blank">署名-非商业性使用-相同方式共享 2.5 中国大陆(CC BY-NC-SA 2.5 CN) </a>协议创作或转载，您可以转载分享，但同时保留原文链接

本站提供的所有内容仅供学习、分享与交流，我们不保证内容的正确性。通过使用本站内容随之而来的风险与本站无关。当使用本站时，代表你已接受本站的免责声明和隐私原则等条款。 
                          <br> 
\t\t\t\t\t\t  </p>
                          <p> 本站力求给您提供安全、通畅的访问和其他服务，但由于Internet线路、垃圾邮件、黑客入侵或其他不可抗力等原因直接给您造成了损害，本站不负任何责任。</p>
                          <p>本站中指向其他第三方站点的链接</p>
                          <p> 本站某些内容指向其他第三方站点的链接仅仅为访问者方便。如果您使用这些链接，您将离开此本站。您通过由本站而链接到的第三方站点，您应自行承担可能的风险，本站不保证第三方站点的安全性。</p>
                            <p>与本站进行链接</p>
                          <p> 您可以创建从自己拥有的站点或其它站点到本站的链接，但您不得使用包括但不限于隐藏本站域名、URL地址、改变本文本结构等任何技术的、非技术的手段使访问本站的访问者误认为您是本站所搭载内容的所有人；不得用与本站的链接为自己进行增加访问量、丰富内容、搜索引擎排名等任何营利；不得复制本站的内容；不得断章取义地链接某一内容，不得对本站内容进行虚假的陈述；不得侵犯本站的商标或标识。</p>
                          <p> <br>
                            <strong>使用条款</strong></p>
                          <p>以下为使用skill's的自留地(下称「本网站」)的有关条款(下称「本条款」)。另行列出的《隐私政策》为本条款的一部分，作为释义或其它用途。若不接受本条款，请不要使用本网站提供的服务。当您使用本网站提供的服务时，即表明已详细阅读及了解本条款，并同意受本条款约束。本网站可能随时以更新网页方式单方面修改本条款，而不会作另行通知。您应定期浏览此网页，以了解对您具有约束力的条款是否有所修改。若您违反任何条款，或本网站怀疑您违反任何条款，或本网站相信您的行为违反适用法律或损害本网站或其用户的利益，本网站可在您获知或不获知的情况下终止您进入本网站及使用各项服务，并采取法律行动。</p>
                          <p>1.网站的合法用途</p>
                          <p>本网站是由多个网页组成的大型网络端口，目的是让用户取得实时新闻报导及各种各样的信息和资源。除经特别声明外，本网站的任何新增服务、特点和功能均受本条款约束。</p>
                          <p>您只可使用本网站作合法用途，不可利用本网站以任何方式侵犯别人的知识产权；或侵犯本网站上其他用户的隐私以获取其身份或任何个人资料；或破坏本网站的任何部分。您有责任判断是否有上述的侵权行为。您不得登载任何错误或虚假的信息，或者侵犯或违反任何第三方权利的信息，也不得登载关于您本身或您权益的不实陈述。您必须遵守登载在网站上的政策。您知道本网站既不对您在本网站上登载的内容提供任何包括查阅、删除或编辑在内的协助，也不对您使用本网站提供任何客户服务。如果您有进入本网站的密码，不得与任何第三方分享。<br> </p><p>您不得利用本网站传输、出版、上载、登载或流通任何非法、有害、恐吓、骚扰、侵权、毁谤、淫秽、中伤、违反他人隐私或名誉权、违反他人知识产权、或者任何其他具攻击性或令人不悦的资料或信息。此外，您不得以任何方式篡改任何资料，也不得以任何方式干扰本网站的运营，包括但不限于传输软件病毒，或者传输专门用来中断、干扰或改变网站运营的任何计算机代码或消息。您也不得利用本网站收集本网站用户的资料。 
                           在没有本网站的明确许可下，本网站及其任何部分均不得翻版、复制、翻印、售卖、转售或以其它方式利用本网站作商业用途。</p>
                          <p>本网站尊重著作权法，亦坚持用户同样尊重著作权法。在未获得授权情况下复制、分发、修改及向公众展示有著作权的作品，属侵犯著作权所有人的权利。作为本网站用户的条件，您须同意不会使用本网站侵犯别人的知识产权。本网站将保留权利终止任何被发现利用本网站侵犯著作权或他人知识产权的用户的使用。</p>
                            <p>
                            2.网站资料的使用</p>
                          <p>本网站的内容，如文字、图形、图像、音乐、标识、图标按钮、链接、HTML编码、软件和其他资料(以下统称"资料")，部分来源于网络，如果您发现侵犯了您的权益，请Email: solove@solove.love告知我们，我们会第一时间做删除处理。如果您复制任何资料，您必须在复制件上保留原资料中有关版权、商标、服务标志以及其他专有权的告示。您只能复制一份资料，并且除非这些资料另有具体规定，复制件仅可用在私人、非商业用途。未经本网站事先的书面同意，您不得为了任何公共或商业目的销售或更改资料，或者复制、重制、再版、上载、下载、登载、传送、显示、公开执行或以任何方式发送资料，或将资料存储于检索系统，或以其他任何方式使用资料。</p>
                          <p>3.资料的公开披露</p>
                          <p>在发出任何资料以前，您须检查您是否在本网站的公共区域发出该资料。如果您是在公共区域发出资料，您承认您是出于自愿并且任何人可以使用该资料。所有您自愿在公共区域提供的资料将不被视为是由本网站收集的，并且本网站没有任何责任处理其他人在本网站的公共区域提供的有可能失实、误导、淫秽、非法或诽谤的该类资料。您承认本网站无法控制核查所有该类资料的真实性，并且本网站也没有任何责任处理任何要求改正或删除该类资料的请求。</p>
                          <p>4.关于产品和服务</p>
                          <p>本网站所提供、介绍或提及的商品、产品或服务有可能由第三方提供。对于这类产品，本网站无法、也不会就您应第三方要求而向其提供的任何资料的安全作出任何陈述和保证。本网站建议，在与任何此类第三方进行任何网上或网外交易之前，您应作一切您认为必要或适当的调查。在法律允许的最大限度内，对所有明文或隐含的、关于商品的适销性和具体用途的陈述和保证，以及对任何隐含的、涉及商品的性能和使用过程的保证，本网站一概免责。在任何情况下，对与本网站上所提供、介绍或提及的资料或商品的使用、无法使用、运作或无法运作有关的损失，本网站概不负责。此类损失的范围包括但不限于：为获取替代货品或服务的所支付的费用、利润损失、数据资料损失、以及任何直接、间接、特殊、惩罚性、伴随性、惩戒性、或继而引起的损失。网站上对商品、产品或服务的提供、介绍或提及仅仅是第三方所作的交易邀请。</p>
                          <p>5.用户资料的收集</p>
                          <p>作为用户，您可能须向本网站提供您的资料。在使用本网站的不同时间，您可能会被要求向本网站提供ICQ或OICQ号码、电子邮件地址、兴趣和喜好之活动。您同意该资料是您自愿向本网站提供的。您必须保证提供的资料准确、属实、有效和完整。如果并非如此或本网站有合理理由表示怀疑，本网站有权拒绝您现时或将来使用本网站提供的服务。</p>
                          <p>若您提供的资料不齐全或不正确，本网站可能不容许或终止您进入本网站，或可能无法向您提供或继续提供服务。您明白您向本网站提供的任何及所有资料将会被收集及可能作下列用途，可用来作为:</p>
                          <p>(a)收集数据作身份登录凭证；<br>
                            (b)让本网站能履行其结连网络的责任或他业内实务上的责任;<br>
                            (c)向您发出有关不同产品和服务的资料;<br>
                            (d)保存通讯联络资料;<br>
                           (e)作有关网站使用的总量统计;<br>
              (f)将用户的数据与用户所提供的其它资料（不管作何种用途）作比较，包括无限制地进行与用户对立之行动（不管有关比较是用人手或自动化方法进行）;<br>
                        (j)满足现行适用的任何法律、行政制度或规例的要求，包括披露或通知要求;<br>
                         (h)执行用户的指示，响应该用户或以该用户名义提出的查询，并向用户提供有效而快捷的服务。</p>
                          <p>本网站对于收集、使用、保存、披露、转移、保护及存取资料的政策和做法，均符合法律规定，并符合本文条款及《隐私政策》。</p>
                          <p>您确认并同意，您在本网站上放置的任何资料或资料，您对其形式、内容和准确性负有全部责任。 您同意本网站为达到以下目的而在合理、必要的情况下可公开您的资料，即为了</p>
                          <p>(a)遵守有关法律，<br>
                            (b)执行或实施本网站的任何用户协议的条款， <br>
                            (c)保障本网站及本网站用户的权利、财产或利益，或<br>
                            (d)遵守隐私政策声明。</p>
                          <p>您知道根据隐私政策，您有权查阅本网站持有的您的资料，有权要求更正您的资料，有权知道对任何此类要求所采取的行动; 
                            鉴于互联网的性质，本网站不能保证数据传输百分之百的可靠性。但是本网站将采取所有实际可行的措施，确保由本网站收集到的任何资料不受第三方的干扰。但是，请知道本网站的广告商或在本网站上设有链接的其他网站可能在收集您的个人数据。本网站一旦收到资料，将按照一贯的严格安全和保密准则保存资料。</p>
                          <p>本网站将于您使用本网站期间，保留您的个人资料。您向本网站提供能核证个人身分的资料后，可能不时收到本网站的电子邮件，内含站点更新等资料。若您不想收到此等资料，请Email: solove@solove.love。 
                            欲知悉有关本网站的隐私政策，请阅读下面的隐私政策。</p>
                          <p>6.链接</p>
                          <p>本网站内设有通往其他网站和网页的链接，但此类网站和网页并非由本网站经营或控制，本网站不承担责任。您启动任何此类链接或网页，离开本网站进入其他网站或网页，所有风险自负，本网站不承担一切责任和债务。</p>
                          <p>7.免责条款</p>
                          <p>网站内容可能不正确或有编排上的错误。本网站不承认所有默示和/或明示的保证、并且不保证内容的正确性、可靠性、完整性或及时性。本网站对内容以及网站上登载的或与网站链接的任何事物，包括但不限于任何谬见、错误、遗漏、侵权、毁谤、谎言、或者其他可能是犯法或会引起权利主张或投诉的资料或遗漏不承担任何责任。使用本网站及内容的后果自负。本网站可能随时或定期会有变动，请不时查阅资料。 
                            本网站是在法律允许的最大范围内照"现状"提供资料和服务，但不提供任何种类的明示或保证。本网站不承认所有明示或默示的保证，包括任何对适销性，适于特定目的和不侵权的保证。本网站不保证资料的正确性、可靠性、完整性或及时性。本网站对网站内容的错误或遗漏，或者任何用户通讯或个人化装置的删除、传输错误或未存储不承担任何责任。</p>
                          <p>本网站不保证以下事项:本网站目前或今后任何时候毫无错误，本网站及其服务器目前或今后任何时候没有计算机病毒或其它有害机能，任何缺陷会被纠正，或者本网站的运作是及时、安全和不会中断的。如果由于您使用本网站，导致需要修理或更换设备或数据，本网站不负责任何由此而产生的费用。 
                            <br>
                            凡由于使用或无法使用本网站，或任何执行失败、错误、遗漏、中断、删除、缺陷、操作或传送的延误、计算机病毒、通信线路失灵、线上通信的拦截、软件或硬件问题(包括但不限于丢失数据或兼容性问题)、偷窃、网站的破坏或改变所引起的，无论是因使用本网站或向本网站上载或从本网站下载或出版数据、文字、图像或其他资料或资料而直接或间接造成的违约、侵权行为、疏忽或任何其他诉因所引起的任何种类损害或伤害(包括但不限于意外损害、间接损害、利润的损失、或者因失去数据或业务被中断所造成的损害)，且无论本网站是否已被告知此种损害的可能性，本网站或在本网站上提及的任何第三方在法律允许的范围内不应承担任何责任。</p>
                          <p>本网站所包含或提供的资料仅仅是提供信息，并根本不打算供交易或投资之用。对于在本网站上传输的任何资料的正确性、实用性或可获得性，本网站不承担任何责任。对于任何基于此类资料所作的交易或投资决定，本网站也不承担任何责任。 

\t\t\t\t<br>
\t\t\t\t</div>
`

export default data;