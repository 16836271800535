const state = {
    permission: {
        articleId: null,
        content: {
            content: null,
            money: null
        },
        download: [],
        permission: [],
        type: "",
        userLevel: null
    },
    status: [],
    pushData: null,
}

const mutations = {
    setPermission(state, data) {
        state.permission = data;
    },
    setStatus(state, status) {
        state.status = status;
    },
    setPushData(state, data){
        state.pushData = data;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
}
