<template>
  <el-row>
    <el-col :md="{span: 16, offset: 4}">
      <el-row :gutter="10">
        <el-container>
          <el-main style="padding: 0">
            <el-col >
              <div class="sl-home-title">
                <h4>标签</h4>
                <div class="sl-home-hot-tag">
                  <a :class="active === item.id ? 'active' : '' " v-for="(item,index) in tags"
                     :key="index" @click="onTagChange(item.id)">{{ item.name }}</a>
                </div>
              </div>
              <el-row :gutter="10">
                <Loading :loading="loading" :data="result" height="378px">
                  <el-col :md="8" v-for="(item,index) in result" :key="index" class="sl-home-tag"
                          @click.native="$router.push(item.staticUrl)">
                    <div class="shadow">
                      <el-image class="sl-home-tag-img"
                                :src="theUrl(item.image) + '?s=412x220'"
                                fit="cover"/>
                      <div style="padding: 0 5px">
                        <SLText style="height:40px" line="2">{{ item.title }}</SLText>
                        <div class="sl-home-tag-user">
                          <div style="display: flex; align-items: center;">
                            <Avatar :lighten="10000" :src="item.author.avatar" :username="item.author.username" :size="20"/>
                            <span style="margin-left: 2px">{{ item.author.name }}</span>
                          </div>
                          <span>{{ item.date }}</span>
                          <span><i class="el-icon-view"> 浏览 {{ item.view }}</i></span>
                          <span><i class="sl icon-pinglun"> 评论 {{ item.comment }}</i></span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col>
                    <div class="sl-home-tag-btn" @click="$router.push({name: 'Tag', params: {name: tagName}})">查看更多</div>
                  </el-col>
                </Loading>
              </el-row>

              <!-- 最新发布 -->
              <NewReleaseArea/>
            </el-col>
          </el-main>
          <el-aside width="400px" class="hidden-xs-only">
            <!-- 侧边栏 -->
            <el-col class="hidden-xs-only">
              <div class="sl-home-title"></div>
              <!-- feed -->
              <FeedAside/>

              <!-- 滑动固定区域 -->
              <FixedArea>
                <!-- 财富榜 -->
                <WealthAside/>
                <!-- 站点信息 -->
                <WebmasterAside/>
              </FixedArea>

            </el-col>
          </el-aside>
        </el-container>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import SLText from "@/components/SLText";
import FixedArea from "@/components/FixedArea";
import FeedAside from "@/components/aside/FeedAside";
import WebmasterAside from "@/components/aside/WebmasterAside";
import WealthAside from "@/components/aside/WealthAside"
import NewReleaseArea from "@/views/home/components/NewReleaseArea";
import homeApi from "@/common/api/homeApi";
import Loading from "@/components/Loading";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar"

export default {
  name: "HotTagArea",
  components: {Loading, WealthAside, WebmasterAside, FeedAside, FixedArea, SLText, NewReleaseArea, Avatar},
  data() {
    return {
      tags: [],
      active: 1,
      loading: true,
      result: null,
    }
  },
  created() {
    homeApi.getHotTag(res => {
      this.tags = res;
      this.active = res[0].id;
      homeApi.getTagArticleMore(this.tags[0].name, 1, 6, res => {
        this.loading = false;
        this.result = res.list;
      })
    })
  },
  methods: {
    onTagChange(id) {
      this.active = id;
    },
    theUrl(url) {
      return getUrl(url)
    }
  },
  watch:{
    active(id){
      this.loading = true;
      homeApi.getTagArticleMore(this.tagName, 1, 6, res => {
        this.loading = false;
        this.result = res.list;
      })
    }
  },
  computed: {
    tagName() {
      for (const item of this.tags) {
        if (item.id === this.active)
          return item.name;
      }
    }
  }
}
</script>

<style scoped lang="less">

.sl-home-hot-tag {
  position: relative;

  &:after {
    content: "";
    position: absolute;
    right: -8px;
    top: 0;
    width: 4px;
    height: 100%;
    transform: scale(0.8);
    background-color: var(--main-green)
  }

  a {
    margin-left: 10px;
    cursor: pointer;
  }

  .active {
    color: var(--main-green);

    &:before {
      transform: scale(1);
      transition: .3s ease;
    }
  }
}

.sl-home-tag {
  position: relative;
  margin-bottom: 10px;
  border-radius: 8px;
  text-align: left;
  font-size: 12px;
  cursor: pointer;

  .sl-home-tag-img {
    height: 210px;
    vertical-align: middle;
  }

  .sl-home-tag-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 15px;

    span:nth-child(2) {
      margin-left: 10px;
    }

    span:nth-child(3) {
      margin-left: 10px;
    }

    span:nth-child(4) {
      margin-left: 10px;
    }
  }
}

.sl-home-tag-btn {
  margin: 10px 0;
  padding: 15px 10px;
  font-weight: 300;
  border: 1px #e0e0e05c solid;
  color: #484848;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s ease;

  &:hover {
    background: var(--main-green);
    color: white;
    font-weight: 400;
    transition: .3s ease;
  }
}

@media screen and(max-width: 768px) {
  .sl-home-tag {
    .shadow {
      padding: 0 5px;
    }
  }
}
</style>
