<template>
  <div>
    <el-row style="background-color: rgb(248,248,248)" v-if="result.length === 3">
      <el-col :md="{span: 16, offset:4}">
        <div class="sl-home-title">
          <h4>视频</h4>
          <p @click="$router.push({name: 'Video'})">查看更多</p>
        </div>
      </el-col>
      <el-col :md="{span: 16, offset:4}">
        <el-row :gutter="10" style="margin-bottom: 60px">
          <el-col :md="16" class="sl-home-video-1">
            <el-image style="width: 100%" :src="theUrl(result[0].image)"
                      fit="cover"/>
            <div class="sl-home-video-pay" @click="showVideo($event, result[0].videoUrl)">
              <el-icon name="caret-right"/>
            </div>
            <div class="sl-home-video-article-info">
              <SLTag type="dark" style="font-size: 13px"
                     @click.native="$router.push({name: 'Tag', params: {name: result[0].tag}})">{{ result[0].tag }}
              </SLTag>
              <h4 @click="$router.push(result[0].staticUrl)" class="title">{{ result[0].title }}</h4>
              <div class="user-info">
                <el-avatar :src="theUrl(result[0].author.avatar)" :size="30"/>
                <span>{{ result[0].author.name }}</span>
                <span>{{ result[0].date }}</span>
                <span>
                  <i class="el-icon-view">浏览 {{ result[0].view }}</i>
                  <i class="el-icon-s-comment">评论 {{ result[0].comment }}</i>
                </span>
              </div>
            </div>
          </el-col>
          <el-col :md="8" class="sl-home-video-2">
            <div class="sl-home-video-2-img">
              <el-image :src="theUrl(result[1].image)" fit="cover"/>
              <div class="sl-home-video-pay" @click="showVideo($event, result[1].videoUrl)">
                <el-icon name="caret-right"/>
              </div>
              <div class="sl-home-video-article-info">
                <SLTag type="dark" @click.native="$router.push({name: 'Tag', params: {name: result[1].tag}})"
                       style="font-size: 13px">{{ result[1].tag }}
                </SLTag>
                <h4 @click="$router.push(result[1].staticUrl)" class="title">{{ result[1].title }}</h4>
                <div class="user-info">
                  <el-avatar :src="theUrl(result[1].author.avatar)" :size="30"/>
                  <span>{{ result[1].author.name }}</span>
                  <span>{{ result[1].date }}</span>
                  <span>
                  <i class="el-icon-view">浏览 {{ result[1].view }}</i>
                  <i class="el-icon-s-comment">评论 {{ result[1].comment }}</i>
                </span>
                </div>
              </div>
            </div>
            <div class="sl-home-video-2-img">
              <el-image :src="theUrl(result[2].image)" fit="cover"/>
              <div class="sl-home-video-pay" @click="showVideo($event, result[2].videoUrl)">
                <el-icon name="caret-right"/>
              </div>
              <div class="sl-home-video-article-info">
                <SLTag type="dark" @click.native="$router.push({name: 'Tag', params: {name: result[2].tag}})"
                       style="font-size: 13px">{{ result[2].tag }}
                </SLTag>
                <h4 @click="$router.push(result[2].staticUrl)" class="title">{{ result[2].title }}</h4>
                <div class="user-info">
                  <el-avatar :src="theUrl(result[2].author.avatar)" :size="30"/>
                  <span>{{ result[2].author.name }}</span>
                  <span>{{ result[2].date }}</span>
                  <span>
                  <i class="el-icon-view">浏览 {{ result[2].view }}</i>
                  <i class="el-icon-s-comment">评论 {{ result[2].comment }}</i>
                </span>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <VideoBox ref="videoBox" :url="videoUrl"/>
  </div>
</template>

<script>
import VideoBox from "@/components/VideoBox";
import SLTag from "@/components/SLTag";
import getUrl from "@/common/utils/getUrl";
import homeApi from "@/common/api/homeApi";

export default {
  name: "VideoArea",
  data() {
    return {
      videoUrl: "",
      result: []
    }
  },
  mounted() {
    homeApi.getVideoArticle(res => this.result = res)
  },
  methods: {
    showVideo(e, url) {
      // console.log(url)
      // this.videoUrl = 'https://www.youtube.com/embed/Wimkqo8gDZ0'
      this.videoUrl = url
      this.$refs.videoBox.show();
    },
    theUrl(url) {
      return getUrl(url);
    }
  },
  components: {SLTag, VideoBox}
}
</script>

<style scoped lang="less">
.sl-home-video-1 {
  position: relative;
  height: 600px;
}

.title {
  cursor: pointer;

  &:hover {
    color: var(--main-green);
  }
}

.sl-home-video-article-info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  padding: 40px 20px;
  color: #fff;
  z-index: 11;

  .user-info {
    width: 100%;
    text-align: left;
    vertical-align: middle;
    font-size: 14px;

    span {
      margin-right: 10px;
      vertical-align: middle;

    }

    span:nth-child(4) {
      display: block;
      float: right;
      vertical-align: middle;

      i {
        margin-left: 10px;
      }
    }
  }
}

.sl-home-video-1:before {
  content: "";
  position: absolute !important;
  left: 5px;
  top: 0;
  height: 100%;
  width: calc(100% - 10px);
  z-index: 2;
  background: linear-gradient(to top, #00000047 1%, transparent);
}

.sl-home-video-2 {
  display: flex;
  flex-direction: column;
  height: 600px;
  justify-content: space-between;

  .sl-home-video-2-img {
    position: relative;
    height: 295px;

    &:before {
      content: "";
      position: absolute !important;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 2;
      background: linear-gradient(to top, #00000047 1%, transparent);
    }
  }
}

.sl-home-video-pay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 10%;
  top: 10%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  z-index: 9999;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 15px #ffffff4a solid;
  }

  i {
    color: #55ca56cf;
    font-size: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sl-home-video-1 {
    height: 300px;

    .el-image {
      padding: 0 5px;
    }

    &:before {
      width: calc(100% - 20px);
      left: 10px;
    }
  }

  .sl-home-video-2 {
    margin-top: 10px;

    .sl-home-video-2-img {
      padding: 0 5px;

      &:before {
        width: calc(100% - 10px);
        left: 5px;
      }
    }
  }
}

@media screen and(min-width: 992px) {

}
</style>
