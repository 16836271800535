<template>
  <draggable class="draggable" tag="ul" :list="menuData" :group="{ name: 'g1' }" :animation="200" @update="onUpdate">
    <transition-group>
      <li class="drop" v-for="(el, index) in menuData" :key="'drag' + index">
        <p>{{ el.name }}
          <el-tooltip placement="bottom" effect="light">
            <i :class="getClassName(el.type)"></i>
            <div slot="content" class="menu">
              <span v-if="el.type === 'Node' || el.type === 'Link'" @click="onChange(index)"><i class="sl icon-edit"></i> 修改</span>
              <span @click="onDelete(index)"><i class="el-icon-delete"></i> 删除</span>
            </div>
          </el-tooltip>
        </p>
        <SLMenu v-model="el.children" @change="onSubChange"/>
      </li>
    </transition-group>
  </draggable>
</template>

<script>

import draggable from 'vuedraggable'
import manageApi from "@/common/api/manageApi";

export default {
  name: "SLMenu",
  data() {
    return {
      menuData: [],
    }
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    this.menuData = this.value;
    //为了防止火狐浏览器拖拽的时候以新标签打开，此代码真实有效
    document.body.ondrop = function (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  },
  methods: {
    /**
     * 修改
     * @param index
     */
    onChange(index) {
      this.$emit("change", this.menuData[index])
    },

    onSubChange(item){
      this.$emit("change", item)
    },

    /**
     * 删除
     * @param index
     */
    onDelete(index) {
      let item = this.menuData[index];
      if(item.id !== undefined && item.id){
        manageApi.deleteMenu(item.id, () => {
          this.menuData.splice(index, 1);
        })
      }else{
        this.menuData.splice(index, 1);
      }
    },

    getClassName(type) {
      let name
      if (type === 'Link') name = 'el-icon-link link-size'
      if (type === 'Category') name = 'sl icon-menu';
      if (type === undefined || type === 'Node') name = "sl icon-node"
      return name;
    },

    onUpdate(e){
      console.log('拖动前的索引 :' + e.oldIndex)
      console.log('拖动后的索引 :' + e.newIndex)
      this.menuData.forEach((item, index) => this.menuData[index].sort = index + 1)
    }
  },
  components: {draggable,},
  watch: {
    value() {
      this.menuData = this.value;
    },
    menuData() {
      this.$emit("input", this.menuData)
    }
  }
}
</script>

<style scoped lang="less">
.draggable {
  display: flex;

  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 5px 0;
    text-align: start;
    cursor: move;
    border: 1px #cccccc4b solid;
    padding: 5px 5px 5px 0;
    width: 150px;
    font-size: 14px;
    padding-left: 10px;
    border-radius: 2px;
    background: linear-gradient(to bottom, #cccccc1f, #fff 30%, #cccccc1f 80%);
    background-color: #fff;
  }
}

ul, li {
  list-style: none
}

.link-size, .sl {
  font-size: 12px;
  cursor: pointer;
}

.menu {
  span {
    display: block;
    margin: 5px;
    color: var(--main-black);
    cursor: pointer;
  }

  span:nth-child(2) {
    &:hover {
      color: var(--main-red)
    }
  }

  span:nth-child(1) {
    &:hover {
      color: var(--main-green)
    }
  }
}
</style>
