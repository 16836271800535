<template>
  <el-row>
    <el-col>
      <div class="search-bg">
        <span><i class="el-icon-search" style="font-size:20px"></i> {{ kw }}</span>
      </div>
    </el-col>

    <el-col :md="{offset: 4, span: 16}" class="sl-tag-wrapper">
      <el-row :gutter="20">
        <Loading :loading="loading" :data="result.list" height="calc(100vh - 395px)">
          <el-col :md="6" v-for="(item, index) in result.list" :key="index" class="item-article" @click.native="$router.push(item.staticUrl)">
            <div class="shadow">
              <el-image :src="getArticleUrl(item.image)"
                        style="height: 240px"
                        fit="cover"/>
              <div style="padding: 0 5px">
                <SLText>{{ item.title }}</SLText>
                <div class="info">
            <span>
              <Avatar :src="getArticleUrl(item.author.avatar)" style="display: inline-block;vertical-align: middle"
                      :size="20" :lighten="10000" :username="item.author.name"/>
              <span> {{ item.author.name }}</span>
            </span>
                  <span>{{ item.date }}</span>
                </div>
              </div>
            </div>
          </el-col>
        </Loading>
      </el-row>

      <el-col class="limit-page">
        <el-pagination class="page" :page-size="12" :total="result.total" :current-page="page"
                       :hide-on-single-page="true"
                       layout="prev, pager, next" @current-change="onPage"></el-pagination>
      </el-col>
    </el-col>

  </el-row>
</template>

<script>
import SLText from "@/components/SLText";
import articleApi from "@/common/api/articleApi";
import Loading from "@/components/Loading";
import getUrl from "@/common/utils/getUrl";
import Avatar from "vue-avatar";

export default {
  name: "SearchPage",
  data() {
    return {
      kw: "",
      page: 1,
      size: 12,
      result: {},
      loading: true,
    }
  },
  mounted() {
    this.kw = this.$route.params.name;
    articleApi.searchArticle(this.kw, this.page, this.size, res => {
      this.loading = false;
      this.result = res;
    })
  },
  methods: {
    getArticleUrl(url) {
      return getUrl(url);
    },
    onPage(page) {
      this.page = page;
      this.loading = true;
      articleApi.searchArticle(this.kw, this.page, this.size, res => {
        this.loading = false;
        this.result = res;
      })
    }
  },
  watch: {
    "$store.state.search.keyword"(newData) {
      this.page = 1;
      this.loading = true;
      this.kw = newData;
      articleApi.searchArticle(newData, this.page, this.size, res => {
        this.loading = false;
        this.result = res;
      })
    }
  },
  components: {Loading, SLText, Avatar}
}
</script>

<style scoped lang="less">
.search-bg {
  position: relative;
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url('../../assets/search-bg.jpeg') no-repeat;
  background-size: cover;
  margin-bottom: 20px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: var(--main-transparent);
    z-index: 10;
  }

  span {
    position: absolute;
    font-size: 20px;
    font-weight: 600;
    z-index: 11;
  }
}

.sl-tag-wrapper {
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .item-article {
    cursor: pointer;

    .info {
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      padding-bottom: 10px;
    }

    span {
      vertical-align: middle;
      color: #777;
    }

    .shadow {
      margin-bottom: 20px;
      transition: .2s linear;

      &:hover {
        box-shadow: 0 0 20px #cccccc;
        transform: scale(1.02);
        transition: .2s linear;
      }
    }
  }
}
</style>
