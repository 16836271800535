import {Notification} from 'element-ui';
import store from "@/store";

function system(title, content) {
    let notification = Notification({
        title: title,
        message: content,
        position: "bottom-right",
        iconClass: "sl icon-bell",
        duration: 0,
        onClick: () => {
            notification.close();
        }
    });
}

function Article(content) {
    store.commit("article/setPushData", content);
}


export default (body) => {
    switch (body.type) {
        case 'System':
            system(body.title, body.content);
            break;
        case 'Article':
            Article(body.content)
            break;
        default:
            break;
    }
}
