<template>
  <div class="sl-upload">
    <input type="file" :multiple="multiple" hidden ref="file" :accept="uploadType === 'image'? imageType : fileType"/>
    <slot name="img">
      <!-- 本地选取 -->
      <div style="position: relative" :style="{width: Number(size) + 2 + 'px', height: Number(size) + 2 + 'px'}"
           v-for="(item, index) in imageUrls" :key="index" v-if="serverUrl.length <= limit">
        <img :src="item" class="image" :class="type" :style="{width: size + 'px', height: size + 'px'}"/>
        <div class="mask" :class="type" @click="deleteImage(index)"><i class="el-icon-delete"></i></div>
      </div>
    </slot>

    <!-- 服务器拉取 -->
    <div style="position: relative" :style="{width: size + 'px', height: size + 'px'}"
         v-for="(item, index) in serverUrl" :key="index">
      <img :src="item" class="image" :class="type" :style="{width: size + 'px', height: size + 'px'}"/>
      <div class="mask" :class="type" @click="deleteServerImage(index)"><i class="el-icon-delete"></i></div>
    </div>

    <slot name="btn" v-if="limit > files.length + serverUrl.length">
      <div class="btn">
        <div class="add-file" :class="type" @click="addFile" :style="{width: size + 'px', height: size + 'px'}"><i
            class="el-icon-plus"></i></div>
        <slot name="content"></slot>
      </div>
    </slot>
    <slot name="no"></slot>
  </div>
</template>

<script>
export default {
  name: "SLUpload",
  data() {
    return {
      files: [],
      imageUrls: [],
      serverUrl: [],
      imageType: "image/gif,image/jpeg,image/jpg,image/png,image/svg,",
      fileType: ".xls,.json,.html,.txt,.rar,.zip,.7z,.xml,.java,.py,.cpp,.php,.js,.gz,.pdf,.md,.class,.doc,.vue,.dart,.svg," +
          ".iso,.exe,.rmvb,.avi,.mdf,.mp3,.mp4,.dmg,.pkg,.docx,.xlsx,.ppt,.pptx"
    }
  },
  mounted() {
    this.serverUrl = this.urls;
  },
  props: {
    size: {
      type: [Number, String],
      default: 50
    },
    type: {
      type: String,
      default: 'round' // round 圆形  square 方形
    },
    multiple: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 1
    },
    urls: {
      type: Array,
      default: () => []
    },
    uploadType: {
      type: String,
      default: "image"
    }
  },
  methods: {
    addFile() {
      this.$refs.file.click();
      this.$refs.file.onchange = (e) => {
        for (let i = 0; i < e.target.files.length; ++i) {
          this.files.push(e.target.files[i])
        }
        this.generateImageUrl(e.target.files)
      }
    },
    generateImageUrl(files) {
      for (let i = 0; i < files.length; ++i) {
        this.imageUrls.push(window.URL.createObjectURL(files[i]))
      }
    },
    deleteImage(index) {
      this.imageUrls.splice(index, index + 1);
      this.files.splice(index, index + 1);
      this.$refs.file.value = null;
      this.$emit('removeServer', index);
    },
    deleteServerImage(index) {
      this.serverUrl.splice(index, index + 1);
      // 接口删除
      this.$emit('removeServer', index);
    },
  },
  watch: {
    files() {
      this.$emit("change", this.files)
    }
  }
}
</script>

<style scoped lang="less">
.sl-upload {
  display: flex;
  flex-direction: row;

  .add-file {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed var(--main-green);
    border-radius: 4px;
    cursor: pointer;

    i {
      color: var(--main-green)
    }
  }

  .round {
    border-radius: 50% !important;
  }

  .image {
    position: relative;
    margin-right: 10px;
    object-fit: cover;
    border: 1px solid #cccccc4b;
    border-radius: 4px;

    animation: imgAn .5s linear;

    @keyframes imgAn {
      0% {
        transform: translateY(-20px);
        opacity: 0;
      }
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    &:hover ~ .mask {
      display: flex;
    }
  }

  .mask {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #0000008a;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:hover {
      display: flex
    }

    i {
      color: #fff;
      cursor: pointer;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
