/**
 * 用户中心api
 */

import {handlerRes, request} from "@/common/request";
import Message from "element-ui/packages/message/src/main";
import ca from "element-ui/src/locale/lang/ca";

const userCenterApi = {
    /**
     * 获取用户信息
     */
    getUserInfo(callback) {
        request({
            url: "/user.center/base",
            method: "GET",
            success: (res) => {
                if (res.code === 10000) {
                    callback(res.result)
                } else {
                    console.error("获取用户资料异常")
                }
            }
        })
    },

    /**
     * 更新用户资料
     * @param data 更新值
     * @param callback
     */
    updateUserInfo(data, callback) {
        request({
            url: "/user.center/base",
            method: "POST",
            data: data,
            success: (res) => {
                if (callback) callback()
                if (res.code === 10000) {
                    Message.success("资料更新成功~")
                }
            }
        })
    },

    /**
     * 删除用户资料图片
     */
    deleteImage(type) {
        request({
            url: "/user.center/base",
            method: "delete",
            data: {type},
            success: (res) => {
                console.log(res)
                if (res.code === 10000) {
                    Message.success("删除成功~")
                }
            }
        })
    },

    /**
     * 获取用户私信列表
     * @param page
     * @param size
     * @param out true收件箱  false 发件箱
     * @param callback
     */
    getPrivateMessage(page, size, out, callback) {
        request({
            url: '/user/message?' + `page=${page}&size=${size}&out=${out}`,
            method: 'get',
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取用户收藏的文章
     * @param page
     * @param size
     * @param callback
     */
    getUserCollections(page, size, callback) {
        request({
            url: "/user/articles" + `?page=${page}&size=${size}`,
            method: "get",
            success: res => handlerRes(res, callback)
        })
    },

    /**
     * 获取我发布的文章
     * @param page
     * @param size
     * @param isPublic true公共文章  false 私有文章
     * @param callback
     */
    getMyArticle(page, size, isPublic, callback) {
        request({
            url: `/user/my.article?page=${page}&size=${size}&isPublic=${isPublic}`,
            method: "get",
            success: res => handlerRes(res, callback),
        })
    },


    /**
     * 删除文章
     * @param articleId 文章id
     * @param callback
     */
    deleteRemoveArticle(articleId, callback) {
        request({
            url: "/user/my.article",
            method: "delete",
            data: {articleId},
            success: res => handlerRes(res, callback)
        })
    }
}

export default userCenterApi;
